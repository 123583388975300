import {
  Calculate,
  ZvjsConditionTypes, ZvjsCustomQuestionTypes,
  ZvjsQuestionnaireTemplate, ZvjsQuestionTypes,
} from '../redux/model';
import { SC_KIO_050101_ClosePerson } from '../requestPages/questionnaire/utils/calculations/custom';

export const PERSON_TYPE_ID = "personType";

export const SC_KIO_050101_is_any_selected_person_in_prison = (
  selectedPeopleList: string[],
  closePeopleList: SC_KIO_050101_ClosePerson[]
) => {
  for (const selectedPhysicalPersonId of selectedPeopleList) {
    if (
      closePeopleList.some(
        (person) =>
          person.fyzickaOsobaId === Number(selectedPhysicalPersonId) &&
          person.inPrison
      )
    ) {
      return true;
    }
  }
  return false;
};

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050701",
  basicInfo: [
    {
      text: "ziadost.ziadostOtelefonovanie.zakladneInfoObvineny",
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "IS_USER_ACCUSED",
          conditionMetWhenValueIs: true,
        },
      ],
    },
    {
      text: "ziadost.ziadostOtelefonovanie.zakaldneInfoOdsudeny",
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "IS_USER_SENTENCED",
          conditionMetWhenValueIs: true,
        },
      ],
    },
  ],
  dataCalculations: {
    IS_USER_ACCUSED: {
      calculateAt: Calculate.AT_INIT,
    },
    IS_USER_SENTENCED: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_050101_CLOSE_PEOPLE_LIST: {
      calculateAt: Calculate.AT_INIT,
    },
    BASIC_CLIENT_DATA: {
      calculateAt: Calculate.AT_INIT,
    },
  },
  counters: [
    "/api/CisTypBlizkejOsoby/List",
    // "/api/Administracia/PravnyZastupcaData",
  ],
  items: [
    {
      id: PERSON_TYPE_ID,
      title: "Žiadosť o telefonovanie",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      options: [
        {
          id: "VVVT",
          label: "Civilná osoba vo VV/VT",
        },
        {
          id: "OBAD",
          label: "Obhajca/advokát na telefonát",
        },
        {
          id: "CIOS",
          label: "Civilná osoba",
        },
      ],
    },
    {
      id: "civilneOsobyList",
      title: "Civilná osoba",
      type: ZvjsCustomQuestionTypes.SC_KIO_050101_PHYSICAL_VISIT_PEOPLE_GRID,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: PERSON_TYPE_ID,
          value: ["CIOS"],
        },
      ],
    },
    {
      id: "obhajcoviaAdvokatiList",
      title: "Obhajca/advokát na telefonát",
      type: ZvjsCustomQuestionTypes.SC_KIO_050701_PHONE_CALL_LAWYERS_GRID,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: PERSON_TYPE_ID,
          value: ["OBAD"],
        },
      ],
    },
    {
      id: "civelneOsobyVoVVVTList",
      title: "Civilná osoba vo VV/VT",
      type: ZvjsCustomQuestionTypes.SC_KIO_050101_PHYSICAL_VISIT_PEOPLE_GRID,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: PERSON_TYPE_ID,
          value: ["VVVT"],
        },
      ],
    },
  ],
};
