import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ReactComponent as DetailIcon } from "../../../../../assets/icons/Other/detail.svg";
import { useNavigate } from "react-router-dom";
import { ZvjsButton, ZvjsTable } from "../../../../../common";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../../../store/context/translation-context";
import { defer } from "react-router-dom";
import { withLoader } from "../../../../../hoc/withLoader";
import { API_Clients } from "../../../../../store/context/dataApi/Data";
import { MAX_NUMBER_OF_ITEMS } from "../../../../../store/context/dataApi/Data";
import { LoaderError } from "../../../../../router/LoaderError";

interface Pohladavka {
  nazovPrijemcu: string;
  adresaPrijemcu?: string | null; // Optional since it's added dynamically
  typPohladavkyNazov: string;
  povodnaSuma: number;
  zostatkovaSuma: number;
  [key: string]: any; // Optional, for any additional properties
}

const loadData = async () => {
  const { CIS_Post, FEOO_Get, FEOO_Post } = await API_Clients();

  const feooKlient = await FEOO_Get("/api/Klient/Get", {
    params: {
      query: {
        id: localStorage.getItem("klientObjectId") ?? undefined,
      },
    },
  });

  const typVeritela = await FEOO_Post("/api/Codelist/ListTypVeritela", {
    body: {
      filters: [],
      paging: {
        currentPage: 1,
        recordsPerPage: MAX_NUMBER_OF_ITEMS,
      },
      sorting: [{}],
    },
  });

  const data = await Promise.all([
    FEOO_Post("/api/Pohladavka/List", {
      body: {
        filters: [
          {
            pohladavkaZakladnyFilter: {
              zakladneCislo:
                feooKlient.data?.data?.zakladneUdajeKlienta?.zakladneCislo,
              typVeritelaID: 2, // vid typVeritela
            },
          },
        ],
      },
    }),
    CIS_Post("/api/CisVeritelTyp/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: 99999,
        },
        sorting: [{}],
      },
    }),
  ]);

  if (data[0].error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [pohľadávky ostatných oprávnených]"
    );
  }

  const testData = {
    data: {
      "records": [
        {
          "pohladavkaID": 1234,
          "klientFeooID": 1210,
          "zakladneCislo": 6741,
          "meno": "Juraj",
          "priezvisko": "ADAM",
          "typVykonuID": 1,
          "typVykonuNazov": "Trest",
          "cisloRozhodnutia": null,
          "stavPohladavkyID": 9,
          "stavPohladavkyNazov": "Zaevidovaná",
          "kodPohladavky": "2-N-N",
          "poradoveCislo": 1,
          "deponovane": false,
          "povodnaSuma": 250,
          "splatenaSuma": 0,
          "zostatkovaSuma": 250,
          "datumPravoplatnosti": null,
          "sumaOpravnejPolozky": 0,
          "nova": null,
          "vyuctovana": false,
          "dodatocna": false,
          "datumDoruceniaPrvemuPlatcovi": "2024-10-11T00:00:00",
          "nazovPrijemcu": "Home Credit Slovakia, a.s.",
          "typVeritelaID": 2,
          "typVeritelaNazov": "Pohľadávky ostatných oprávnených",
          "typPohladavkyID": 22,
          "typPohladavkyNazov": "neprednostné",
          "zadaneVUstaveID": 6,
          "zadaneVUstaveNazov": "ÚVV a ÚVTOS Prešov",
          "poznamka": null,
          "vs": null,
          "pc": 0,
          "klientVUstaveID": 6,
          "klientVUstaveNazov": "ÚVV a ÚVTOS Prešov",
          "financnySpisVUstaveID": 6,
          "financnySpisVUstave": "ÚVV a ÚVTOS Prešov",
          "priezviskoAMeno": "ADAM Juraj",
          "klientVUstaveFsVUstave": "ÚVV a ÚVTOS Prešov",
          "zrusitelna": false,
          "stavKlientaSkratka": null,
          "ikona": null,
          "farba": "denim",
          "transakciaID": "00000000-0000-0000-0000-000000000000",
          "transakciaPovodnaID": "f0a9215d-9aa3-463a-8933-2728ce16c5d5",
          "changed": "0001-01-01T00:00:00"
        }
      ],
      "paging": {
        "totalCount": 0,
        "currentPage": 1,
        "recordsPerPage": 2147483647
      }
    }
  };

  const { EOO_Post } = await API_Clients();

  const fetchPravnickeOsobyAddress = async (nazovPrijemcu: string) => {
    const response = await EOO_Post("/api/PravnickaOsoba/List", {
      body: {
        filters: [{
          // nazov: nazovPrijemcu,
          nazov: "Regionálne združenie miest a obcí stredného Považia",
          overene: true,
          aktualne: true,
        }],
      },
    });
    if (response?.data?.records?.length && response?.data?.records?.length > 0) {
      const record = response.data.records[0];
      return `${record.adresaUlica ?? ""} ${record.adresaSupisneCislo ?? ""}${
        record.adresaOrientacneCislo ? (record.adresaOrientacneCislo + " ") : ""
      }, ${record.adresaObec ?? ""}, ${record.adresaPsc ?? ""}, ${
        record.adresaStat ?? ""
      }`.trim();
    }
    return null;
  };

  const updatePohladavkyWithAddress = async (pohladavky: any) => {
    const updatedRecords = await Promise.all(
      pohladavky.data.records.map(async (record: any) => {
        const adresaPrijemcu = await fetchPravnickeOsobyAddress(
          record.nazovPrijemcu
        );
        return { ...record, adresaPrijemcu };
      })
    );
    pohladavky.data.records = updatedRecords;
    return pohladavky;
  };

  const updatedTestData = await updatePohladavkyWithAddress(testData);
  const updatedData0 = await updatePohladavkyWithAddress(data[0]);

  return {
    pohladavky: updatedData0,
  };
};

export const PohladavkyOstatnychOpravnenychLoader = async () => {
  return defer({
    data: loadData(),
  });
};

interface PohladavkyOstatnychOpravnenychProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const PohladavkyOstatnychOpravnenych = (
  props: PohladavkyOstatnychOpravnenychProps
) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(
              tui(
                "osobneUdaje.pohladavkyOstatnychOpravnenych.pohladavkyOstatni"
              )
            )}
          </Typography>
        </Stack>
      </Grid>

      <ZvjsTable
        data={{
          header: [
            {
              value: capitalize(
                tui("osobneUdaje.pohladavkyOstatnychOpravnenych.nazovPrijemcu")
              ),
              align: "left",
            },
            {
              value: capitalize(
                tui("osobneUdaje.pohladavkyOstatnychOpravnenych.adresaPrijemcu")
              ),
              align: "left",
            },
            {
              value: capitalize(
                tui("osobneUdaje.pohladavkyZboru.typPohladavky")
              ),
              align: "left",
            },
            {
              value: capitalize(tui("osobneUdaje.pohladavkyZboru.povodnaSuma")),
              align: "left",
            },
            {
              value: capitalize(
                tui("osobneUdaje.pohladavkyZboru.ZostatkovaSuma")
              ),
              align: "left",
            },
            {
              value: capitalize(tui("akcia")), //TODO kluc akcia
              align: "left",
            },
          ],
          body:
            props?.data?.pohladavky?.data?.records
              // .sort((p1, p2) =>
              //   p1.name > p2.name)
              ?.map((pohladavka: Pohladavka) => {
                const rd: RowData = {
                  row: [
                    {
                      value: pohladavka.nazovPrijemcu ?? "",
                      align: "left",
                    },
                    {
                      // value: pohladavka.zadaneVUstaveNazov ?? "",
                      value: pohladavka.adresaPrijemcu ?? "",
                      align: "left",
                    },
                    {
                      value: pohladavka.typPohladavkyNazov ?? "",
                      align: "left",
                    },
                    {
                      value: pohladavka.povodnaSuma?.toFixed(2) + " Eur",
                      align: "left",
                    },
                    {
                      value: pohladavka.zostatkovaSuma?.toFixed(2) + " Eur",
                      align: "left",
                    },
                    {
                      value: (
                        <ZvjsButton
                          text={capitalize(
                            tui("osobneUdaje.pohladavkyZboru.rozpisSplatok")
                          )}
                          zvjsVariant="secondaryAction"
                          startIcon={
                            <DetailIcon
                              style={{ fill: "white", width: 28, height: 28 }}
                            />
                          }
                          onClick={() => {
                            navigate(`${pohladavka.pohladavkaID}`);
                          }}
                          style={{
                            lineHeight: "1",
                            textTransform: "none",
                            textAlign: "left",
                          }}
                        />
                      ),
                      align: "left",
                    },
                  ],
                };
                return rd;
              }) ?? [],
        }}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Grid>
  );
};

export default withLoader(PohladavkyOstatnychOpravnenych);
