import React from "react";
import {
  Baliky,
  BezpecnyInternet,
  DisciplinarneTrestyAIneRozhodnutia,
  ErrorPage,
  ExamplesPage,
  HelpPage,
  Holup,
  IntranetPrehliadacSuborov,
  Kniznica,
  KniznicaKatalogTitulov,
  Login,
  MiestoVykonuVazbyAVykonuTrestu,
  MojeUdaje,
  Nastenka,
  Navstevy,
  OfflinePage,
  OsobneVeci,
  PeniazeAPoplatky,
  PohladavkyGRZVJS,
  PohladavkyOstatnychOpravnenych,
  PohladavkyUstavu,
  PohladavkyVyzivovacichPovinnosti,
  Poucenia,
  Requests,
  ResocializacneAVolnocasoveAktivity,
  SplatkyPohladavokGRZVJS,
  SplatkyPohladavokOstatnychOpravnenych,
  SplatkyPohladavokUstavu,
  SplatkyPohladavokVyzivovacichPovinnosti,
  Telefonovanie,
  UstavnyPoriadok,
  UstavnyPoriadokCasovyRozvrhDna,
  UstavnyPoriadokUstavnyPoriadok,
  VseobecneInformacie,
  ZakladneUdajeKlienta,
  ZakladneUdajeOTreste,
  ZakladneUdajeOVazbe,
  ZmenaUdajovZiadost,
} from "../pages";
import { createBrowserRouter, redirect } from "react-router-dom";
import { AppLayout } from "../layouts/AppLayout";
import {
  Dashboard_SC_KIO_0200,
  GeneralInformation_SC_KIO_0490,
  InstitutionalOrder_SC_KIO_0410,
  Library_SC_KIO_0460,
  MoneyClaimAndAlimony_SC_KIO_03603,
  Noticeboard_SC_KIO_0400,
  PersonalDataCategory_SC_KIO_0313,
  Request,
} from "../common";
import { TelefonovanieLoader } from "../pages/auth/MojeUdajePages/Telefonovanie";
import { BalikyLoader } from "../pages/auth/MojeUdajePages/Baliky";
import { NavstevyLoader } from "../pages/auth/MojeUdajePages/Navstevy";
import { OsobneVeciLoader } from "../pages/auth/MojeUdajePages/OsobneVeci";
import { MiestoVykonuVazbyAVykonuTrestuLoader } from "../pages/auth/MojeUdajePages/MiestoVykonuVazbyAVykonuTrestu";
import { DisciplinarneTrestyAIneRozhodnutiaLoader } from "../pages/auth/MojeUdajePages/DisciplinarneTrestyAIneRozhodnutia";
import { ZakladneUdajeOTresteTabs } from "../pages/auth/MojeUdajePages/ZakladneUdajeOTreste";
import { ZakladneUdajeOVazbeLoader } from "../pages/auth/MojeUdajePages/ZakladneUdajeOVazbe";
import { PouceniaLoader } from "../pages/auth/MojeUdajePages/Poucenia";
import { ResocializacneAVolnocasoveAktivityTabs } from "../pages/auth/MojeUdajePages/ResocializacneAVolnocasoveAktivity";
import PoucenieDetail, {
  PoucenieDetailLoader,
} from "../pages/auth/MojeUdajePages/PoucenieDetail";
import { VseobecneInformacieLoader } from "../pages/auth/NastenkaPages/VseobecneInformacie";
import { KniznicaLoader } from "../pages/auth/NastenkaPages/Kniznica";
import { KniznicaKatalogTitulovLoader } from "../pages/auth/NastenkaPages/KniznicaKatalogTitulov";
import { CheckIsLoggedInGuardLoader } from "../store/context/auth-context";
import { ProtectedLayout } from "../layouts/ProtectedLayout";
import { RequestActions, RequestLoader } from "../common/request/Request";
import { ExamplesActions, ExamplesLoader } from "../pages/auth/ExamplesPage";
import { PohladavkyUstavuLoader } from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/OstatnePages/PohladavkyUstavu";
import { PohladavkyGRZVJSLoader } from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/OstatnePages/PohladavkyGRZVJS";
import { PohladavkyOstatnychOpravnenychLoader } from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/OstatnePages/PohladavkyOstatnychOpravnenych";
import { PohladavkyVyzivovacichPovinnostiLoader } from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/OstatnePages/PohladavkyVyzivovacichPovinnosti";
import { SplatkyPohladavokUstavuLoader } from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/OstatnePages/SplatkyPohladavokUstavu";
import { SplatkyPohladavokGRZVJSLoader } from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/OstatnePages/SplatkyPohladavokGRZVJS";
import { SplatkyPohladavokOstatnychOpravnenychLoader } from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/OstatnePages/SplatkyPohladavokOstatnychOpravnenych";
import { SplatkyPohladavokVyzivovacichPovinnostiLoader } from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/OstatnePages/SplatkyPohladavokVyzivovacichPovinnosti";
import { Requests_SC_KIO_0481 } from "../common/specs/sections/Requests_SC_KIO_0481";
import RequestCategory, {
  RequestCategoryLoader,
} from "../pages/auth/Requests/RequestCategory";
import { HolupLoader } from "../pages/auth/Holup";
import { ZmenaUdajovRequestLoader } from "../pages/auth/MojeUdajePages/ZmenaOsobnychUdajov/ZmenaUdajovZiadost";
import { RequestDetail } from "../pages/auth/Requests";
import { RequestsTableActions } from "../pages/auth/Requests/UserRequestsList";
import { RequestsLoader } from "../pages/auth/Requests/Requests";
import {
  RequestDetailActions,
  RequestDetailLoader,
} from "../pages/auth/Requests/RequestDetail";
import Listy, { ListyTabs } from "../pages/auth/MojeUdajePages/Listy";
import DisciplinarnaOdmenaDetail, {
  DisciplinarnaOdmenaDetailLoader,
} from "../pages/auth/MojeUdajePages/DisciplinarnaOdmenaDetail";
import DisciplinarnyTrestDetail, {
  DisciplinarnyTrestDetailLoader,
} from "../pages/auth/MojeUdajePages/DisciplinarnyTrestDetail";
import IneRozhodnutieDetail, {
  IneRozhodnutieDetailLoader,
} from "../pages/auth/MojeUdajePages/IneRozhodnutieDetail";
import OchranneOpatrenieDetail, {
  OchranneOpatrenieDetailLoader,
} from "../pages/auth/MojeUdajePages/OchranneOpatrenieDetail";
import RozhodnutieDetail, {
  RozhodnutieDetailLoader,
} from "../pages/auth/MojeUdajePages/RozhodnutieDetail";
import TrestDetail, {
  TrestDetailLoader,
} from "../pages/auth/MojeUdajePages/TrestDetail";
import VazbaDetail, {
  VazbaDetailLoader,
} from "../pages/auth/MojeUdajePages/VazbaDetail";
import Stravovanie, {
  StravovanieLoader,
} from "../pages/auth/NastenkaPages/Stravovanie";
import { LoginLoader } from "../pages/Login";
import Dashboard from "../pages/auth/Dashboard";
import { NastenkaLoader } from "../pages/auth/Nastenka";
import { BezpecnyInternetLoader } from "../pages/auth/BezpecnyInternetPages/BezpecnyInternet";
import { UstavnyPoriadokLoader } from "../pages/auth/NastenkaPages/UstavnyPoriadok";
import { UstavnyPoriadokUstavnyPoriadokLoader } from "../pages/auth/NastenkaPages/UstavnyPoriadokUstavnyPoriadok";
import { UstavnyPoriadokCasovyRozvrhDnaLoader } from "../pages/auth/NastenkaPages/UstavnyPoriadokCasovyRozvrhDna";
import { OfflinePageLoader } from "../pages/auth/BezpecnyInternetPages/OfflinePage";
import Notifikacie, { NotifikacieLoader } from "../pages/auth/Notifikacie";
import {
  INTRANET_FILE_BROWSER_ROUTER_PATH,
  IntranetPrehliadacSuborovLoader,
} from "../pages/auth/NastenkaPages/IntranetPrehliadacSuborov";
import { HelpPageLoader } from "../pages/HelpPage";
import { intranetDirectories } from "../utils/intranetFiles";
import { isKiosk } from "../store/context/envConfigContext";
import PrijataKorespondenciaTab, {
  PrijataKorespondenciaTabLoader,
} from "../pages/auth/MojeUdajePages/ListyTabs/PrijataKorespondenciaTab";
import OdoslanaKorespondenciaTab, {
  OdoslanaKorespondenciaTabLoader,
} from "../pages/auth/MojeUdajePages/ListyTabs/OdoslanaKorespondenciaTab";
import AbsolvovaneAktivityTab, {
  AbsolvovaneAktivityTabLoader,
} from "../pages/auth/MojeUdajePages/ResocializacneAVolnocasoveAktivityTabs/AbsolvovaneAktivityTab";
import PracovneZaradenieTab, {
  PracovneZaradenieTabLoader,
} from "../pages/auth/MojeUdajePages/ResocializacneAVolnocasoveAktivityTabs/PracovneZaradenieTab";
import ProgramZaobchadzaniaTab, {
  ProgramZaobchadzaniaTabLoader,
} from "../pages/auth/MojeUdajePages/ResocializacneAVolnocasoveAktivityTabs/ProgramZaobchadzaniaTab";
import { ZakladneUdajeKlientaTabs } from "../pages/auth/MojeUdajePages/ZakladneUdajeKlienta";
import OsobneUdajeTab, {
  OsobneUdajeTabLoader,
} from "../pages/auth/MojeUdajePages/ZakladneUdajeKlientaTabs/OsobneUdajeTab";
import TrvalyPobytTab, {
  TrvalyPobytTabLoader,
} from "../pages/auth/MojeUdajePages/ZakladneUdajeKlientaTabs/TrvalyPobytTab";
import PrechodnyPobytTab, {
  PrechodnyPobytTabLoader,
} from "../pages/auth/MojeUdajePages/ZakladneUdajeKlientaTabs/PrechodnyPobytTab";
import PotravinoveIntolerancieTab, {
  PotravinoveIntolerancieTabLoader,
} from "../pages/auth/MojeUdajePages/ZakladneUdajeKlientaTabs/PotravinoveIntolerancieTab";
import VyzivovaciePovinnostiTab, {
  VyzivovaciePovinnostiTabLoader,
} from "../pages/auth/MojeUdajePages/ZakladneUdajeKlientaTabs/VyzivovaciePovinnostiTab";
import RozhodnutiaTab, {
  RozhodnutiaTabLoader,
} from "../pages/auth/MojeUdajePages/ZakladneUdajeOTresteTabs/RozhodnutiaTab";
import TrestyAVazbyTab, {
  TrestyAVazbyTabLoader,
} from "../pages/auth/MojeUdajePages/ZakladneUdajeOTresteTabs/TrestyAVazbyTab";
import UdajeOTresteTab, {
  UdajeOTresteTabLoader,
} from "../pages/auth/MojeUdajePages/ZakladneUdajeOTresteTabs/UdajeOTresteTab";
import ZastupcoviaAOpatreniaTab, {
  ZastupcoviaAOpatreniaTabLoader,
} from "../pages/auth/MojeUdajePages/ZakladneUdajeOTresteTabs/ZastupcoviaAOpatreniaTab";
import { PeniazeAPoplatkyTabs } from "../pages/auth/MojeUdajePages/PeniazeAPoplatky";
import KontoTab, {
  KontoTabLoader,
} from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/KontoTab";
import KreditABlokacieTab, {
  KreditABlokacieTabLoader,
} from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/KreditABlokacieTab";
import PohladavkyTab, {
  PohladavkyTabLoader,
} from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/PohladavkyTab";
import VyplatnePaskyTab, {
  VyplatnePaskyTabLoader,
} from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/VyplatnePaskyTab";
import ZiadostiTab, {
  ZiadostiTabLoader,
} from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/ZiadostiTab";

/**
 * Loaders classes / interfaces
 */
export interface GenericLoadedData<T> {
  data: T;
}

export const Router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Login />,
        loader: LoginLoader,
      },
      {
        path: "examples",
        element: <ExamplesPage />,
        loader: ExamplesLoader,
        action: ExamplesActions,
        shouldRevalidate: (args) => false,
      },
      {
        path: "help/:pageID",
        element: <HelpPage />,
        loader: HelpPageLoader,
      },
      {
        path: "auth",
        // ensures that after a user is logged out he is redirected to login page
        element: <ProtectedLayout />,
        // loader protects all auth routes from being displayed - instead user is redirected to log in before first render
        loader: CheckIsLoggedInGuardLoader,
        children: [
          {
            index: true,
            element: <Dashboard />,
          },
          {
            path: "Notifikacie",
            element: <Notifikacie />,
            loader: NotifikacieLoader,
          },
          {
            path: Dashboard_SC_KIO_0200.Requests,
            children: [
              {
                index: true,
                element: <Requests />,
                loader: RequestsLoader,
                action: RequestsTableActions,
                shouldRevalidate: (args) => false,
              },
              {
                path: ":requestCode",
                element: <Request />,
                loader: RequestLoader,
                action: RequestActions,
                shouldRevalidate: (args) => false,
              },
              {
                path: "Detail/:requestID",
                element: <RequestDetail />,
                loader: RequestDetailLoader,
                action: RequestDetailActions,
                shouldRevalidate: (args) => false,
              },
              {
                path: "universalRequest",
                children: [
                  {
                    path: "personalData",
                    element: <ZmenaUdajovZiadost />,
                    shouldRevalidate: (args) => false,
                    loader: ZmenaUdajovRequestLoader,
                  },
                ],
              },
            ],
          },
          {
            path:
              Requests_SC_KIO_0481.RequestsCategories + "/:requestCategoryCode",
            element: <RequestCategory />,
            loader: RequestCategoryLoader,
          },
          {
            path: Dashboard_SC_KIO_0200.NoticeBoard,
            children: [
              {
                index: true,
                element: <Nastenka />,
                loader: NastenkaLoader,
              },
              {
                path: Noticeboard_SC_KIO_0400.ConstitutionalOrder,
                children: [
                  {
                    index: true,
                    element: <UstavnyPoriadok />,
                    loader: UstavnyPoriadokLoader,
                  },
                  {
                    path: InstitutionalOrder_SC_KIO_0410.InstitutionalOrder,
                    element: <UstavnyPoriadokUstavnyPoriadok />,
                    loader: UstavnyPoriadokUstavnyPoriadokLoader,
                  },
                  {
                    path: InstitutionalOrder_SC_KIO_0410.DailySchedule,
                    element: <UstavnyPoriadokCasovyRozvrhDna />,
                    loader: UstavnyPoriadokCasovyRozvrhDnaLoader,
                  },
                  {
                    path: InstitutionalOrder_SC_KIO_0410.ClinicOfficeHours,
                    loader: async () => {
                      const intranetDirs = await intranetDirectories(
                        Number(localStorage.getItem("klientUstavId")),
                        isKiosk()
                      );
                      return redirect(
                        `/auth/${INTRANET_FILE_BROWSER_ROUTER_PATH}/${encodeURIComponent(
                          intranetDirs.ordinacneHodinyVAmbulancii
                        )}`
                      );
                    },
                  },
                ],
              },
              {
                path: Noticeboard_SC_KIO_0400.PacketsAndLetters,
                loader: async () => {
                  const intranetDirs = await intranetDirectories(
                    Number(localStorage.getItem("klientUstavId")),
                    isKiosk()
                  );
                  return redirect(
                    `/auth/${INTRANET_FILE_BROWSER_ROUTER_PATH}/${encodeURIComponent(
                      intranetDirs.balikyAListy
                    )}`
                  );
                },
              },
              {
                path: Noticeboard_SC_KIO_0400.Telephony,
                loader: async () => {
                  const intranetDirs = await intranetDirectories(
                    Number(localStorage.getItem("klientUstavId")),
                    isKiosk()
                  );
                  return redirect(
                    `/auth/${INTRANET_FILE_BROWSER_ROUTER_PATH}/${encodeURIComponent(
                      intranetDirs.telefonovanie
                    )}`
                  );
                },
              },
              {
                path: Noticeboard_SC_KIO_0400.Meal,
                element: <Stravovanie />,
                loader: StravovanieLoader,
              },
              {
                path: Noticeboard_SC_KIO_0400.CulturalAndEducationalActivities,
                loader: async () => {
                  const intranetDirs = await intranetDirectories(
                    Number(localStorage.getItem("klientUstavId")),
                    isKiosk()
                  );
                  return redirect(
                    `/auth/${INTRANET_FILE_BROWSER_ROUTER_PATH}/${encodeURIComponent(
                      intranetDirs.kulturnoOsvetoveAktivity
                    )}`
                  );
                },
              },
              {
                path: Noticeboard_SC_KIO_0400.Library,
                children: [
                  {
                    index: true,
                    element: <Kniznica />,
                    loader: KniznicaLoader,
                  },
                  {
                    path: Library_SC_KIO_0460.Catalog,
                    element: <KniznicaKatalogTitulov />,
                    loader: KniznicaKatalogTitulovLoader,
                  },
                  {
                    path: Library_SC_KIO_0460.OfficeHours,
                    loader: async () => {
                      const intranetDirs = await intranetDirectories(
                        Number(localStorage.getItem("klientUstavId")),
                        isKiosk()
                      );
                      return redirect(
                        `/auth/${INTRANET_FILE_BROWSER_ROUTER_PATH}/${encodeURIComponent(
                          intranetDirs.kniznicaVypozicneHodiny
                        )}`
                      );
                    },
                  },
                ],
              },
              {
                path: Noticeboard_SC_KIO_0400.DailyPressAndMagazines,
                loader: async () => {
                  const intranetDirs = await intranetDirectories(
                    Number(localStorage.getItem("klientUstavId")),
                    isKiosk()
                  );
                  return redirect(
                    `/auth/${INTRANET_FILE_BROWSER_ROUTER_PATH}/${encodeURIComponent(
                      intranetDirs.dennaTlacACasopisy
                    )}`
                  );
                },
              },
              {
                path: Noticeboard_SC_KIO_0400.ProtectionOfRights,
                loader: async () => {
                  const intranetDirs = await intranetDirectories(
                    Number(localStorage.getItem("klientUstavId")),
                    isKiosk()
                  );
                  return redirect(
                    `/auth/${INTRANET_FILE_BROWSER_ROUTER_PATH}/${encodeURIComponent(
                      intranetDirs.ochranaPrav
                    )}`
                  );
                },
              },
              {
                path: Noticeboard_SC_KIO_0400.GeneralInformation,
                children: [
                  {
                    index: true,
                    element: <VseobecneInformacie />,
                    loader: VseobecneInformacieLoader,
                  },
                  {
                    path: GeneralInformation_SC_KIO_0490.AudioRecordings,
                    loader: async () => {
                      const intranetDirs = await intranetDirectories(
                        Number(localStorage.getItem("klientUstavId")),
                        isKiosk()
                      );
                      return redirect(
                        `/auth/${INTRANET_FILE_BROWSER_ROUTER_PATH}/${encodeURIComponent(
                          intranetDirs.audionahravky
                        )}`
                      );
                    },
                  },
                  {
                    path: GeneralInformation_SC_KIO_0490.InformationalBrochures,
                    loader: async () => {
                      const intranetDirs = await intranetDirectories(
                        Number(localStorage.getItem("klientUstavId")),
                        isKiosk()
                      );
                      return redirect(
                        `/auth/${INTRANET_FILE_BROWSER_ROUTER_PATH}/${encodeURIComponent(
                          intranetDirs.informacneBrozury
                        )}`
                      );
                    },
                  },
                  {
                    path: GeneralInformation_SC_KIO_0490.InternalRegulationsZVJS,
                    loader: async () => {
                      const intranetDirs = await intranetDirectories(
                        Number(localStorage.getItem("klientUstavId")),
                        isKiosk()
                      );
                      return redirect(
                        `/auth/${INTRANET_FILE_BROWSER_ROUTER_PATH}/${encodeURIComponent(
                          intranetDirs.internePredpisy
                        )}`
                      );
                    },
                  },
                  {
                    path: GeneralInformation_SC_KIO_0490.OtherFiles,
                    loader: async () => {
                      const intranetDirs = await intranetDirectories(
                        Number(localStorage.getItem("klientUstavId")),
                        isKiosk()
                      );
                      return redirect(
                        `/auth/${INTRANET_FILE_BROWSER_ROUTER_PATH}/${encodeURIComponent(
                          intranetDirs.ineSubory
                        )}`
                      );
                    },
                  },
                ],
              },
            ],
          },
          {
            path: Dashboard_SC_KIO_0200.MyData,
            children: [
              {
                index: true,
                element: <MojeUdaje />,
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.DisciplinarySanctionsAndOtherDecisions,
                element: <DisciplinarneTrestyAIneRozhodnutia />,
                loader: DisciplinarneTrestyAIneRozhodnutiaLoader,
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.DisciplinarySanctionsAndOtherDecisions +
                  "/DisciplinaryReward" +
                  "/:disciplinarnaOdmenaId",
                element: <DisciplinarnaOdmenaDetail />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  DisciplinarnaOdmenaDetailLoader(
                    params.disciplinarnaOdmenaId!
                  ),
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.DisciplinarySanctionsAndOtherDecisions +
                  "/DisciplinarySanction" +
                  "/:disciplinarnyTrestId",
                element: <DisciplinarnyTrestDetail />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  DisciplinarnyTrestDetailLoader(params.disciplinarnyTrestId!),
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.DisciplinarySanctionsAndOtherDecisions +
                  "/DisciplinaryOtherDecision" +
                  "/:ineRozhodnutieId",
                element: <IneRozhodnutieDetail />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  IneRozhodnutieDetailLoader(params.ineRozhodnutieId!),
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.ResocializationAndLeisureActivities,
                element: <ResocializacneAVolnocasoveAktivity />,
                children: [
                  {
                    path: ResocializacneAVolnocasoveAktivityTabs.PracovneZaradenie,
                    element: <PracovneZaradenieTab />,
                    loader: PracovneZaradenieTabLoader,
                  },
                  {
                    path: ResocializacneAVolnocasoveAktivityTabs.AbsolvovaneAktivity,
                    element: <AbsolvovaneAktivityTab />,
                    loader: AbsolvovaneAktivityTabLoader,
                  },
                  {
                    path: ResocializacneAVolnocasoveAktivityTabs.ProgramZaobchadzania,
                    element: <ProgramZaobchadzaniaTab />,
                    loader: ProgramZaobchadzaniaTabLoader,
                  },
                ],
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.MoneyAndFees,
                element: <PeniazeAPoplatky />,
                children: [
                  {
                    path: PeniazeAPoplatkyTabs.Konto,
                    element: <KontoTab />,
                    loader: KontoTabLoader,
                  },
                  {
                    path: PeniazeAPoplatkyTabs.KreditABlokacie,
                    element: <KreditABlokacieTab />,
                    loader: KreditABlokacieTabLoader,
                  },
                  {
                    path: PeniazeAPoplatkyTabs.Pohladavky,
                    element: <PohladavkyTab />,
                    loader: PohladavkyTabLoader,
                  },
                  {
                    path: PeniazeAPoplatkyTabs.VyplatnePasky,
                    element: <VyplatnePaskyTab />,
                    loader: VyplatnePaskyTabLoader,
                  },
                  {
                    path: PeniazeAPoplatkyTabs.Ziadosti,
                    element: <ZiadostiTab />,
                    loader: ZiadostiTabLoader,
                  },
                ],
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.MoneyAndFees +
                  "/" +
                  PeniazeAPoplatkyTabs.Pohladavky +
                  "/" +
                  MoneyClaimAndAlimony_SC_KIO_03603.PenitentiaryClaims,
                element: <PohladavkyUstavu />,
                loader: PohladavkyUstavuLoader,
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.MoneyAndFees +
                  "/" +
                  PeniazeAPoplatkyTabs.Pohladavky +
                  "/" +
                  MoneyClaimAndAlimony_SC_KIO_03603.PenitentiaryClaims +
                  "/:pohladavkaId",
                element: <SplatkyPohladavokUstavu />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  SplatkyPohladavokUstavuLoader(params.pohladavkaId!),
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.MoneyAndFees +
                  "/" +
                  PeniazeAPoplatkyTabs.Pohladavky +
                  "/" +
                  MoneyClaimAndAlimony_SC_KIO_03603.GRZVJSClaims,
                element: <PohladavkyGRZVJS />,
                loader: PohladavkyGRZVJSLoader,
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.MoneyAndFees +
                  "/" +
                  PeniazeAPoplatkyTabs.Pohladavky +
                  "/" +
                  MoneyClaimAndAlimony_SC_KIO_03603.GRZVJSClaims +
                  "/:pohladavkaId",
                element: <SplatkyPohladavokGRZVJS />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  SplatkyPohladavokGRZVJSLoader(params.pohladavkaId!),
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.MoneyAndFees +
                  "/" +
                  PeniazeAPoplatkyTabs.Pohladavky +
                  "/" +
                  MoneyClaimAndAlimony_SC_KIO_03603.OtherAuthorizedClaims,
                element: <PohladavkyOstatnychOpravnenych />,
                loader: PohladavkyOstatnychOpravnenychLoader,
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.MoneyAndFees +
                  "/" +
                  PeniazeAPoplatkyTabs.Pohladavky +
                  "/" +
                  MoneyClaimAndAlimony_SC_KIO_03603.OtherAuthorizedClaims +
                  "/:pohladavkaId",
                element: <SplatkyPohladavokOstatnychOpravnenych />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  SplatkyPohladavokOstatnychOpravnenychLoader(
                    params.pohladavkaId!
                  ),
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.MoneyAndFees +
                  "/" +
                  PeniazeAPoplatkyTabs.Pohladavky +
                  "/" +
                  MoneyClaimAndAlimony_SC_KIO_03603.Alimonys,
                element: <PohladavkyVyzivovacichPovinnosti />,
                loader: PohladavkyVyzivovacichPovinnostiLoader,
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.MoneyAndFees +
                  "/" +
                  PeniazeAPoplatkyTabs.Pohladavky +
                  "/" +
                  MoneyClaimAndAlimony_SC_KIO_03603.Alimonys +
                  "/:pohladavkaId",
                element: <SplatkyPohladavokVyzivovacichPovinnosti />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  SplatkyPohladavokVyzivovacichPovinnostiLoader(
                    params.pohladavkaId!
                  ),
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.Packets,
                element: <Baliky />,
                loader: BalikyLoader,
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.Letters,
                element: <Listy />,
                children: [
                  {
                    path: ListyTabs.PrijataKorespondencia,
                    element: <PrijataKorespondenciaTab />,
                    loader: PrijataKorespondenciaTabLoader,
                  },
                  {
                    path: ListyTabs.OdoslanaKorespondencia,
                    element: <OdoslanaKorespondenciaTab />,
                    loader: OdoslanaKorespondenciaTabLoader,
                  },
                ],
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.BasicClientData,
                element: <ZakladneUdajeKlienta />,
                children: [
                  {
                    path: ZakladneUdajeKlientaTabs.OsobneUdaje,
                    element: <OsobneUdajeTab />,
                    loader: OsobneUdajeTabLoader,
                  },
                  {
                    path: ZakladneUdajeKlientaTabs.TrvalyPobyt,
                    element: <TrvalyPobytTab />,
                    loader: TrvalyPobytTabLoader,
                  },
                  {
                    path: ZakladneUdajeKlientaTabs.PrechodnyPobyt,
                    element: <PrechodnyPobytTab />,
                    loader: PrechodnyPobytTabLoader,
                  },
                  {
                    path: ZakladneUdajeKlientaTabs.PotravinoveIntolerancie,
                    element: <PotravinoveIntolerancieTab />,
                    loader: PotravinoveIntolerancieTabLoader,
                  },
                  {
                    path: ZakladneUdajeKlientaTabs.VyzivovaciePovinnosti,
                    element: <VyzivovaciePovinnostiTab />,
                    loader: VyzivovaciePovinnostiTabLoader,
                  },
                ],
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.BasicArrestData,
                element: <ZakladneUdajeOVazbe />,
                loader: ZakladneUdajeOVazbeLoader,
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.BasicPunishmentData,
                element: <ZakladneUdajeOTreste />,
                children: [
                  {
                    path: ZakladneUdajeOTresteTabs.UdajeOTreste,
                    element: <UdajeOTresteTab />,
                    loader: UdajeOTresteTabLoader,
                  },
                  {
                    path: ZakladneUdajeOTresteTabs.ZastupcoviaAOpatrenia,
                    element: <ZastupcoviaAOpatreniaTab />,
                    loader: ZastupcoviaAOpatreniaTabLoader,
                  },
                  {
                    path: ZakladneUdajeOTresteTabs.Rozhodnutia,
                    element: <RozhodnutiaTab />,
                    loader: RozhodnutiaTabLoader,
                  },
                  {
                    path: ZakladneUdajeOTresteTabs.TrestyAVazby,
                    element: <TrestyAVazbyTab />,
                    loader: TrestyAVazbyTabLoader,
                  },
                ],
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.BasicPunishmentData +
                  "/" +
                  ZakladneUdajeOTresteTabs.ZastupcoviaAOpatrenia +
                  "/ProtectiveMeasure" +
                  "/:ochranneOpatrenieID",
                element: <OchranneOpatrenieDetail />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  OchranneOpatrenieDetailLoader(params.ochranneOpatrenieID!),
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.BasicPunishmentData +
                  "/" +
                  ZakladneUdajeOTresteTabs.TrestyAVazby +
                  "/Detention" +
                  "/:vazbaID",
                element: <VazbaDetail />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  VazbaDetailLoader(params.vazbaID!),
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.BasicPunishmentData +
                  "/" +
                  ZakladneUdajeOTresteTabs.TrestyAVazby +
                  "/Punishment" +
                  "/:trestID",
                element: <TrestDetail />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  TrestDetailLoader(params.trestID!),
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.BasicPunishmentData +
                  "/" +
                  ZakladneUdajeOTresteTabs.Rozhodnutia +
                  "/Verdict" +
                  "/:rozhodnutieID",
                element: <RozhodnutieDetail />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  RozhodnutieDetailLoader(params.rozhodnutieID!),
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.PlaceOfCustodyAndExecutionOfSentence,
                element: <MiestoVykonuVazbyAVykonuTrestu />,
                loader: MiestoVykonuVazbyAVykonuTrestuLoader,
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.PersonalBelongings,
                element: <OsobneVeci />,
                loader: OsobneVeciLoader,
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.Telephony,
                element: <Telefonovanie />,
                loader: TelefonovanieLoader,
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.Visits,
                element: <Navstevy />,
                loader: NavstevyLoader,
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.Guidances,
                element: <Poucenia />,
                loader: PouceniaLoader,
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.Guidances + "/:guidanceID",
                element: <PoucenieDetail />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  PoucenieDetailLoader(params.guidanceID!),
              },
            ],
          },
          {
            path: Dashboard_SC_KIO_0200.SafeInternet,
            children: [
              {
                index: true,
                element: <BezpecnyInternet />,
                loader: BezpecnyInternetLoader,
              },
              {
                path: ":url",
                element: <OfflinePage />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  OfflinePageLoader(params.url!),
              },
            ],
          },
          {
            path: INTRANET_FILE_BROWSER_ROUTER_PATH + "/:filePath",
            element: <IntranetPrehliadacSuborov />,
            loader: ({ params }) =>
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              IntranetPrehliadacSuborovLoader(params.filePath!),
          },
        ],
      },
    ],
  },
  {
    path: "/auth/" + Dashboard_SC_KIO_0200.Holup,
    element: <ProtectedLayout />,
    errorElement: <ErrorPage />,
    // loader protects all auth routes from being displayed - instead user is redirected to login before first render
    loader: CheckIsLoggedInGuardLoader,
    children: [
      {
        index: true,
        element: <Holup />,
        loader: HolupLoader,
        shouldRevalidate: (args) => false,
        // errorElement: <ErrorPage />,
      },
    ],
  },
]);
