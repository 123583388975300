import React from "react";
import { capitalize } from "@mui/material/utils";
import { useUITranslation } from "../../../store/context/translation-context";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { ZvjsTable } from "../../../common";
import { withLoader } from "../../../hoc/withLoader";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { Link, useNavigate } from "react-router-dom";
import { defer } from "react-router-dom";
import { API_Clients } from "../../../store/context/dataApi/Data";
import { LoaderError } from "../../../router/LoaderError";
import { isKiosk } from "../../../store/context/envConfigContext";

const LoadData = async () => {
  const { ZVJS_MP_Post } = await API_Clients();

  const response = await ZVJS_MP_Post("/bezpecny-internet/list", {
    body: {
      stav: "PLATNY",
      sort: [{ attribute: "url", sortDirection: "ASC" }],
      page: 1,
      size: 99999,
    },
  });

  if (response.error || !response.response.ok) {
    throw new LoaderError();
  }

  return response.data;
};

export const BezpecnyInternetLoader = async () => {
  return defer({
    data: LoadData(),
  });
};

interface BezpecnyInternetProps {
  data: Awaited<ReturnType<typeof LoadData>>;
}

const BezpecnyInternet = (props: BezpecnyInternetProps) => {
  const navigate = useNavigate();
  const { tui } = useUITranslation();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} width={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {capitalize(tui("dashboard.bezpecnyInternet"))}
            </Typography>
          </Stack>
        </Grid>
        <Grid item mt={4}>
          <ZvjsTable
            data={{
              header: [
                {
                  value: capitalize(
                    tui(
                      "Názov stránky" // TODO: use proper KEY
                    )
                  ),
                  align: "left",
                },
                {
                  value: capitalize(
                    tui(
                      "Link" // TODO: use proper KEY
                    )
                  ),
                  align: "right",
                },
              ],
              body:
                props.data?.bezpecnyInternetList?.map((page) => {
                  let url = page.url;
                  if (page.url?.startsWith("www")) {
                    url = "https://" + page.url;
                  }
                  const rd: RowData = {
                    row: [
                      { value: page.url ?? "" },
                      {
                        value: isKiosk() ? (
                          <a
                            // component="button"
                            target={"_blank"}
                            href={url}
                            // onClick={() => {
                            //   const searchParams = new URLSearchParams(location.search);
                            //   searchParams.set("page", Links[i]);
                            //   navigate(
                            //     "/auth/SafeInternet/openedPage?" + searchParams.toString(),
                            //     {
                            //       // replace current path (so back button redirects you directly to previous page and not to the previous tab)
                            //       replace: true,
                            //     }
                            //   );
                            // }}
                          >
                            {"Link"} {/*TODO: use proper KEY */}
                          </a>
                        ) : (
                          <Link to={page.url ?? ""}>
                            {"Link"} {/*TODO: use proper KEY */}
                          </Link>
                        ),
                        align: "right",
                      },
                    ],
                  };
                  return rd;
                }) ?? [],
            }}
            variant={ZvjsTableVariant.NORMAL}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withLoader(BezpecnyInternet);
