import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../../../../../redux";
import { AppDispatch } from "../../../../../../../../store/redux";
import {
  ZvjsButton,
  ZvjsCustomQuestionFullWidthBox,
  ZvjsTextField,
} from '../../../../../../../components';
import { capitalize } from "@mui/material/utils";
import { Radio, Stack } from '@mui/material';
import CustomQuestionProps from "../CustomQuestionProps";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";
import ZvjsTable, {
  ZvjsTableVariant,
  CellData,
  RequestFontSizes,
  RowData,
  TableData,
  TableStyle,
} from "../../../../../../../components/ZvjsTable";
import {
  CriminalProceedingsGridConstants,
  SC_KIO_050301_CriminalProceedingsGrid_answerType,
} from "./redux";
import ChevronRight from '@mui/icons-material/ChevronRight';

const SC_KIO_050301_CriminalProceedingsGrid: React.FC<CustomQuestionProps> = ({
  location,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { tuiz } = useUITranslation();

  console.debug(
    `SC_KIO_050301 CRIMINAL PROCEEDINGS GRID QUESTION RERENDER ${location.join(
      "-"
    )}`
  );

  const questionData = useSelector(
    questionnaireRedux.selectors.getCriminalProceedingsGridDisplayData(
      location
    ),
    shallowEqual
  );

  const answerVal = useSelector(
    questionnaireRedux.selectors.getQuestionAnswerData(questionData.id),
    shallowEqual
  ) as undefined | SC_KIO_050301_CriminalProceedingsGrid_answerType;

  const [showTextField, setShowTextField] = useState(false);

  const onRadioChangeHandler = (
    criminalProceedingId:
      | number
      | typeof CriminalProceedingsGridConstants.OTHER_CRIMINAL_PROCEEDING_INTERNAL_CODE
  ): void => {
    if (
      criminalProceedingId !==
      CriminalProceedingsGridConstants.OTHER_CRIMINAL_PROCEEDING_INTERNAL_CODE
    ) {
      setShowTextField(false);
    }

    dispatch(
      questionnaireRedux.actions.addNewAnswer(location, questionData.id, {
        criminalProceeding: criminalProceedingId,
      })
    );
  };

  const onTextFieldChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (answerVal) {
      dispatch(
        questionnaireRedux.actions.addNewAnswer(location, questionData.id, {
          criminalProceeding: answerVal.criminalProceeding,
          otherCriminalProceedingDescription: event.target.value,
        })
      );
    }
  };

  const dataStyle: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "right",
      sx: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(tuiz("Popis trestného konania")),
      ...dataStyle[0],
    },
    {
      value: capitalize(
        tuiz("ziadost.ziadostOodoslanieKorespondencie.labelOznaecnieKonania")
      ),
      ...dataStyle[1],
    },
  ];

  const bodyData: Array<RowData> = [];
  for (const criminalProceeding of questionData.criminalProceedings) {
    bodyData.push({
      row: [
        {
          value: criminalProceeding.description,
          ...dataStyle[0],
        },
        {
          value: (
            <Radio
              value={criminalProceeding.id}
              onChange={() => onRadioChangeHandler(criminalProceeding.id)}
              checked={criminalProceeding.id === answerVal?.criminalProceeding}
            />
          ),
          ...dataStyle[1],
        },
      ],
    });
  }

  const tableData: TableData = {
    header: headerData,
    body: bodyData,
    label: questionData.title,
  };

  return (
    <>
      <ZvjsCustomQuestionFullWidthBox location={location}>
        <ZvjsTable
          data={tableData}
          variant={ZvjsTableVariant.NORMAL}
          fontSizes={RequestFontSizes}
        />
        <Stack direction={"row"} spacing={2} mb={3}>
          <ZvjsButton
            zvjsVariant={"primaryAction"}
            text={capitalize(tuiz("Iné konanie"))}
            endIcon={<ChevronRight style={{ height: 45, width: 45 }} />}
            onClick={() => {
              onRadioChangeHandler(
                CriminalProceedingsGridConstants.OTHER_CRIMINAL_PROCEEDING_INTERNAL_CODE
              );
              setShowTextField((prev) => !prev);
            }}
          />
        </Stack>
        {showTextField && (
          <ZvjsTextField
            id={questionData.id}
            label={capitalize(tuiz("Popis iného trestného konania"))}
            value={answerVal?.otherCriminalProceedingDescription ?? ""}
            onChange={onTextFieldChangeHandler}
            type={"text"}
            answerRequired
          />
        )}
      </ZvjsCustomQuestionFullWidthBox>
    </>
  );
};

export default SC_KIO_050301_CriminalProceedingsGrid;
