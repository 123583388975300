import * as idb from "idb";
import { KioskZVJSDBSchema } from "./databaseSchema";
import {
  DataArray,
  DataRequestOptionsArray,
} from "../store/context/dataApi/Data";

const DatabaseName = "kioskzvjs";
const DatabaseVersion = 13;

export const dbPromise = idb.openDB<KioskZVJSDBSchema>(
  DatabaseName,
  DatabaseVersion,
  {
    blocked(currentVersion, blockedVersion, event) {
      console.info(
        `[DB] blocked: currentVersion[${currentVersion}] blockedVersion[${blockedVersion}]`
      );
    },
    blocking(blockingVersion, event) {
      console.info(`[DB] blocking: blockingVersion[${blockingVersion}]`);
    },
    terminated() {
      console.info("[DB] terminated");
    },
    upgrade(database, oldVersion, newVersion, transaction, event) {
      console.info(
        `[DB] upgrade: database[${DatabaseName}] oldVersion[${oldVersion}] newVersion[${newVersion}]`
      );

      const logCreation = (storeName: string) => {
        console.debug(
          `[DB] upgrade database[${DatabaseName}] create store[${storeName}]`
        );
      };

      if (!database.objectStoreNames.contains("EnvironmentVariables")) {
        logCreation("EnvironmentVariables");
        database.createObjectStore("EnvironmentVariables");
      }

      if (!database.objectStoreNames.contains("File")) {
        logCreation("File");
        database.createObjectStore("File");
      }

      DataArray.forEach((data) => {
        if (!database.objectStoreNames.contains(data)) {
          logCreation(data);
          database.createObjectStore(data);
        }
      });

      DataRequestOptionsArray.forEach((data) => {
        if (!database.objectStoreNames.contains(data)) {
          logCreation(data);
          database.createObjectStore(data);
        }
      });

      switch (oldVersion) {
        case 0:
          break;
        case 1:
          break;
        case 2:
          break;
      }

      console.info(`[DB] upgrade: database[${DatabaseName}] finished`);
    },
  }
);
