import {
  ZvjsConditionTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_051104",
  basicInfo: [
    {
      text: "ziadost.ziadostOobmenuVystrojnychSucasti.zakladneInfo",
    },
  ],
  isAvailableToUser: "SC_KIO_051104_isAvailableToUser",
  counters: ["/api/CisDovodPozadovanejObmeny/List", "/api/CisVystrojneSucasti/List"],
  items: [
    {
      id: "vystrojnaSucastId",
      title:
        "ziadost.ziadostOobmenuVystrojnychSucasti.labelVystrojneSucasti",
      type: ZvjsQuestionTypes.DROPDOWN_NEW,
      isRequired: true,
      options: {
        counterKey: "/api/CisVystrojneSucasti/List",
        textKey: "nazov_sucasti",
        optValues: [
          {
            id: "1",
          },
        ],
      },
    },
    {
      id: "dovodPozadovanejObmeny",
      title:
        "ziadost.ziadostOobmenuVystrojnychSucasti.labelDovodPozadovanejObmeny",
      type: ZvjsQuestionTypes.DROPDOWN_NEW,
      isRequired: true,
      options: {
        counterKey: "/api/CisDovodPozadovanejObmeny/List",
        textKey: "dovod",
        optValues: [
          {
            id: "VELKOST",
          },
          {
            id: "OPOTR",
          },
          {
            id: "STRATA",
          },
          {
            id: "POSKOD",
          },
          {
            id: "INYD",
          },
        ],
      },
    },
    {
      id: "vysvetlenieIneDovody",
      title: "ziadost.ziadostOobmenuVystrojnychSucasti.labelInyDovodObmeny",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "dovodPozadovanejObmeny",
          value: ["INYD"],
        },
      ],
    },
  ],
};
