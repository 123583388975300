import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { COMMON } from "./constants";
import {
  initialState,
  RequestUserPersonalData,
  State,
  UserCalcData,
  ZvjsCustomQuestionTypes,
  ZvjsItem,
  ZvjsQuestion,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
  ZvjsSection,
  ZvjsValidationError,
  ZvjsAnswerValueType,
  ZvjsNestedDropdownOptions,
  ZvjsNestedDropdownOption,
  ZvjsFormCondition,
} from "./model";
import { isItemDisplayed } from "./selectors";
import {
  Ciselnik,
  CiselnikListResultType,
} from "../../../store/context/dataApi/Data";
import { SC_KIO_050101_ClosePerson } from '../requestPages/questionnaire/utils/calculations/custom';

const requestSlice = createSlice({
  name: COMMON.ROOT_REDUCER,
  initialState: initialState,
  reducers: {
    setQuestionnaire(
      state: State,
      action: PayloadAction<{
        questionnaire: ZvjsQuestionnaireTemplate;
        precalculatedData: UserCalcData;
        userPersonalData: RequestUserPersonalData;
        requestCounter: CiselnikListResultType<"/api/CisTypZiadosti/List">["data"];
        counters: {
          [key in Ciselnik]?: CiselnikListResultType<Ciselnik>["data"];
        };
      }>
    ) {
      state.questionnaire = action.payload.questionnaire;
      state.answers = {};
      state.validationErrors = {};
      state.userCalcData = action.payload.precalculatedData;
      state.counters = action.payload.counters;
      state.userPersonalData = action.payload.userPersonalData;
      state.requestCounter = action.payload.requestCounter;
    },
    replacePrecalculatedData(
      state: State,
      action: PayloadAction<{ precalculatedData: UserCalcData }>
    ) {
      state.userCalcData = action.payload.precalculatedData;
    },
    addNewOption(
      state: State,
      action: PayloadAction<{
        newOption: SC_KIO_050101_ClosePerson;
      }>
    ) {
      if (!Array.isArray(state.userCalcData.SC_KIO_050101_CLOSE_PEOPLE_LIST)) {
        state.userCalcData.SC_KIO_050101_CLOSE_PEOPLE_LIST = [];
      }
      state.userCalcData.SC_KIO_050101_CLOSE_PEOPLE_LIST.push(action.payload.newOption);
    },
    addNewAnswer(
      state: State,
      action: PayloadAction<{
        questionId: string;
        newAnswer: ZvjsAnswerValueType;
        newValidationError: { [key: string]: ZvjsValidationError | undefined };
      }>
    ) {
      // assign new validation error message to questionnaire validations
      state.validationErrors = Object.assign(
        state.validationErrors,
        action.payload.newValidationError
      );
      state.answers[action.payload.questionId] = action.payload.newAnswer;
    },
    removeAnswer(state: State, action: PayloadAction<{ questionId: string }>) {
      delete state.answers[action.payload.questionId];
    },
    // remove method which removes answerValue from answers array (if there is array)
    // removeAnswer(
    //   state: State,
    //   action: PayloadAction<{ questionId: string; answerValue?: string }>
    // ) {
    //   if (action.payload.answerValue === undefined) {
    //     delete state.answers[action.payload.questionId];
    //   } else {
    //     if (
    //       Array.isArray(state.answers[action.payload.questionId]) &&
    //       (
    //         state.answers[action.payload.questionId] as ZvjsAnswerValueType[]
    //       ).every((item) => typeof item === "string")
    //     ) {
    //       state.answers[action.payload.questionId] = (
    //         state.answers[action.payload.questionId] as string[]
    //       ).filter((answer) => answer !== action.payload.answerValue);
    //       if (
    //         (state.answers[action.payload.questionId] as string[]).length === 0
    //       ) {
    //         delete state.answers[action.payload.questionId];
    //       }
    //     }
    //   }
    // },
    setEditQuestionnaire(
      state: State,
      action: PayloadAction<{ questionId: string | undefined }>
    ) {
      state.editQuestion = action.payload.questionId;
    },
  },
});

export const findItem = (
  state: ZvjsQuestionnaireTemplate,
  location: number[]
) => {
  let foundItems = state.items[location[0]];
  for (let i = 1; i < location.length; i++) {
    foundItems = (foundItems as ZvjsSection).items[location[i]] as ZvjsItem;
  }
  return foundItems;
};

// iterator which iterates through all questions in questionnaire and returns with question also its location in questionnaire
export function* questionsIteratorWithLocation(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: any,
  loc: number[] = []
): IterableIterator<{
  value: ZvjsQuestion;
  location: number[];
}> {
  if (typeof obj === "object" && obj !== null) {
    // if obj is question yield it
    if (obj.type in ZvjsQuestionTypes || obj.type in ZvjsCustomQuestionTypes) {
      yield {
        value: obj,
        location: loc,
      };
    }

    // if object has array of items it is Section, therefore iterate through items array and pass proper location
    if (Array.isArray(obj.items)) {
      for (let i = 0; i < obj.items.length; i++) {
        yield* questionsIteratorWithLocation(obj.items[i], loc.concat(i));
      }
    }
  }
}

export function* questionsIteratorWithLocFromDisplayedSections(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: any,
  state: State,
  loc: number[] = []
): IterableIterator<{
  value: ZvjsQuestion;
  location: number[];
}> {
  if (typeof obj === "object" && obj !== null) {
    // if obj is question yield it
    if (obj.type in ZvjsQuestionTypes || obj.type in ZvjsCustomQuestionTypes) {
      yield {
        value: obj,
        location: loc,
      };
    }

    // if object has array of items it is Section, therefore iterate through items array and pass proper location
    // && check if section is displayed, if not do not iterate through its items
    if (
      Array.isArray(obj.items) &&
      (loc.length === 0 || isItemDisplayed(loc, state))
    ) {
      for (let i = 0; i < obj.items.length; i++) {
        yield* questionsIteratorWithLocFromDisplayedSections(
          obj.items[i],
          state,
          loc.concat(i)
        );
      }
    }
  }
}

/**
 * Iterator which iterates through all option in nested dropdown
 * @param obj nested dropdown options
 * @param parentTree parameter which holds information about parentTree of a currently iterated option
 */
export function* questionsIteratorWithLocAndParentTreeNestedDropdown(
  obj: ZvjsNestedDropdownOptions,
  parentTree: { [key: string]: string } = {}
): IterableIterator<{
  id: string;
  currentItemConditions?: ZvjsFormCondition[];
  counterKey: Ciselnik;
  textKey: string;
  answerId: string;
  parentTree: { [key: string]: string };
}> {
  if (typeof obj === "object" && obj !== null) {
    if (obj.children) {
      for (let i = 0; i < obj.children.length; i++) {
        const options = obj.children[i].options;
        if (options !== undefined) {
          yield* questionsIteratorWithLocAndParentTreeNestedDropdown(options, {
            [obj.answerId]: obj.children[i].id,
            ...parentTree,
          });
        } else {
          yield {
            id: obj.children[i].id,
            currentItemConditions: obj.children[i].conditions,
            counterKey: obj.counterKey,
            textKey: obj.textKey,
            answerId: obj.answerId,
            parentTree: {
              [obj.answerId]: obj.children[i].id,
              ...parentTree,
            },
          };
        }
      }
    }
  }
}

// iterator which iterates through all questions in questionnaire and returns with question also its location in questionnaire
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* itemsIterator(obj: any): IterableIterator<ZvjsItem> {
  if (typeof obj === "object" && obj !== null) {
    // if obj is question yield it
    if (obj.type !== undefined) {
      yield obj;
    }

    // if object has array of items it is Section, therefore iterate through items array and pass proper location
    if (Array.isArray(obj.items)) {
      for (let i = 0; i < obj.items.length; i++) {
        yield* itemsIterator(obj.items[i]);
      }
    }
  }
}

export const sliceActions = requestSlice.actions;
export default requestSlice.reducer;
