import {
  State,
  UserCalcData,
  ZvjsCustomQuestion,
} from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { findItem } from "../../../../../../redux/slice";
import {
  CiselnikListResultType,
  MAX_NUMBER_OF_ITEMS,
} from "../../../../../../../../store/context/dataApi/Data";
import { LoaderError } from "../../../../../../../../router/LoaderError";
import { CivilianClothes } from "../../../../utils/calculations/custom";
import { API_Clients } from "../../../../../../../../store/context/dataApi/Data";

const METHOD_OF_ENSURING_HYGIENIC_SAFETY_COUNTER =
  "METHOD_OF_ENSURING_HYGIENIC_SAFETY_COUNTER";
const DEFAULT_METHOD_OF_ENSURING_HYGIENIC_SAFETY_CODE = "RUCNE";
const METHOD_OF_ENSURING_HYGIENIC_SAFETY_INSTITUTION_LAUNDRY_CODE = "PRACOV";

export const CivilianClothesGridConstants = {
  METHOD_OF_ENSURING_HYGIENIC_SAFETY_COUNTER,
  DEFAULT_METHOD_OF_ENSURING_HYGIENIC_SAFETY_CODE,
  METHOD_OF_ENSURING_HYGIENIC_SAFETY_INSTITUTION_LAUNDRY_CODE,
};

/**
 * OnInit
 */

export const SC_KIO_051102_CivilianClothesGrid_onInit = async (
  calcData: UserCalcData
): Promise<void> => {
  const { CIS_Post } = await API_Clients();

  const data = await Promise.all([
    CIS_Post("/api/CisSposobZabezpeceniaHygNezavadnosti/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
  ]);

  if (data[0] === undefined || data[0].data?.records === undefined) {
    // if some data were not fetch, do not display request and throw error
    throw new LoaderError();
  }

  calcData[
    CivilianClothesGridConstants.METHOD_OF_ENSURING_HYGIENIC_SAFETY_COUNTER
  ] = data[0];
};

/**
 * Selectors
 */

const getCivilianClothesGridDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    const civilianClothesList = state.userCalcData[
      "SC_KIO_051102_CIVILIAN_CLOTHES_LIST"
    ] as CivilianClothes[];

    return {
      id: question.id,
      title: question.title,
      civilianClothesList: civilianClothesList,
      methodOfEnsuringHygienicSafetyCounter:
        // records are always present though (ensured in loader)
        (
          state.userCalcData[
            CivilianClothesGridConstants
              .METHOD_OF_ENSURING_HYGIENIC_SAFETY_COUNTER
          ] as CiselnikListResultType<"/api/CisSposobZabezpeceniaHygNezavadnosti/List">
        ).data?.records ?? [],
    };
  });

export const allSelectors = {
  getCivilianClothesGridDisplayData,
};

/**
 * IsAnswered
 */

export const SC_KIO_051102_CivilianClothesGrid_isAnswered = (
  userCalcData: UserCalcData,
  answer?: string[]
): boolean => {
  return false;
};

/**
 * Model
 */
export interface SC_KIO_051102_CivilianClothesGrid_answerType {
  vecId: number;
  druhCivilnejVeci: string;
  // current count of clothes which client would like to use from a particular clothes type
  pozadovanyPocet: number;
  // current method of ensuring hygienic safety of particular item
  hygienickaNezavadnost: string;
}
