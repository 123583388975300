import { UserCalcData } from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectUserCalcData } from "../../../../../../redux/selectors";

/**
 * Selectors
 */

const displayClientHasEnoughMoneyOnAccount_SC_KIO_050301 = () =>
  createSelector(
    selectUserCalcData,
    (state: UserCalcData): boolean | undefined => {
      const questionControl = state.userCalcData["SC_KIO_050301_QUESTION_CONTROL"];
      if (questionControl === undefined || questionControl.porusenePodmienky === null){
        return false;
      }
      return questionControl?.porusenePodmienky?.some((x: string) => x === "BR024");
    }
  );

const displayClientShoppedLastCalendarMonth_SC_KIO_050301 = () =>
  createSelector(
    selectUserCalcData,
    (state: UserCalcData): boolean | undefined => {
      const questionControl = state.userCalcData["SC_KIO_050301_QUESTION_CONTROL"];
      if (questionControl === undefined || questionControl.porusenePodmienky === null){
        return false;
      }
      return questionControl?.porusenePodmienky?.some((x: string) => x === "BR025");
    }
  );

export const allSelectors = {
  displayClientHasEnoughMoneyOnAccount_SC_KIO_050301,
  displayClientShoppedLastCalendarMonth_SC_KIO_050301,
};
