import {
  Calculate, ZvjsConditionTypes,
  ZvjsCustomQuestionTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_051404",
  isAvailableToUser: "SC_KIO_051404_isAvailableToUser",
  basicInfo: [
    {
      text: "ziadost.ziadostOumiestnenie.zakladneInfoOdsudeny",
    },
  ],
  counters: ["/api/CisDovodPodaniaZiadosti/List", "/api/CisUstavZvjs/List"],
  dataCalculations: {
    SC_KIO_051404_QUESTION_CONTROL: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_051404_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE: {
      calculateAt: Calculate.LIVE,
    },
  },
  items: [
    {
      id: "miestoPozadovanehoUmiestnenia",
      type: ZvjsCustomQuestionTypes.SC_KIO_051404_PLACE_OF_LOCATION_QUESTION,
      title: "ziadost.ziadostOumiestnenie.labelMiestoUmiestnenia",
      isRequired: true,
    },
    {
      id: "dovodPodaniaZiadosti",
      title: "Dôvod podania žiadosti",
      type: ZvjsQuestionTypes.DROPDOWN_NEW,
      isRequired: true,
      options: {
        counterKey: "/api/CisDovodPodaniaZiadosti/List",
        textKey: "dovod",
        optValues: [
          {
            id: "ROD",
          },
          {
            id: "BEZP",
          },
          {
            id: "ZMENA",
          },
          {
            id: "INED",
          },
        ],
      },
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_051404_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE",
          conditionMetWhenValueIs: false,
        },
      ],
    },
    {
      id: "doplnujuceInformacie",
      title: "Bližšia špecifikácia dôvodu žiadosti",
      type: ZvjsQuestionTypes.TEXT,
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_051404_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE",
          conditionMetWhenValueIs: false,
        },
      ],
    },
  ],
};
