import React, { useEffect, useState } from "react";
import { useUITranslation } from "../../store/context/translation-context";
import { Box, Stack } from "@mui/material";
import FlipCameraIosOutlinedIcon from "@mui/icons-material/FlipCameraIosOutlined";
import {
  Html5Qrcode,
  Html5QrcodeCameraScanConfig,
  Html5QrcodeScannerState,
  QrcodeErrorCallback,
  QrcodeSuccessCallback,
} from "html5-qrcode";
import { QrDimensions } from "html5-qrcode/core";
import { ZvjsButton } from "./index";
import { capitalize } from "@mui/material/utils";
import { delay } from "../../utils/helpers";

const qrcodeRegionId = "html5qr-code-full-region";

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props: Html5QrcodeCameraScanConfig) => {
  const config: Html5QrcodeCameraScanConfig = {
    fps: 0,
    qrbox: { height: 0, width: 0 },
    aspectRatio: 0,
    disableFlip: false,
  };
  if (props.fps) {
    config.fps = props.fps;
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox;
  }
  if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio;
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip;
  }
  return config;
};

interface html5QrcodePluginInterface extends Html5QrcodeCameraScanConfig {
  verbose?: boolean;
  qrCodeSuccessCallback?: QrcodeSuccessCallback;
  qrCodeErrorCallback?: QrcodeErrorCallback;
  cameraId: "environment" | "user";
}

const Html5QrcodePlugin: React.FC<html5QrcodePluginInterface> = (props) => {
  const [config, setConfig] = useState<Html5QrcodeCameraScanConfig>(
    createConfig(props)
  );
  // const [html5Qrcode, setHtml5Qrcode] = useState<Html5Qrcode>();
  const [cameraId, setCameraID] = useState<number>(
    Math.floor(Math.random() * 10000000)
  );

  const [html5Qrcode, setHtml5Qrcode] = useState<Html5Qrcode>();

  let html5Qrcodetmp: Html5Qrcode;
  let cameraInterval: NodeJS.Timeout | undefined;
  let tryStartInterval: NodeJS.Timeout | undefined;

  useEffect(() => {
    // when component mounts
    setConfig(createConfig(props));
    const verbose = props.verbose === true;
    // Success callback is required.
    if (!props.qrCodeSuccessCallback) {
      // eslint-disable-next-line no-throw-literal
      throw "qrCodeSuccessCallback is required callback.";
    }

    html5Qrcodetmp = new Html5Qrcode(qrcodeRegionId, verbose);
    setHtml5Qrcode(html5Qrcodetmp);
    // setHtml5Qrcode(new Html5Qrcode(qrcodeRegionId, verbose));

    //camera id in local storage for indemnify camera in case where are more than one tab si open (bug in android OS)
    if (localStorage.getItem("cameraId") === null) {
      localStorage.setItem("cameraId", cameraId.toString());
      startCamera();
      startCheckInterval();
    } else {
      console.log(
        "cameraId from another tab:",
        localStorage.getItem("cameraId"),
        "rewriting with my id:",
        cameraId.toString()
      );
      localStorage.setItem("cameraId", cameraId.toString());
      startCameraTryInterval();
    }
    // cleanup function when component will unmount
    return () => {
      if (
        html5Qrcode?.getState() === Html5QrcodeScannerState.SCANNING ||
        html5Qrcode?.getState() === Html5QrcodeScannerState.PAUSED
      ) {
        html5Qrcode.stop().then(() => html5Qrcode.clear());
      }
      console.log("cleanup id of camera", cameraId);
      localStorage.removeItem("cameraId");
      clearInterval(cameraInterval);
      clearInterval(tryStartInterval);
    };
  }, []);

  useEffect(() => {
    console.log("cameraId changed", props.cameraId);
    console.log(
      html5Qrcode?.getState(),
      Html5QrcodeScannerState.SCANNING,
      Html5QrcodeScannerState.PAUSED
    );
    console.log(html5Qrcode);
    if (
      html5Qrcode?.getState() === Html5QrcodeScannerState.SCANNING ||
      html5Qrcode?.getState() === Html5QrcodeScannerState.PAUSED
    ) {
      html5Qrcode?.stop().then((r) => html5Qrcode.clear());
    }

    const delayTimer = async () => {
      await delay(100);
    };
    // stopCheckInterval().then(() => {delayTimer().then(() => {startCamera()})});

    delayTimer().then(() => {
      startCamera();
    });
    startCamera();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cameraId, html5Qrcode]);

  const startCamera = () => {
    Html5Qrcode.getCameras().then((devices) => {
      if (devices && devices.length) {
        // some devices might throw an error when attempting to start camera immediately after cameras are requested, therefore consider sleeping for short time prior camera launch
        delay(100).then(() => {
          html5Qrcode?.start(
            { facingMode: props.cameraId },
            config,
            props.qrCodeSuccessCallback,
            props.qrCodeErrorCallback
          );
        });
      }
    });
    startCheckInterval();
  };

  /**
   * This try to start camera every second until it is successfully
   * On Android os may app go to background and "take camera" from app
   * so when we open another instance we don't have any camera
   */
  const startCameraTryInterval = () => {
    console.log("start TRY camera interval");
    // check if is not defined to prevent multiple intervals
    if (tryStartInterval === undefined) {
      tryStartInterval = setInterval(() => {
        console.log(html5Qrcode?.getState());
        if (
          html5Qrcode?.getState() === Html5QrcodeScannerState.SCANNING ||
          html5Qrcode?.getState() === Html5QrcodeScannerState.PAUSED
        ) {
          clearInterval(tryStartInterval);
          tryStartInterval = undefined;
          console.log("stopping TRY camera interval");
        } else {
          startCamera();
          console.log("TRY started camera");
        }
      }, 2000);
    }
  };

  /**
   * This function check every second if camera id is still the same
   * if same other instance of our app try to use camera, we need to stop our camera
   */
  const startCheckInterval = () => {
    console.log("start interval", cameraInterval);
    // check if is not defined to prevent multiple intervals
    if (cameraInterval === undefined) {
      cameraInterval = setInterval(() => {
        console.log("camera state", html5Qrcode?.getState());
        if (localStorage.getItem("cameraId") !== cameraId.toString()) {
          console.log(
            "find new camera id:",
            localStorage.getItem("cameraId"),
            "stopping my camera:",
            cameraId.toString()
          );
          stopCheckInterval();
        } else {
          console.log("camera id is still the same:", cameraId.toString());
        }
      }, 1000);
    }
  };

  const stopCheckInterval = () => {
    clearInterval(cameraInterval);
    cameraInterval = undefined;
    console.log("stopping check interval");
    if (
      html5Qrcode?.getState() === Html5QrcodeScannerState.SCANNING ||
      html5Qrcode?.getState() === Html5QrcodeScannerState.PAUSED
    ) {
      html5Qrcode.stop().then(() => html5Qrcode.clear());
    }
  };

  return <div id={qrcodeRegionId} />;
};

const Html5QrcodePluginWinTablet: React.FC<html5QrcodePluginInterface> = (
  props
) => {
  const [config, setConfig] = useState<Html5QrcodeCameraScanConfig>(
    createConfig(props)
  );
  const [html5Qrcode, setHtml5Qrcode] = useState<Html5Qrcode>();
  const [isScanning, setIsScanning] = useState(false);
  let html5QrcodeTmp: Html5Qrcode;

  useEffect(() => {
    // when component mounts
    setConfig(createConfig(props));
    const verbose = props.verbose === true;
    // Suceess callback is required.
    if (!props.qrCodeSuccessCallback) {
      // eslint-disable-next-line no-throw-literal
      throw "qrCodeSuccessCallback is required callback.";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    html5QrcodeTmp = new Html5Qrcode(qrcodeRegionId, verbose);
    setHtml5Qrcode(html5QrcodeTmp);

    const initializeScanner = async () => {
      try {
        const devices = await Html5Qrcode?.getCameras();
        if (devices?.length > 0) {
          console.log("devices", devices);
          const cameraId = props.cameraId === "environment" && devices[1] ? devices[1].id : devices[0].id;
          // some devices might throw an error when attempting to start camera immediately after cameras are requested, therefore consider sleeping for short time prior camera launch
          await delay(100);
          await html5QrcodeTmp.start(cameraId, config, props.qrCodeSuccessCallback, props.qrCodeErrorCallback);
          setIsScanning(true);
        } else {
          console.error("No cameras found.");
        }
      } catch (error) {
        console.error("Error fetching cameras: ", error);
      }
    };

    initializeScanner();

    // cleanup function
    return () => {
      const stopScanner = async () => {
        if (isScanning && (html5QrcodeTmp.getState() === Html5QrcodeScannerState.SCANNING || html5QrcodeTmp.getState() === Html5QrcodeScannerState.PAUSED)) {
          await html5QrcodeTmp.stop();
          html5QrcodeTmp.clear();
          setIsScanning(false);
        }
      };
      stopScanner();
    };
  }, []);

  useEffect(() => {
    const restartScanner = async () => {
      if (html5Qrcode) {
        if (isScanning && (html5Qrcode.getState() === Html5QrcodeScannerState.SCANNING || html5Qrcode.getState() === Html5QrcodeScannerState.PAUSED)) {
          await html5Qrcode.stop();
          html5Qrcode.clear();
        }

        try {
          const devices = await Html5Qrcode.getCameras();
          if (devices?.length > 0) {
            const cameraId = props.cameraId === "environment" && devices[1] ? devices[1].id : devices[0].id;
            await html5Qrcode.start(cameraId, config, props.qrCodeSuccessCallback, props.qrCodeErrorCallback);
            setIsScanning(true);
          }
        } catch (error) {
          console.error("Error fetching cameras: ", error);
        }
      }
    };
    restartScanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cameraId]);

  return <div id={qrcodeRegionId} />;
};

interface ZvjsQrReaderProps {
  onScan: (qrCode: string) => void;
}

const ZvjsQrReader: React.FC<ZvjsQrReaderProps> = ({ onScan }) => {
  const { tui } = useUITranslation();

  const [cameraCount, setCameraCount] = useState<number>(0);
  // const [cameraId, setCameraId] = useState<number>(0);
  const [facingMode, setFacingMode] = useState<"environment" | "user">(
    "environment"
  );

  // retrieve initial camera count
  useEffect(() => {
    const fetchCameras = async () => {
      try {
        const devices = await Html5Qrcode.getCameras();
        if (devices && devices.length > 0) {
          setCameraCount(devices.length);
        } else {
          console.warn("No cameras found.");
          setCameraCount(0);
        }
      } catch (error) {
        console.error("Error fetching cameras: ", error);
      }
    };

    fetchCameras();
  }, []);

  // periodical refresh of available cameras (needed at least for iOS)
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     navigator.mediaDevices?.enumerateDevices()
  //       .then(devices => {
  //         const count = devices
  //           .filter(device => {
  //             return device.kind === "videoinput"
  //           })
  //           .length;
  //         // eslint-disable-next-line eqeqeq
  //         if (count != cameraCount) {
  //           setCameraCount(count);
  //         }
  //       });
  //   }, 1000);
  //
  //   return () => clearInterval(interval);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const onNewScanResult: QrcodeSuccessCallback = (
    decodedText,
    decodedResult
  ) => {
    // handle decoded results here
    console.log(decodedText);
    onScan(decodedText);
  };

  const qrboxVar: QrDimensions = { height: 80, width: 289 };
  const isWindows = navigator.userAgent.match(/Windows/i);

  return (
    <Stack spacing={1} alignItems="center">
      {/*{cameraCount > 0 && (*/}
      <>
        <Box width={cameraCount > 0 ? 300 : 0}>
          {isWindows ? (
            <Html5QrcodePluginWinTablet
              fps={30}
              qrbox={qrboxVar}
              disableFlip={false}
              qrCodeSuccessCallback={onNewScanResult}
              cameraId={facingMode}
              //qrCodeErrorCallback={(errorMessage) => zvjsSnackbar(true, errorMessage)} //todo: add snackbar
            />
          ) : (
            <Html5QrcodePlugin
              fps={30}
              qrbox={qrboxVar}
              disableFlip={false}
              qrCodeSuccessCallback={onNewScanResult}
              cameraId={facingMode}
              //qrCodeErrorCallback={(errorMessage) => zvjsSnackbar(true, errorMessage)} //todo: add snackbar
            />
          )}
        </Box>

        {cameraCount > 1 && (
          <ZvjsButton
            zvjsVariant="primaryAction"
            text={capitalize(tui("prepnúť kameru"))}
            startIcon={<FlipCameraIosOutlinedIcon />}
            color={"primary"}
            onClick={() => {
              setFacingMode(
                facingMode === "environment" ? "user" : "environment"
              );
            }}
          />
        )}
      </>
      {/*)}*/}
    </Stack>
  );
};

export default ZvjsQrReader;
