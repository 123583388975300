import { createSelector } from "@reduxjs/toolkit";
import { State, ZvjsCustomQuestion } from "../../../../../../redux/model";
import { findItem } from "../../../../../../redux/slice";
import { selectSelf } from "../../../../../../redux/selectors";
import { SC_KIO_050101_ClosePerson } from "../../../../utils/calculations/custom";
import { getTranslationByLanguageId } from "../../../../../../../../utils/helpers";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";
import { getCiselnikJazyk } from "../../../../../../../../locales/i18n";
import i18n from "i18next";
import { CiselnikListResultType } from "../../../../../../../../store/context/dataApi/Data";
import {
  DetailZakladneUdajeKlientaData,
  FyzickaOsobaKontakt,
} from "../../../../../../../specs/swaggerTypes";
import { SC_KIO_050101_CLOSE_PEOPLE_LIST } from "../../../../../../requestTemplates/SC_KIO_050101_Ziadost o navstevu";

/**
 * Selectors
 */
const getVideoCallVisitPeopleGridDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const { getFallbackJazyk } = useUITranslation();

    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    const isVND = state.answers.formaRealizacieNavstevy === "VND";

    return {
      id: question.id,
      title: question.title,
      peopleList: (
        state.userCalcData[
          SC_KIO_050101_CLOSE_PEOPLE_LIST
          ] as SC_KIO_050101_ClosePerson[]
      )
        .filter((person) => {
          return isVND ? person.isUnderage : !person.isUnderage;
        })
        .map((person) => {
          return {
            id: person.fyzickaOsobaId,
            firstname: person.firstname,
            surname: person.surname,
            relationText:
              state.counters["/api/CisTypBlizkejOsoby/List"]?.records &&
              person.relationId
                ? getTranslationByLanguageId(
                  state.counters["/api/CisTypBlizkejOsoby/List"].records,
                  getCiselnikJazyk(i18n.language),
                  getFallbackJazyk(),
                  person.relationId,
                  "nazov"
                )
                : undefined,
            videoCallContact: person.videoCallContact,
          } as SC_KIO_050101_VideoCallVisitClosePersonDisplayData;
        }),
    };
  });

const getVideoCallVisitClosePeopleList = () =>
  createSelector(selectSelf, (state: State): SC_KIO_050101_ClosePerson[] => {
    return state.userCalcData[
      SC_KIO_050101_CLOSE_PEOPLE_LIST
    ] as SC_KIO_050101_ClosePerson[];
  });

const getVideoCallClosePersonRelationTypeCounter = () =>
  createSelector(
    selectSelf,
    (
      state: State
    ): CiselnikListResultType<"/api/CisTypBlizkejOsoby/List">["data"] => {
      if (state.counters["/api/CisTypBlizkejOsoby/List"]) {
        return state.counters["/api/CisTypBlizkejOsoby/List"];
      }
      return {};
    }
  );

const getVideoCallBasicClientData = () =>
  createSelector(selectSelf, (state: State): DetailZakladneUdajeKlientaData => {
    return state.userCalcData["BASIC_CLIENT_DATA"];
  });

export const allSelectors = {
  getVideoCallVisitPeopleGridDisplayData,
  getVideoCallVisitClosePeopleList,
  getVideoCallClosePersonRelationTypeCounter,
  getVideoCallBasicClientData,
};

export interface SC_KIO_050101_VideoCallVisitClosePersonDisplayData {
  id: number;
  firstname: string | undefined;
  surname: string | undefined;
  relationText: string | undefined;
  videoCallContact: FyzickaOsobaKontakt | undefined;
}
