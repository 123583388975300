import { API_Clients } from "../../../../../store/context/dataApi/Data";
import { CisPohlavie_ZENA_ID } from "../../../../specs/countersValues";
import { getIsFemale } from "./calculations/reusable";

export type IsAvailableToUserType =
  | "SC_KIO_051001_isAvailableToUser"
  | "SC_KIO_051402_isAvailableToUser"
  | "SC_KIO_051401_isAvailableToUser"
  | "SC_KIO_051201_isAvailableToUser"
  | "SC_KIO_051104_isAvailableToUser"
  | "SC_KIO_051004_isAvailableToUser"
  | "SC_KIO_050602_isAvailableToUser"
  | "SC_KIO_050402_isAvailableToUser"
  | "SC_KIO_050207_isAvailableToUser"
  | "SC_KIO_050206_isAvailableToUser"
  | "SC_KIO_050205_isAvailableToUser"
  | "SC_KIO_051404_isAvailableToUser";

const SC_KIO_051001_isAvailableToUser = async () => {
  const isUserFemale = await getIsFemale();
  if (isUserFemale) {
    return false;
  }
  return true;
};

const SC_KIO_051402_isAvailableToUser = async () => {
  // TODO add real api calls
  const hasOtherRequestOfThisTypeInProgress = false;
  if (hasOtherRequestOfThisTypeInProgress) {
    return false;
  }

  return true;
};

const SC_KIO_051401_isAvailableToUser = async () => {
  const { SZOO_Get } = await API_Clients();
  const responseSzoo = await SZOO_Get("/api/Kio/ClientRequestValidate", {
    params: {
      query: {
        klientId: localStorage.getItem("klientObjectId") ?? "",
        typZiadosti: 7,
      },
    },
  });

  if (responseSzoo.error || !responseSzoo.response.ok || !responseSzoo.data) {
    console.error("Failed to fetch request body from Szoo");
  }

  if (responseSzoo?.data === undefined){
    const isAccused = localStorage.getItem("aktivnaVazba");
    return isAccused === null ? true : JSON.parse(isAccused);
  }

  return !responseSzoo?.data?.porusenePodmienky?.some((x: string) => x === "BR037" || x === "BR038" || x === "BR039");
};

const SC_KIO_051201_isAvailableToUser = async () => {
  // TODO add real api calls
  const userHasSpecialDiet = false;
  if (userHasSpecialDiet) {
    return false;
  }

  const hasAlreadyRequestedThisCalendarMonth = false;
  if (hasAlreadyRequestedThisCalendarMonth) {
    return false;
  }

  return true;
};

const SC_KIO_051104_isAvailableToUser = async () => {
  // TODO add real api calls
  const userUseInstitutionClothes = true;
  if (!userUseInstitutionClothes) {
    return false;
  }

  return true;
};

const SC_KIO_051004_isAvailableToUser = async () => {
  // TODO add real api calls
  const hasAlreadyRequestedThisCalendarMonth = false;
  if (hasAlreadyRequestedThisCalendarMonth) {
    return false;
  }

  const shoppedThisCalendarMonth = false;
  if (shoppedThisCalendarMonth) {
    return false;
  }

  const otherChecks = true;
  if (!otherChecks) {
    return false;
  }

  return true;
};

const SC_KIO_050602_isAvailableToUser = async () => {
  const isAnyProgramAvailableToClient = true;
  // TODO check whether there is in a client's institution any program that he can attend and have not applied for yet
  if (!isAnyProgramAvailableToClient) {
    return false;
  }
  return true;
};

const SC_KIO_050402_isAvailableToUser = async () => {
  // TODO add real api calls
  const hasAlreadyRequestedToday = false;
  if (hasAlreadyRequestedToday) {
    return false;
  }

  return true;
};

const SC_KIO_050207_isAvailableToUser = async () => {
  // TODO add real api calls
  const isUserAllowedToManipulateWithFunds = true;
  if (!isUserAllowedToManipulateWithFunds) {
    return false;
  }

  const userHasAnyActiveExtraInstitutionLeaveAllowance = true;
  if (!userHasAnyActiveExtraInstitutionLeaveAllowance) {
    return false;
  }

  const isUserSentenced = true;
  if (!isUserSentenced) {
    return false;
  }

  return true;
};

const SC_KIO_050206_isAvailableToUser = async () => {
  // TODO add real api calls
  const isUserSentenced = true;
  if (!isUserSentenced) {
    return false;
  }

  const hasAnyChildSupportObligation = true;
  if (!hasAnyChildSupportObligation) {
    return false;
  }

  return true;
};

const SC_KIO_050205_isAvailableToUser = async () => {
  // TODO add real api calls
  const hasAlreadyRequestedThisMonth = false;
  if (hasAlreadyRequestedThisMonth) {
    return false;
  }

  const hasMoneyOnAccount = true;
  if (!hasMoneyOnAccount) {
    return false;
  }

  return true;
};

const SC_KIO_051404_isAvailableToUser = async () => {
  const { SZOO_Get } = await API_Clients();
  const responseSzoo = await SZOO_Get("/api/Kio/ClientRequestValidate", {
    params: {
      query: {
        klientId: localStorage.getItem("klientObjectId") ?? "",
        typZiadosti: 5,
      },
    },
  });

  if (responseSzoo.error || !responseSzoo.response.ok || !responseSzoo.data) {
    console.error("Failed to fetch request body from Szoo");
  }

  if (responseSzoo?.data === undefined){
    const isSentenced = localStorage.getItem("aktivnyTrest");
    return isSentenced === null ? true : JSON.parse(isSentenced);
  }

  return !responseSzoo?.data?.porusenePodmienky?.some((x: string) => x === "BR034" || x === "BR035");
};


const isAvailableToUserFunctions: IsAvailableToUserFunctionsType = {
  SC_KIO_051001_isAvailableToUser: SC_KIO_051001_isAvailableToUser,
  SC_KIO_051402_isAvailableToUser: SC_KIO_051402_isAvailableToUser,
  SC_KIO_051401_isAvailableToUser: SC_KIO_051401_isAvailableToUser,
  SC_KIO_051201_isAvailableToUser: SC_KIO_051201_isAvailableToUser,
  SC_KIO_051104_isAvailableToUser: SC_KIO_051104_isAvailableToUser,
  SC_KIO_051004_isAvailableToUser: SC_KIO_051004_isAvailableToUser,
  SC_KIO_050602_isAvailableToUser: SC_KIO_050602_isAvailableToUser,
  SC_KIO_050402_isAvailableToUser: SC_KIO_050402_isAvailableToUser,
  SC_KIO_050207_isAvailableToUser: SC_KIO_050207_isAvailableToUser,
  SC_KIO_050206_isAvailableToUser: SC_KIO_050206_isAvailableToUser,
  SC_KIO_050205_isAvailableToUser: SC_KIO_050205_isAvailableToUser,
  SC_KIO_051404_isAvailableToUser: SC_KIO_051404_isAvailableToUser,
};

type IsAvailableToUserFunctionsType = {
  [key in IsAvailableToUserType]: () => Promise<boolean>;
};

export default isAvailableToUserFunctions;
