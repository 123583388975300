import {
  Calculate,
  ZvjsConditionTypes,
  ZvjsItemTypes,
  ZvjsLabelFlagTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from '../redux/model';

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050302",
  basicInfo: [
    {
      text: "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.zakladneInfo",
    },
  ],
  dataCalculations: {
    SC_KIO_050101_CLOSE_PEOPLE_LIST: {
      calculateAt: Calculate.AT_INIT,
    },
    BASIC_CLIENT_DATA: {
      calculateAt: Calculate.AT_INIT,
    },
    CAN_ACCEPT_PACKAGE_WITH_PERSONAL_ITEMS: {
      calculateAt: Calculate.AT_INIT,
    },
    CAN_ACCEPT_PACKAGE_WITH_CLOTHES_AND_FOOTWEAR: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_050302_AVAILABLE_USER_PACKAGE_TYPE_OPTIONS: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_050302_SHOULD_DISPLAY_PERSONAL_BELONGINGS_PACKAGE_OPTION: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_050302_SHOULD_DISPLAY_OTHER_PERSONAL_BELONGINGS_OPTION: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_050302_SHOULD_DISPLAY_CLOTHING_PACKAGE_OPTION: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_050302_SHOULD_DISPLAY_SHIRT_OPTION: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_050302_SHOULD_DISPLAY_SHOES_OPTION: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_050302_SHOULD_DISPLAY_UNDERPANTS_OPTION: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_050302_SHOULD_DISPLAY_WINTER_JACKET_HAT_OPTION: {
      calculateAt: Calculate.LIVE,
    }
  },
  counters: [
    "/api/CisDruhBalika/List",
    "/api/CisTypBlizkejOsoby/List",],
  items: [
    {
      id: "druhBalika",
      title: "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.labelDruhBalika",
      type: ZvjsQuestionTypes.DROPDOWN_NEW,
      isRequired: true,
      options: {
        counterKey: "/api/CisDruhBalika/List",
        textKey: "druh_balika",
        optValues: [
          {
            id: "1",
            conditions: [
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc: "SC_KIO_050302_SHOULD_DISPLAY_PERSONAL_BELONGINGS_PACKAGE_OPTION",
                conditionMetWhenValueIs: true,
              },
            ],
          },
          {
            id: "3",
            conditions: [
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc: "SC_KIO_050302_SHOULD_DISPLAY_CLOTHING_PACKAGE_OPTION",
                conditionMetWhenValueIs: true,
              },
            ],
          },
          {
            id: "8",
          },
        ],
      },
    },
    {
      type: ZvjsItemTypes.LABEL,
      text: "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.napovedaObmenaOdevu",
      flag: ZvjsLabelFlagTypes.WARNING,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "druhBalika",
          value: ["3"],
        },
      ],
    },
    {
      id: "polozkyBalika",
      title:
        "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.labelPozadovaneVeci",
      type: ZvjsQuestionTypes.NESTED_DROPDOWN,
      isRequired: false,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "druhBalika",
          hasValue: true,
        },
      ],
      options: {
        counterKey: "/api/CisDruhBalika/List",
        textKey: "druh_balika",
        answerId: "polozkyBalika",
        children: [
          {
            id: "2",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "druhBalika",
                value: ["1"],
              },
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc: "SC_KIO_050302_SHOULD_DISPLAY_OTHER_PERSONAL_BELONGINGS_OPTION",
                conditionMetWhenValueIs: true,
              },
            ],
          },
          {
            id: "4",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "druhBalika",
                value: ["3"],
              },
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc: "SC_KIO_050302_SHOULD_DISPLAY_SHIRT_OPTION",
                conditionMetWhenValueIs: true,
              },
            ],
          },
          {
            id: "5",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "druhBalika",
                value: ["3"],
              },
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc: "SC_KIO_050302_SHOULD_DISPLAY_SHOES_OPTION",
                conditionMetWhenValueIs: true,
              },
            ],
          },
          {
            id: "6",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "druhBalika",
                value: ["3"],
              },
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc: "SC_KIO_050302_SHOULD_DISPLAY_UNDERPANTS_OPTION",
                conditionMetWhenValueIs: true,
              },
            ],
          },
          {
            id: "7",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "druhBalika",
                value: ["3"],
              },
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc: "SC_KIO_050302_SHOULD_DISPLAY_WINTER_JACKET_HAT_OPTION",
                conditionMetWhenValueIs: true,
              },
            ],
          },
          {
            id: "9",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "druhBalika",
                value: ["8"],
              },
            ],
          },
          {
            id: "10",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "druhBalika",
                value: ["8"],
              },
            ],
          },
          {
            id: "11",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "druhBalika",
                value: ["8"],
              },
            ],
          },
          {
            id: "13",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "druhBalika",
                value: ["8"],
              },
            ],
          },
          {
            id: "14",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "druhBalika",
                value: ["8"],
              },
            ],
          },
        ],
      },
    },
    {
      id: "ineVeciOsobnejPotrebySpecifikacia",
      title: "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.labelIneVeci",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "polozkyBalika",
          value: [{ polozkyBalika: "2" }],
        },
      ],
    },
    {
      id: "literaturaSpecifikacia",
      title:
        "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.labelLiterarneDielo",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      hintText:
        "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.napovedaObsahBalikaKnihy",
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "polozkyBalika",
          value: [{ polozkyBalika: "10" }],
        },
      ],
    },
    {
      id: "zaujmovaCinnostSpecifikacia",
      title:
        "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.labelZaujmovaCinnost",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      hintText:
        "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.napovedaObsahBalikaPocty",
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "polozkyBalika",
          value: [{ polozkyBalika: "11" }],
        },
      ],
    },
    {
      id: "sportovaPomockaSpecifikacia",
      title:
        "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.labelSportovaPomocka",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "polozkyBalika",
          value: [{ polozkyBalika: "14" }],
        },
      ],
    },
    {
      type: ZvjsItemTypes.LABEL,
      text: "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.napovedaObsahBalikaIneVeci",
      flag: ZvjsLabelFlagTypes.INFO,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "druhBalika",
          value: ["1"],
        },
      ],
    },
    {
      type: ZvjsItemTypes.LABEL,
      text: "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.napovedaBalikMimoEU",
      flag: ZvjsLabelFlagTypes.WARNING,
    },
    {
      id: "SC_KIO_050302_QUES_05",
      title:
        "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.labelOdoslaniePO",
      type: ZvjsQuestionTypes.SINGLE_CHECKBOX,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "polozkyBalika",
          hasValue: true,
        },
      ],
    },
    /*
    {
      type: ZvjsItemTypes.SECTION,
      title: "Údaje právnickej osoby, ktorá má zaslať balík",
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050302_QUES_05",
          hasValue: true,
        },
      ],
      items: [
        {
          type: ZvjsItemTypes.LABEL,
          text: "Sekcia pre pravnicku osobu.",
          flag: ZvjsLabelFlagTypes.INFO,
        },
      ],
    },
    {
      id: 'osobaOdosielajucaBalik',
      title: "Blízke osoby",
      type: ZvjsCustomQuestionTypes.SC_KIO_050302_PERSON_SENDING_PACKAGE_GRID,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050302_QUES_05",
          hasValue: false,
        },
      ],
    },*/
  ],
};
