import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import {
  createSearchParams,
  defer,
  Params,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  Dashboard_SC_KIO_0200,
  ZvjsButton,
  ZvjsSearchBar,
  ZvjsTable,
} from "../../../common";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { withLoader } from "../../../hoc/withLoader";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { ZvjsSearchBarVariant } from "../../../common/components/ZvjsSearchBar";
import { MAX_NUMBER_OF_ITEMS } from "../../../store/context/dataApi/Data";
import { API_Clients } from "../../../store/context/dataApi/Data";
import { LoaderError } from "../../../router/LoaderError";
import moment from "moment";

const loadData = async (params: Params, request: Request) => {
  const searchParams = new URL(request.url).searchParams;
  const datumOd = searchParams.get("datumOd")
    ? decodeURIComponent(searchParams.get("datumOd") || "")
    : undefined;
  const datumDo = searchParams.get("datumDo")
    ? decodeURIComponent(searchParams.get("datumDo") || "")
    : undefined;

  console.debug(`Baliky loadData datumOd[${datumOd}] datumDo[${datumDo}]`);

  const { CIS_Post, SHARE_INT_Post } = await API_Clients();

  const data = await Promise.all([
    CIS_Post("/api/CisDruhBalika/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
    SHARE_INT_Post("/api/interfaces/Epvvvt/ListZoznamPrijatychBalikovKlienta", {
      body: {
        klientId: {
          eq: localStorage.getItem("klientObjectId") ?? "faultyId",
        },
        dorucenyDatum:
          !!datumOd || !!datumDo
            ? {
                gte: datumOd ?? undefined,
                lte: datumDo ?? undefined,
              }
            : undefined,
      },
    }),
  ]);

  if (data[0].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [druh balika]");
  }

  if (data[1].error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [zoznam prijatych balikov]"
    );
  }

  const { EOO_Get, EOO_Post } = await API_Clients();

  const zoznamPrijatychBalikov = await Promise.all(
    data[1].data.map(async (item) => {
      try {
        let odosielatelNazov = "";

        if (item.dorucenyRealOdosielatelFyzOsobaId) {
          const prijimatelDetail = await EOO_Get(
            "/api/FyzickaOsoba/DetailData",
            {
              params: {
                query: {
                  request: Number(item.dorucenyRealOdosielatelFyzOsobaId),
                },
              },
            }
          );

          const fyzickaOsobaData = prijimatelDetail?.data?.data;
          if (fyzickaOsobaData) {
            odosielatelNazov =
              `${fyzickaOsobaData.meno || ""} ${fyzickaOsobaData.priezvisko || ""}`.trim();
          }
        } else if (item.dorucenyRealOdosielatelPravOsobaId) {
          const pravnickeOsoby = await EOO_Post("/api/PravnickaOsoba/List", {
            body: {
              // filters: [
              //   {},
              // ],
            },
          });

          const matchingPravnickaOsoba = pravnickeOsoby?.data?.records?.find(
            (record) =>
              record.id === Number(item.dorucenyRealOdosielatelPravOsobaId)
          );

          if (matchingPravnickaOsoba) {
            odosielatelNazov = matchingPravnickaOsoba.nazov || "";
          }
        }

        return {
          ...item,
          odosielatelNazov: odosielatelNazov,
        };
      } catch (error) {
        console.error("Error processing item:", item, error);
        return {
          ...item,
          odosielatelNazov: "",
        };
      }
    })
  );

  return {
    cisDruhBalika: data[0],
    baliky: zoznamPrijatychBalikov,
  };
};

export const BalikyLoader = async ({
  params,
  request,
}: {
  params: Params;
  request: Request;
}) => {
  return defer({
    data: loadData(params, request),
  });
};

interface BalikyProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const Baliky = (props: BalikyProps) => {
  const { tui, getFallbackJazyk } = useUITranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  const onSearch = (dateFrom: Date, dateTo: Date) => {
    console.debug(`Baliky onSearch dateFrom[${dateFrom}] dateTo[${dateTo}]`);
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams({
          ...(dateFrom.isValid() && {
            datumOd: encodeURIComponent(moment(dateFrom).toISOString()),
          }),
          ...(dateTo.isValid() && {
            datumDo: encodeURIComponent(moment(dateTo).toISOString()),
          }),
        }).toString()}`,
      },
      { replace: true }
    );
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(tui("osobneUdaje.kategorieOsobnychUdajov.baliky"))}
          </Typography>
        </Stack>
      </Grid>
      <Stack direction="column" spacing={2}>
        <ZvjsSearchBar
          title={tui("vyhľadať v balíkoch")}
          type={ZvjsSearchBarVariant.VARIANT_A_DpDp}
          onSubmit={onSearch}
        />

        <ZvjsTable
          data={{
            header: [
              {
                value: capitalize(tui("dátum prijatia")),
                align: "left",
                width: "calc(100% / 3)",
              },
              {
                value: capitalize(tui("typ balíka")),
                align: "left",
                width: "calc(100% / 3)",
              },
              {
                value: capitalize(tui("odosielateľ")),
                align: "left",
                width: "calc(100% / 3)",
              },
            ],
            body: props.data.baliky
              ?.sort(
                (p1, p2) =>
                  new Date(p2?.dorucenyDatum ?? new Date()).valueOf() -
                  new Date(p1?.dorucenyDatum ?? new Date()).valueOf()
              )
              ?.map((balik) => {
                const rd: RowData = {
                  row: [
                    {
                      value: new Date(
                        balik?.dorucenyDatum ?? new Date()
                      ).zvjsToString(),
                      align: "left",
                      width: "calc(100% / 3)",
                    },
                    {
                      value: balik?.balikDruhNazov
                        ? balik?.balikDruhNazov
                        : (balik?.balikDruhId &&
                            props.data.cisDruhBalika.data?.records?.find(
                              (cdb) =>
                                cdb.kod ===
                                (balik as any)?.balikDruhId?.toString()
                            )?.druh_balika) ||
                          "",
                      align: "left",
                      width: "calc(100% / 3)",
                    },
                    {
                      value: (balik as any)?.odosielatelNazov ?? "",
                      align: "left",
                      width: "calc(100% / 3)",
                    },
                  ],
                };
                return rd;
              }),
          }}
          height={360}
          variant={ZvjsTableVariant.NORMAL}
          // TODO add translation
          hintText={" "}
        />

        <Grid width="25rem">
          <ZvjsButton
            text={capitalize(
              tui("žiadosť o vydanie povolenia na prijatie balíka")
            )}
            endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
            style={{
              lineHeight: "1",
              textAlign: "left",
              textTransform: "none",
            }}
            zvjsVariant="secondaryAction"
            onClick={() => {
              navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}/01.01.01`);
            }}
          />
        </Grid>
      </Stack>
    </Grid>
  );
};

export default withLoader(Baliky);
