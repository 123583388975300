import {
  Calculate,
  ZvjsConditionTypes,
  ZvjsCustomQuestionTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

// To avoid the need to implement the condition when question is required there is duplicated third question
// The first one is not required and is displayed in scenario A and the second one is not required and is displayed in scenario B
export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_051402",
  basicInfo: [
    {
      text: "ziadost.ziadostOumiestnenieVaktualnomUstave.zakladneInfoObvineny",
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "IS_USER_ACCUSED",
          conditionMetWhenValueIs: true,
        },
      ],
    },
    {
      text: "ziadost.ziadostOumiestnenieVaktualnomUstave.zakladneInfoOdsudeny",
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "IS_USER_SENTENCED",
          conditionMetWhenValueIs: true,
        },
      ],
    },
  ],
  isAvailableToUser: "SC_KIO_051402_isAvailableToUser",
  dataCalculations: {
    IS_USER_ACCUSED: {
      calculateAt: Calculate.AT_INIT,
    },
    IS_USER_SENTENCED: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_051402_QUESTION_CONTROL: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_051402_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE: {
      calculateAt: Calculate.LIVE,
    }
  },
  counters: ["/api/CisDovodZiadosti/List", "/api/CisTypPremiestnenia/List"],
  items: [
    {
      id: "SC_KIO_051402_NOT_ALLOWED_TO_SEND_REQUEST_LABEL_QUESTION",
      type: ZvjsCustomQuestionTypes.SC_KIO_051402_NOT_ALLOWED_TO_SEND_REQUEST_LABEL,
      title: "",
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_051402_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE",
          conditionMetWhenValueIs: true,
        },
      ],
    },
    {
      id: "typZiadostiOPremiestnenie",
      title: "ziadost.ziadostOumiestnenieVaktualnomUstave.labelTypZiadosti",
      type: ZvjsQuestionTypes.DROPDOWN_NEW,
      isRequired: true,
      options: {
        counterKey: "/api/CisTypPremiestnenia/List",
        textKey: "typ",
        optValues: [
          {
            id: "INAC",
          },
          {
            id: "SAMU",
          },
        ],
      },
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_051402_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE",
          conditionMetWhenValueIs: false,
        },
      ],
    },
    {
      id: "dovodZiadostiOUmiestnenie",
      title: "Dôvod žiadosti",
      type: ZvjsQuestionTypes.DROPDOWN_NEW,
      isRequired: true,
      options: {
        counterKey: "/api/CisDovodZiadosti/List",
        textKey: "nazov",
        optValues: [
          {
            id: "NEZHOD",
          },
          {
            id: "INEBD",
          },
          {
            id: "INED",
          },
        ],
      },
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_051402_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE",
          conditionMetWhenValueIs: false,
        },
      ],
    },
    {
      id: "dovodPreNezhodySoSpolubyvajucimi",
      title: "ziadost.ziadostOumiestnenieVaktualnomUstave.labelDovodZiadosti",
      type: ZvjsQuestionTypes.TEXT,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "dovodZiadostiOUmiestnenie",
          value: ["NEZHOD"],
        },
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_051402_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE",
          conditionMetWhenValueIs: false,
        },
      ],
    },
    {
      id: "dovodPreIne",
      title: "ziadost.ziadostOumiestnenieVaktualnomUstave.labelDovodZiadosti",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "dovodZiadostiOUmiestnenie",
          value: ["INEBD", "INED"],
        },
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_051402_HAS_SAME_REQUEST_IN_NON_TERMINATED_STATE",
          conditionMetWhenValueIs: false,
        },
      ],
    },
  ],
};
