import { createSelector } from "@reduxjs/toolkit";
import { State, ZvjsCustomQuestion } from "../../../../../../redux/model";
import { findItem } from "../../../../../../redux/slice";
import { selectSelf } from "../../../../../../redux/selectors";
import { SC_KIO_050101_ClosePerson } from "../../../../utils/calculations/custom";
import { getTranslationByLanguageId } from "../../../../../../../../utils/helpers";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";
import { getCiselnikJazyk } from "../../../../../../../../locales/i18n";
import i18n from "i18next";
import { CiselnikListResultType } from "../../../../../../../../store/context/dataApi/Data";
import { DetailZakladneUdajeKlientaData } from "../../../../../../../specs/swaggerTypes";
import { SC_KIO_050101_CLOSE_PEOPLE_LIST } from "../../../../../../requestTemplates/SC_KIO_050101_Ziadost o navstevu";
import {
  SC_KIO_050101_PhysicalVisitClosePersonDisplayData
} from '../SC_KIO_050101_PhysicalVisitPeopleGrid/redux';

/**
 * Selectors
 */
const getPhysicalVisitPeopleGridDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const { getFallbackJazyk } = useUITranslation();

    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    return {
      id: question.id,
      title: question.title,
      peopleList: (
        state.userCalcData[
          SC_KIO_050101_CLOSE_PEOPLE_LIST
          ] as SC_KIO_050101_ClosePerson[]
      ).map((person) => {
        return {
          id: person.fyzickaOsobaId,
          firstname: person.firstname,
          surname: person.surname,
          relationText:
            state.counters["/api/CisTypBlizkejOsoby/List"]?.records &&
            person.relationId
              ? getTranslationByLanguageId(
                state.counters["/api/CisTypBlizkejOsoby/List"].records,
                getCiselnikJazyk(i18n.language),
                getFallbackJazyk(),
                person.relationId,
                "nazov"
              )
              : undefined,
        } as SC_KIO_050101_PhysicalVisitClosePersonDisplayData;
      }),
    };
  });

const getPhysicalVisitClosePeopleList = () =>
  createSelector(selectSelf, (state: State): SC_KIO_050101_ClosePerson[] => {
    return state.userCalcData[
      SC_KIO_050101_CLOSE_PEOPLE_LIST
    ] as SC_KIO_050101_ClosePerson[];
  });

const getPhysicalVisitClosePersonRelationTypeCounter = () =>
  createSelector(
    selectSelf,
    (
      state: State
    ): CiselnikListResultType<"/api/CisTypBlizkejOsoby/List">["data"] => {
      if (state.counters["/api/CisTypBlizkejOsoby/List"]) {
        return state.counters["/api/CisTypBlizkejOsoby/List"];
      }
      return {};
    }
  );

const getPhysicalVisitBasicClientData = () =>
  createSelector(selectSelf, (state: State): DetailZakladneUdajeKlientaData => {
    return state.userCalcData["BASIC_CLIENT_DATA"];
  });

export const allSelectors = {
  getPhysicalVisitPeopleGridDisplayData,
  getPhysicalVisitClosePeopleList,
  getPhysicalVisitClosePersonRelationTypeCounter,
  getPhysicalVisitBasicClientData,
};

export interface SC_KIO_050302_PersonSendingPackageDisplayData {
  id: number;
  firstname: string | undefined;
  surname: string | undefined;
  relationText: string | undefined;
}
