import {
  Box,
  Collapse,
  SxProps,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { ReactElement, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { ZVJS_COLORS } from "../../theme/zvjs_theme";
import { ZvjsButton, ZvjsHintModalFragment } from "./index";
import { useNavigation } from "react-router-dom";
import { isSubmitting } from "../../utils/helpers";
import { useUITranslation } from "../../store/context/translation-context";
import { capitalize } from "@mui/material/utils";
import { Property } from "csstype";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export enum ZvjsTableVariant {
  NORMAL,
  INFINITE_SCROLL,
}

interface ZvjsTableProps {
  //placeholder
  height?: number | string | "auto";
  variant: ZvjsTableVariant;
  hintTitle?: string;
  hintText?: string;
  fontSizes?: TableFontSizes;
}

interface ZvjsTableNormalProps extends ZvjsTableProps {
  variant: ZvjsTableVariant.NORMAL;
  data: TableData;
}

interface ZvjsTableInfiniteScrollProps extends ZvjsTableProps {
  variant: ZvjsTableVariant.INFINITE_SCROLL;
  data: TableDataInfinityScroll;
  /**
   * function that will be called when user scrolls to the bottom of the table and user wants to fetch new data
   * @param page
   * @param rowOnPage
   */
  onLoadMore: (page: number, rowOnPage: number) => void;
  pageSize: number;
}

type ZvjsTableTypes = ZvjsTableNormalProps | ZvjsTableInfiniteScrollProps;

export interface TableStyle {
  align?: "center" | "right" | "left" | "inherit" | "justify" | undefined;
  width?: number | string | undefined;
  bold?: boolean;
  // these parameters apply only to body cells when a component is provided
  sx?: {
    display?: Property.Display | undefined;
    justifyContent?: Property.JustifyContent | undefined;
  };
}

export interface TableData {
  label?: string;
  header: CellData[];
  body: RowData[];
}

export interface TableDataInfinityScroll extends TableData {
  pageNumber: number;
  totalPageNumber: number;
}

export interface RowData {
  row: CellData[];
  rowDetail?: {
    header: CellData[];
    body: {
      row: CellData[];
    }[];
  };
}

export interface CellData extends TableStyle {
  value: string | number | undefined | ReactElement;
}

interface TableFontSizes {
  tableLabel: number;
  header: number;
  body: number;
}

export const RequestFontSizes: TableFontSizes = {
  tableLabel: 24,
  header: 16,
  body: 19,
};

/**
 * Infinity scroll is not supported in categorized table
 */
const ZvjsTable: React.FC<ZvjsTableTypes> = ({
  data,
  height = "auto",
  variant,
  hintTitle,
  hintText,
  fontSizes,
  ...rest
}) => {
  const [tableData, setTableData] = React.useState<
    TableDataInfinityScroll | TableData
  >(data);
  const navigation = useNavigation();
  const { tui } = useUITranslation();
  const [expandedRow, setExpandedRow] = useState<undefined | number>(undefined);
  // variable determines if detail column is being displayed as the last column of table
  const hasDetail = data.body.some((row) => row.rowDetail !== undefined);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const renderRows = () => {
    const tableDataNormal = tableData as TableData;
    const tableDataInfinityScroll = tableData as TableDataInfinityScroll;

    const renderRows = (rowData: RowData[]) => {
      const getTableBodyCellStyle = (
        rowIndex: number,
        cellIndex: number,
        numberOfColumns: number,
        width: string | number | undefined = undefined
      ) => {
        let toReturn: SxProps<Theme> = {
          width: width,
          paddingLeft: 1,
          paddingRight: 1,
          paddingTop: 2,
          paddingBottom: 2,
          backgroundColor: ZVJS_COLORS.WHITE,
          border: "none",
        };

        if (cellIndex === 0) {
          toReturn = {
            ...toReturn,
            paddingLeft: 3,
          };
        }

        if (cellIndex === numberOfColumns - 1) {
          toReturn = {
            ...toReturn,
            paddingRight: 3,
          };
        }

        if (rowIndex === 0 && cellIndex === 0) {
          toReturn = {
            ...toReturn,
            borderTopLeftRadius: "4px",
          };
        }

        if (rowIndex === rowData.length - 1 && cellIndex === 0) {
          toReturn = {
            ...toReturn,
            borderBottomLeftRadius: "4px",
          };
        }

        if (rowIndex === 0 && cellIndex === numberOfColumns - 1) {
          toReturn = {
            ...toReturn,
            borderTopRightRadius: "4px",
          };
        }

        if (
          rowIndex === rowData.length - 1 &&
          cellIndex === numberOfColumns - 1
        ) {
          toReturn = {
            ...toReturn,
            borderBottomRightRadius: "4px",
          };
        }

        return toReturn;
      };

      return rowData.map((row, index, body) => {
        const toReturn = [
          <TableRow
            key={index}
            sx={
              // add row (row detail parent) divider when:
              // row detail is expanded or
              // in all cases except for the last row of the table or situation when Load more button in infinity scroll mode is displayed
              index === expandedRow ||
              index < rowData.length - 1 ||
              tableDataInfinityScroll.pageNumber <
                tableDataInfinityScroll.totalPageNumber
                ? {
                    position: "relative",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      /* 100% width - margins (16px left, 16px right) */
                      width: "calc(100% - 32px)",
                      bottom: 0,
                      left: 0,
                      marginLeft: 2,
                      marginRight: 2,
                      borderBottom: `1px solid ${ZVJS_COLORS.GREY_2}`,
                    },
                  }
                : {}
            }
          >
            {row.row.map((cell, cellIndex) => (
              <TableCell
                key={`${index}${cellIndex}`}
                align={cell.align}
                sx={{
                  ...getTableBodyCellStyle(
                    index,
                    cellIndex,
                    // if there is row detail, additional Detail column is added to the table
                    hasDetail ? row.row.length + 1 : row.row.length,
                    cell.width
                  ),
                  // if a detail of this row is expanded, make sure that you do not add bottom border radius
                  ...(index === expandedRow
                    ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }
                    : {}),
                }}
              >
                {typeof cell.value !== "number" &&
                typeof cell.value !== "string" ? (
                  <Box
                    sx={{
                      display: cell.sx?.display,
                      justifyContent: cell.sx?.justifyContent,
                    }}
                  >
                    {cell.value}
                  </Box>
                ) : (
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight:
                        cell.bold === undefined ? 400 : cell.bold ? 700 : 400,
                      fontSize: fontSizes ? fontSizes?.body : 19,
                    }}
                  >
                    {cell.value as string | number}
                  </Typography>
                )}
              </TableCell>
            ))}
            {hasDetail && (
              <TableCell
                sx={{
                  ...getTableBodyCellStyle(
                    index,
                    row.row.length,
                    hasDetail ? row.row.length + 1 : row.row.length
                  ),
                  // if there is row detail, additional Detail column is added to the table
                  paddingTop: 0,
                  paddingBottom: 0,
                  width: 165,
                }}
              >
                {row.rowDetail !== undefined && (
                  <ZvjsButton
                    sx={{ height: 45, width: "100%", fontSize: 19 }}
                    text={capitalize(
                      // TODO Add translations
                      tui(expandedRow === index ? "zbaliť" : "rozbaliť")
                    )}
                    zvjsVariant="secondaryAction"
                    startIcon={
                      expandedRow === index ? (
                        <ExpandLessIcon sx={{ fontSize: "30px !important" }} />
                      ) : (
                        <ExpandMoreIcon sx={{ fontSize: "30px !important" }} />
                      )
                    }
                    onClick={() =>
                      setExpandedRow(expandedRow === index ? undefined : index)
                    }
                  />
                )}
              </TableCell>
            )}
          </TableRow>,
        ];

        if (row.rowDetail !== undefined) {
          toReturn.push(
            <TableRow
              sx={
                // when row detail is expanded
                // and in all cases except for the last row of the table or situation when Load more button in infinity scroll mode is displayed
                // => add row (row detail) divider
                index === expandedRow &&
                (index < rowData.length - 1 ||
                  tableDataInfinityScroll.pageNumber <
                    tableDataInfinityScroll.totalPageNumber)
                  ? {
                      position: "relative",
                      backgroundColor: ZVJS_COLORS.WHITE,
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        /* 100% width - margins (16px left, 16px right) */
                        width: "calc(100% - 32px)",
                        bottom: 0,
                        left: 0,
                        marginLeft: 2,
                        marginRight: 2,
                        borderBottom: `1px solid ${ZVJS_COLORS.GREY_2}`,
                      },
                    }
                  : { backgroundColor: ZVJS_COLORS.WHITE }
              }
              key={`${index}_detail`}
            >
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0, border: "none" }}
                colSpan={6}
              >
                <Collapse
                  in={index === expandedRow}
                  timeout="auto"
                  unmountOnExit
                >
                  <Table size={"small"}>
                    <TableHead>
                      <TableRow>
                        {row.rowDetail.header.map((cell, index) => (
                          <TableCell
                            key={"head" + index}
                            align={cell.align}
                            sx={{
                              ...getTableHeaderCellStyle(
                                index,
                                ZVJS_COLORS.WHITE
                              ),
                              paddingBottom: 0,
                            }}
                          >
                            {typeof cell.value === typeof React.Component ? (
                              cell.value
                            ) : (
                              <Typography
                                fontSize={fontSizes ? fontSizes.header : 20}
                                fontWeight={700}
                              >
                                {cell.value as string | number}
                              </Typography>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.rowDetail.body.map((detailRow, index) => (
                        <TableRow
                          key={index}
                          sx={
                            // except for the last row of the detail table => add row divider
                            row.rowDetail?.body.length &&
                            index < row.rowDetail.body.length - 1
                              ? {
                                  position: "relative",
                                  "&::after": {
                                    content: '""',
                                    position: "absolute",
                                    /* 100% width - margins (16px left, 16px right) */
                                    width: "calc(100% - 32px)",
                                    bottom: 0,
                                    left: 0,
                                    marginLeft: 2,
                                    marginRight: 2,
                                    borderBottom: `1px solid ${ZVJS_COLORS.GREY_2}`,
                                  },
                                }
                              : {}
                          }
                        >
                          {detailRow.row.map((detailCell, cellIndex) => (
                            <TableCell
                              key={cellIndex}
                              align={detailCell.align}
                              sx={{
                                ...getTableBodyCellStyle(
                                  index,
                                  cellIndex,
                                  detailRow.row.length,
                                  detailCell.width
                                ),
                                ...{ paddingTop: 1, paddingBottom: 1 },
                              }}
                            >
                              {typeof detailCell.value !== "number" &&
                              typeof detailCell.value !== "string" ? (
                                <Box
                                  sx={{
                                    display: detailCell.sx?.display,
                                    justifyContent:
                                      detailCell.sx?.justifyContent,
                                  }}
                                >
                                  {detailCell.value}
                                </Box>
                              ) : (
                                <Typography
                                  variant="body1"
                                  style={{
                                    fontWeight:
                                      detailCell.bold === undefined
                                        ? 400
                                        : detailCell.bold
                                        ? 700
                                        : 400,
                                    fontSize: fontSizes ? fontSizes?.body : 19,
                                  }}
                                >
                                  {detailCell.value as string | number}
                                </Typography>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Collapse>
              </TableCell>
            </TableRow>
          );
        }

        return toReturn;
      });
    };

    if (
      tableDataNormal.body.length === 0 ||
      tableDataInfinityScroll.body.length === 0
    ) {
      return (
        <TableRow>
          <TableCell
            colSpan={tableDataNormal.header.length}
            align={"center"}
            sx={{
              backgroundColor: ZVJS_COLORS.WHITE,
              borderRadius: "4px",
            }}
          >
            <Typography variant="h3">
              {capitalize(tui("nenašli sme žiadne dáta"))}
            </Typography>
          </TableCell>
        </TableRow>
      );
    }

    if (variant === ZvjsTableVariant.NORMAL) {
      return renderRows(tableDataNormal.body);
    } else {
      if (
        tableDataInfinityScroll.pageNumber <
        tableDataInfinityScroll.totalPageNumber
      ) {
        return (
          <>
            {renderRows(tableDataInfinityScroll.body)}
            {renderLoadButton()}
          </>
        );
      } else {
        return renderRows(tableDataInfinityScroll.body);
      }
    }
  };
  const renderLoadButton = () => {
    const infinitRest = rest as ZvjsTableInfiniteScrollProps;
    const tableDataInfinit = tableData as TableDataInfinityScroll;
    if (variant !== ZvjsTableVariant.INFINITE_SCROLL) {
      return null;
    }
    if (tableDataInfinit.pageNumber >= tableDataInfinit.totalPageNumber) {
      return null;
    }
    return (
      infinitRest.onLoadMore && (
        <TableRow>
          <TableCell
            colSpan={tableDataInfinit.header.length}
            align={"center"}
            sx={{
              backgroundColor: ZVJS_COLORS.WHITE,
              borderBottomLeftRadius: "4px",
              borderBottomRightRadius: "4px",
            }}
          >
            <ZvjsButton
              zvjsVariant={"secondaryAction"}
              text={capitalize(tui("načítať ďalšie"))}
              disabled={isSubmitting(navigation)}
              isLoading={isSubmitting(navigation)}
              onClick={() => {
                infinitRest.onLoadMore(
                  tableDataInfinit.pageNumber + 1,
                  infinitRest.pageSize
                );
              }}
              sx={{ minWidth: "10rem" }}
            />
          </TableCell>
        </TableRow>
      )
    );
  };

  const getTableHeaderCellStyle = (
    index: number,
    backgroundColor: Property.BackgroundColor | undefined = ZVJS_COLORS.GREY_5
  ) => {
    const columnsCount = hasDetail
      ? tableData.header.length + 1
      : tableData.header.length;

    let toReturn: SxProps<Theme> = {
      padding: 1,
      border: 0,
      backgroundColor: backgroundColor,
    };

    if (index === 0) {
      toReturn = {
        ...toReturn,
        borderTopLeftRadius: "4px",
        borderBottomLeftRadius: "4px",
        paddingLeft: 3,
      };
    }

    if (index === columnsCount - 1) {
      toReturn = {
        ...toReturn,
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        paddingRight: 3,
      };
    }

    return toReturn;
  };

  return (
    <Stack spacing={1} mt={2} mb={2}>
      <Stack direction={"row"} spacing={1} alignItems={"top"}>
        {tableData.label && (
          <Typography
            variant="h3"
            pt={1}
            pl={2.5}
            fontSize={fontSizes ? fontSizes.tableLabel : 32}
          >
            {tableData.label}
          </Typography>
        )}
        {hintText ? (
          <ZvjsHintModalFragment
            title={hintTitle !== undefined ? hintTitle : tui("nápoveda")}
            text={hintText}
            color={ZVJS_COLORS.BLUE_2}
          />
        ) : null}
      </Stack>
      <TableContainer
        sx={{
          minWidth: 700,
          maxHeight: height,
        }}
      >
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <TableRow>
              {tableData.header.map((cell, index) => (
                <TableCell
                  key={"head" + index}
                  align={cell.align}
                  sx={getTableHeaderCellStyle(index)}
                >
                  {typeof cell.value === typeof React.Component ? (
                    cell.value
                  ) : (
                    <Typography
                      fontSize={fontSizes ? fontSizes.header : 20}
                      fontWeight={700}
                    >
                      {cell.value as string | number}
                    </Typography>
                  )}
                </TableCell>
              ))}
              {hasDetail && (
                <TableCell
                  sx={getTableHeaderCellStyle(tableData.header.length)}
                >
                  <Typography
                    fontSize={fontSizes ? fontSizes.header : 20}
                    fontWeight={700}
                  >
                    {capitalize(tui("detail"))}
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody sx={{ height: "8px" }} />
          <TableBody>{renderRows()}</TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
export default ZvjsTable;
