import React from "react";
import { Stack } from "@mui/system";
import { Typography } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { capitalize } from "@mui/material/utils";
import ClearIcon from "@mui/icons-material/Clear";
import { useUITranslation } from "../../../../../../store/context/translation-context";
import { useModal } from "../../../../../../store/context/modal-context";
import { ZvjsButton, ZvjsTextField } from "../../../../../components";
import { isSubmitting } from "../../../../../../utils/helpers";
import { Params, useNavigation } from "react-router-dom";
import { ActionResultTypes } from "../../../../../../router/ActionResult";
import { API_Clients } from "../../../../../../store/context/dataApi/Data";
import {
  BlizkaOsoba,
  DetailZakladneUdajeKlientaData,
} from "../../../../../specs/swaggerTypes";
import {
  refetchRequest,
  RequestActionResult,
  RequestActionsType,
} from "../../../../Request";

interface EditPersonSubmitData {
  formData: PersonFormData;
  closePersonData: BlizkaOsoba;
}

export async function SC_KIO_050202_editClosePerson({
  params,
  data,
}: {
  params: Params;
  data: unknown;
}): Promise<RequestActionResult> {
  const { EOO_Post } = await API_Clients();

  const dataToEdit = data as EditPersonSubmitData;

  const toSubmit = dataToEdit.closePersonData.fyzickaOsoba;
  if (toSubmit) {
    // update existing physical person
    toSubmit.cisloObcianskehoPreukazu =
      dataToEdit.formData.identificationDocumentNumber;

    const resultUpdatedPhysicalPerson = await EOO_Post(
      "/api/FyzickaOsoba/UpdateFyzickaOsoba",
      { body: toSubmit }
    );

    if (
      resultUpdatedPhysicalPerson.error ||
      !resultUpdatedPhysicalPerson.response.ok
    ) {
      if (
        resultUpdatedPhysicalPerson.response.status === 503 &&
        resultUpdatedPhysicalPerson.response.headers.get("X-Zvjs-Offline") ===
          "offline"
      ) {
        return {
          type: ActionResultTypes.SUCCESS,
          action: RequestActionsType.SC_KIO_050202_EDIT_CLOSE_PERSON,
          // TODO add translation
          message:
            "Požiadavka na zmenu údajov bola zaevidovaná. V systéme sa prejaví po synchronizácií tabletu.",
        };
      }

      return {
        type: ActionResultTypes.ERROR,
        // TODO add translation
        message: "Osobu sa nepodarilo upraviť",
        action: RequestActionsType.SC_KIO_050202_EDIT_CLOSE_PERSON,
      };
    }
  }

  return {
    type: ActionResultTypes.SUCCESS,
    action: RequestActionsType.SC_KIO_050202_EDIT_CLOSE_PERSON,
  };
}

const generateSchema = (
  tuiz: (key: string) => string,
  closePersonData: BlizkaOsoba
) =>
  yup
    .object({
      firstname: yup.string().default(closePersonData.fyzickaOsoba?.meno ?? ""), // todo add translation
      surname: yup
        .string()
        .default(closePersonData.fyzickaOsoba?.priezvisko ?? ""), // todo add translation
      identificationDocumentNumber: yup
        .string()
        .required(capitalize(tuiz("Napíšte číslo identifikačného dokladu")))
        .default(closePersonData.fyzickaOsoba?.cisloObcianskehoPreukazu ?? ""), // todo add translation
    })
    .required();
type PersonFormData = yup.InferType<ReturnType<typeof generateSchema>>;

interface SC_KIO_050202_EditClosePersonFormProps {
  closePersonData: BlizkaOsoba;
  onSubmit: (toSubmit: unknown) => void;
  basicClientData: DetailZakladneUdajeKlientaData;
}

const SC_KIO_050202_EditClosePersonForm: React.FC<
  SC_KIO_050202_EditClosePersonFormProps
> = ({ closePersonData, onSubmit, basicClientData }) => {
  const { closeModal } = useModal();
  const { tuiz } = useUITranslation();
  const navigation = useNavigation();

  const schema = generateSchema(tuiz, closePersonData);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: schema.cast(undefined),
    resolver: yupResolver(schema),
  });

  const handleOnSubmit = (formData: PersonFormData) => {
    const toSubmit: EditPersonSubmitData = {
      formData: formData,
      closePersonData: closePersonData,
    };
    onSubmit(toSubmit);
  };

  return (
    <Stack width={"70vw"} maxHeight={"80vh"} overflow={"auto"}>
      {/* todo add translation */}
      <Typography variant={"h2"} mb={2}>
        {/*TODO add translation*/}
        {capitalize(tuiz("Zmena údajov"))}
      </Typography>{" "}
      <form
        style={{ width: "20 rem" }}
        onSubmit={handleSubmit(handleOnSubmit)}
        // TODO think about how to make live validation work - low priority
        // onBlur={handleSubmit(dummyFunction)}
      >
        <Stack spacing={1}>
          {/*/!* todo add translation *!/*/}
          <ZvjsTextField
            label={capitalize(tuiz("Meno"))}
            register={{ ...register("firstname") }}
            error={errors.firstname !== undefined}
            validationErrorMessage={errors.firstname?.message}
            disabled
          />
          <ZvjsTextField
            label={capitalize(tuiz("Priezvisko"))}
            register={{ ...register("surname") }}
            error={errors.surname !== undefined}
            validationErrorMessage={errors.surname?.message}
            disabled
          />
          <ZvjsTextField
            label={capitalize(
              tuiz("ziadost.ziadostOvydanieCeniny.labelCisloDokladu")
            )}
            register={{ ...register("identificationDocumentNumber") }}
            error={errors.identificationDocumentNumber !== undefined}
            validationErrorMessage={
              errors.identificationDocumentNumber?.message
            }
          />
        </Stack>
        <Stack direction={"row"} spacing={2} mt={3} justifyContent={"center"}>
          <ZvjsButton
            type={"submit"}
            zvjsVariant={"secondaryAction"}
            disabled={
              Object.keys(errors).length > 0 || isSubmitting(navigation)
            }
            text={capitalize(tuiz("Upraviť osobu"))}
            endIcon={<ChevronRight style={{ height: 45, width: 45 }} />}
          />
          <ZvjsButton
            zvjsVariant={"cancelAction"}
            disabled={isSubmitting(navigation)}
            text={capitalize(tuiz("Zrušiť"))}
            startIcon={<ClearIcon style={{ height: 45, width: 45 }} />}
            onClick={closeModal}
          />
        </Stack>
      </form>
    </Stack>
  );
};
export default SC_KIO_050202_EditClosePersonForm;
