import {
  createSearchParams,
  defer,
  Params,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ZvjsSearchBar, ZvjsTable } from "../../../../common";
import { ZvjsSearchBarVariant } from "../../../../common/components/ZvjsSearchBar";
import { Stack } from "@mui/material";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import { API_Clients } from "../../../../store/context/dataApi/Data";
import { withLoader } from "../../../../hoc/withLoader";
import { LoaderError } from "../../../../router/LoaderError";
import moment from "moment";

const loadData = async (params: Params, request: Request) => {
  const searchParams = new URL(request.url).searchParams;
  const datumOd = searchParams.get("datumOd")
    ? decodeURIComponent(searchParams.get("datumOd") || "")
    : undefined;
  const datumDo = searchParams.get("datumDo")
    ? decodeURIComponent(searchParams.get("datumDo") || "")
    : undefined;

  console.debug(
    `PrijataKorespondenciaTab loadData datumOd[${datumOd}] datumDo[${datumDo}]`
  );

  const { SHARE_INT_Post } = await API_Clients();

  const prijataKorespondenciaList = await SHARE_INT_Post(
    "/api/interfaces/Epvvvt/ListZoznamPrijatejKorenspondencie",
    {
      body: {
        klientObjectId: {
          eq: localStorage.getItem("klientObjectId") ?? "faultyId",
        },
      },
    }
  );

  if (prijataKorespondenciaList.error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [prijatá korešpondencia]"
    );
  }

  let filteredData = prijataKorespondenciaList.data;
  if (datumOd) {
    filteredData = filteredData.filter(
      (item) =>
        item?.datumPrijatia &&
        datumOd &&
        new Date(item?.datumPrijatia) >= new Date(datumOd)
    );
  }
  if (datumDo) {
    filteredData = filteredData.filter(
      (item) =>
        item.datumPrijatia &&
        datumDo &&
        new Date(item.datumPrijatia) <= new Date(datumDo)
    );
  }

  const { EOO_Get, EOO_Post } = await API_Clients();

  const zoznamPrijatejKorespondencie = await Promise.all(
    filteredData.map(async (item) => {
      try {
        let odosielatelNazov = "";

        if (!!item.odosielatelFyzickaOsobaId) {
          const prijimatelDetail = await EOO_Get(
            "/api/FyzickaOsoba/DetailData",
            {
              params: {
                query: {
                  request: item.odosielatelFyzickaOsobaId,
                },
              },
            }
          );

          const fyzickaOsobaData = prijimatelDetail?.data?.data;
          if (fyzickaOsobaData) {
            odosielatelNazov =
              `${fyzickaOsobaData.meno || ""} ${fyzickaOsobaData.priezvisko || ""}`.trim();
          }
        } else if (!!item.odosielatelPravnickaOsobaId) {
          const pravnickeOsoby = await EOO_Post("/api/PravnickaOsoba/List", {
            body: {
              // filters: [
              //   {
              //   TODO:  id: item.prijmatelPravnickaOsoba!,
              //   },
              // ],
            },
          });

          const matchingPravnickaOsoba = pravnickeOsoby?.data?.records?.find(
            (record) => record.id === item.odosielatelPravnickaOsobaId
          );

          if (matchingPravnickaOsoba) {
            odosielatelNazov = matchingPravnickaOsoba.nazov || "";
          }
        }

        return {
          ...item,
          odosielatelNazov: odosielatelNazov,
        };
      } catch (error) {
        console.error("Error processing item:", item, error);
        return {
          ...item,
          odosielatelNazov: "",
        };
      }
    })
  );

  return {
    zoznamPrijatejKorespondencie,
  };
};

export const PrijataKorespondenciaTabLoader = async ({
  params,
  request,
}: {
  params: Params;
  request: Request;
}) => {
  return defer({
    data: loadData(params, request),
  });
};

export interface PrijataKorespondenciaTabProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const PrijataKorespondenciaTab = (props: PrijataKorespondenciaTabProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const onSearch = (dateFrom: Date, dateTo: Date) => {
    console.debug(
      `PrijataKorespondencia onSearch dateFrom[${dateFrom}] dateTo[${dateTo}]`
    );
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams({
          ...(dateFrom.isValid() && {
            datumOd: encodeURIComponent(moment(dateFrom).toISOString()),
          }),
          ...(dateTo.isValid() && {
            datumDo: encodeURIComponent(moment(dateTo).toISOString()),
          }),
        }).toString()}`,
      },
      { replace: true }
    );
  };

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsSearchBar
        title={tui("vyhľadať korešpondenciu")}
        type={ZvjsSearchBarVariant.VARIANT_A_DpDp}
        onSubmit={onSearch}
      />
      <ZvjsTable
        data={{
          header: [
            {
              value: capitalize(tui("dátum prijatia")),
              align: "left",
              width: "26%",
            },
            {
              value: capitalize(tui("typ korešpondencie")),
              align: "left",
              width: "37%",
            },
            {
              value: capitalize(tui("odosielateľ")),
              align: "left",
              width: "37%",
            },
          ],
          body:
            props.data.zoznamPrijatejKorespondencie
              .sort(
                (a, b) =>
                  new Date(b?.datumPrijatia ?? new Date()).valueOf() -
                  new Date(a?.datumPrijatia ?? new Date()).valueOf()
              )
              .map((prijataKorespondencia) => {
                const rd: RowData = {
                  row: [
                    {
                      value: new Date(
                        prijataKorespondencia?.datumPrijatia ?? new Date()
                      ).zvjsToString(),
                      align: "left",
                      width: "26%",
                    },
                    {
                      value:
                        prijataKorespondencia?.typKorespondencieNazov ?? "",
                      align: "left",
                      width: "37%",
                    },
                    {
                      value: prijataKorespondencia?.odosielatelNazov,
                      align: "left",
                      width: "37%",
                    },
                  ],
                };
                return rd;
              }) ?? [],
        }}
        height={180}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Stack>
  );
};

export default withLoader(PrijataKorespondenciaTab);
