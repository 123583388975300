/**
 * Store selected counters ids/codes here which are being used in code logic
 */
export const CisCCMStav_Evidovane_ID = 3;
export const CisCCMStav_Evidovane_Kod = "Evid";

export const CisDovodNaOdoslanieKorespondencie_List_Kod = "LIST";

export const CisDovodPodaniaZiadosti_Rodinne_Dovody_Kod = "ROD";
export const CisDovodPodaniaZiadosti_Ine_Dovody_Kod = "INED";

export const CisDovodPodaniaZiadostiElektrospotrebic_Novy_Elektricky_Spotrebic_Kod =
  "POUZ";
export const CisDovodPodaniaZiadostiElektrospotrebic_Revizia_Kod = "REVIZ";

export const CisDovodZiadostiSprchovanie_Pracovne_Zaradenie_Kod = "ZARAD";
export const CisDovodZiadostiSprchovanie_Zdravotne_Dovody_Kod = "ZDRAVD";
export const CisDovodZiadostiSprchovanie_Ine_Dovody_Kod = "INED";

export const CisDruhAdresy_Trvaly_Pobyt_Kod = "100001";
export const CisDruhAdresy_Prechodny_Pobyt_Kod = "100002";

export const CisDruhElektrospotrebica_Iny_Elektricky_Spotrebic_Kod = "INY";

export const CisFormaRealizacieNavstevy_Fyzicka_Navsteva_Kod = "FN";
export const CisFormaRealizacieNavstevy_Videonavsteva_Kod = "VN";
export const CisFormaRealizacieNavstevy_Videonavsteva_S_Maloletymi_Detmi_Kod =
  "VND";

export const CisNarodnost_Slovenska_ID = 3;

export const CisOblastZdravotnychProblemov_Bolest_Kod = "1";

export const CisPohlavie_NEZISTENE_ID = 1;
export const CisPohlavie_MUZ_ID = 2;
export const CisPohlavie_ZENA_ID = 3;

export const CisStavZiadosti_Poziadavka_O_Stanovisko_ID = 7;
export const CisStavZiadosti_Schvalena_ID = 10;
export const CisStavZiadosti_Spracovane_Stanovisko_ID = 18;
export const CisStavZiadosti_Zaevidovana_ID = 27;
export const CisStavZiadosti_Zamietnuta_ID = 30;

export const CisTrvanieNavstevy_1_hodina_Kod = "1h";
export const CisTrvanieNavstevy_2_hodiny_Kod = "2h";

export const CisTypBlizkejOsoby_ManzelManzelka_ID = 1;
export const CisTypBlizkejOsoby_DruhDruzka_ID = 2;
export const CisTypBlizkejOsoby_Rodic_ID = 3;
export const CisTypBlizkejOsoby_SvokorSvokra_ID = 4;
export const CisTypBlizkejOsoby_Dieta_ID = 5;
export const CisTypBlizkejOsoby_ZatNevesta_ID = 6;
export const CisTypBlizkejOsoby_StaryRodic_ID = 7;
export const CisTypBlizkejOsoby_VnukVnucka_ID = 8;
export const CisTypBlizkejOsoby_Surodenec_ID = 9;
export const CisTypBlizkejOsoby_SvagorSvagrina_ID = 10;
export const CisTypBlizkejOsoby_PestunDietata_ID = 11;
export const CisTypBlizkejOsoby_KamaratKamaratka_ID = 12;
export const CisTypBlizkejOsoby_InyVztah_ID = 13;

export const CisTypOdoslaniaProstriedkov_Blizkej_Osobe_Kod = "BLIZOS";
export const CisTypOdoslaniaProstriedkov_Organu_Verejnej_Moci_Kod = "OVM";
export const CisTypOdoslaniaProstriedkov_Inej_Osobe_Alebo_Sposobena_Ujma_Kod =
  "INAOS";
export const CisTypOdoslaniaProstriedkov_Sudu_Alebo_Inemu_Spravnemu_Organu_Kod =
  "SUD";
export const CisTypOdoslaniaProstriedkov_Urcenej_Osobe_Kod = "URCOS";

export const CisTypZiadosti_SC_KIO_050101_ID = 9;
export const CisTypZiadosti_SC_KIO_050204_ID = 27;
export const CisTypZiadosti_SC_KIO_050301_ID = 3;
export const CisTypZiadosti_SC_KIO_050501_ID = 19;
export const CisTypZiadosti_SC_KIO_050802_ID = 31;
export const CisTypZiadosti_SC_KIO_051001_ID = 16;
export const CisTypZiadosti_SC_KIO_051403_ID = 8;

export const CisUstavZvjs_GR_Kod = "75";

export const CisZdrojZiadosti_Tablet_Kod = "1";
export const CisZdrojZiadosti_Kiosk_Kod = "2";

export const EooCiselnikTypKontaktu_Skype_ID = 4;

export const CisDruhBalik_BalikVeciOsobnejPotreby_ID = 1;
export const CisDruhBalik_IneVeciOsobnejPotreby_ID = 2;
export const CisDruhBalik_BalikOdevObuv_ID = 3;
export const CisDruhBalik_Tricko_ID = 4;
export const CisDruhBalik_Topanky_ID = 5;
export const CisDruhBalik_Spodky_ID = 6;
export const CisDruhBalik_ZimnaBundaCiapka_ID = 7;

