import {
  State,
  UserCalcData,
  ZvjsCustomQuestion,
} from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { findItem } from "../../../../../../redux/slice";
import { LoaderError } from '../../../../../../../../router/LoaderError';
import { API_Clients } from '../../../../../../../../store/context/dataApi/Data';

const AVAILABLE_ACTIVITIES = "ACTIVITIES";
export const ActivitiesGridConstants = {
  AVAILABLE_ACTIVITIES,
};

/**
 * OnInit
 */

export interface ActivityType {
  id: number;
  name: string;
}

export const SC_KIO_050601_ActivitiesGrid_onInit = async (
  calcData: UserCalcData
): Promise<void> => {
  const isWorkingDay = (date: Date): boolean => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const addWorkingDays = (date: Date, days: number): Date => {
    const result = new Date(date);
    while (days > 0) {
      result.setDate(result.getDate() + 1);
      if (isWorkingDay(result)) {
        days--;
      }
    }
    return result;
  };

  const { SHARE_INT_Post } = await API_Clients();

  const data = await Promise.all([
    SHARE_INT_Post("/api/interfaces/Epvvvt/ListOraVolnocasovaAktivita", {
      body: {},
    }),
    SHARE_INT_Post("/api/interfaces/Epvvvt/ListZoznamSkupinovychAktivit", {
      body: {},
    }),
  ]);

  if (data[0].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [zoznam volnočasových aktivít]");
  }

  if (data[1].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [zoznam volnočasových aktivít]");
  }

  const today = new Date();
  const listOfActivities0 = (data[0].data ?? [])
    .filter((program: any) => {
      const eventDate = new Date(program.datumKonania);
      const earliestSubmissionDate = addWorkingDays(today, 4);
      return eventDate >= earliestSubmissionDate;
    })
    .map((program: any) => ({
      id: program.oraVolnocasovaAktivitaId,
      name: program.druhVolnocasovejAktivityNazov,
    }));

  const listOfActivities1 = (data[1].data ?? [])
    .filter((program: any) => {
      const eventDate = new Date(program.datumKonania);
      const earliestSubmissionDate = addWorkingDays(today, 4);
      return eventDate >= earliestSubmissionDate;
    })
    .map((program: any) => ({
      id: program.oraVychovnoOsvetovaAktivitaId,
      name: program.druhVolnocasovejAktivityNazov,
    }));

  /*const listOfActivities = [
    {
      id: 1,
      name: "Večer kultúry a divadla",
    },
    {
      id: 2,
      name: "Turnaj vo stolnom tenise",
    },
    {
      id: 3,
      name: "Hudobný festival väzenských talentov",
    },
    {
      id: 4,
      name: "Literárna čítanka a diskusia",
    },
    {
      id: 5,
      name: "Športový deň so súťažami",
    },
    {
      id: 6,
      name: "Výtvarná výstava a dielne",
    },
  ];*/

  const mergedActivities = [...listOfActivities0, ...listOfActivities1];

  calcData[ActivitiesGridConstants.AVAILABLE_ACTIVITIES] = mergedActivities;
};

/**
 * Selectors
 */

const getActivitiesGridDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    return {
      id: question.id,
      title: question.title,
      availableActivities: state.userCalcData[
        ActivitiesGridConstants.AVAILABLE_ACTIVITIES
      ] as ActivityType[],
    };
  });

export const allSelectors = {
  getActivitiesGridDisplayData,
};
