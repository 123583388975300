import { UserCalcData, ZvjsAnswers } from "../../../../redux/model";
import { LoaderError } from "../../../../../../router/LoaderError";
import { API_Clients } from "../../../../../../store/context/dataApi/Data";
import { BlizkaOsoba } from "../../../../../specs/swaggerTypes";
import {
  isClientAccused,
  isClientFemale,
  isClientSentenced,
} from "../../../../../../utils/dataFetchers";

const getAvailableAccountAmount = async () => {
  const { FEOO_Post } = await API_Clients();

  const response = await FEOO_Post(
    "/api/integracie/Kiosk/ListStavKontaAKreditu",
    {
      body: {
        idKlienta: localStorage.getItem("klientObjectId"),
      },
    }
  );

  if (
    response.error ||
    (!response.data.at(0)?.disponibilnyZostatok &&
      response.data.at(0)?.disponibilnyZostatok !== 0)
  ) {
    console.error(
      `Failed to load ListStavKontaAKreditu from FEOO for client ${localStorage.getItem(
        "klientObjectId"
      )}`
    );
    throw new LoaderError();
  }

  return response.data.at(0)?.disponibilnyZostatok;
};

const getAvailableAmountToShop = async () => {
  const { FEOO_Post } = await API_Clients();

  const response = await FEOO_Post(
    "/api/integracie/Kiosk/ListStavKontaAKreditu",
    {
      body: {
        idKlienta: localStorage.getItem("klientObjectId"),
      },
    }
  );

  if (
    response.error ||
    (!response.data.at(0)?.moznaSumaNaNakup &&
      response.data.at(0)?.moznaSumaNaNakup !== 0)
  ) {
    console.error(
      `Failed to load ListStavKontaAKreditu from FEOO for client ${localStorage.getItem(
        "klientObjectId"
      )}`
    );
    throw new LoaderError();
  }

  return response.data.at(0)?.moznaSumaNaNakup;
};

const getCreditAmount = async () => {
  const { FEOO_Post } = await API_Clients();

  const response = await FEOO_Post(
    "/api/integracie/Kiosk/ListStavKontaAKreditu",
    {
      body: {
        idKlienta: localStorage.getItem("klientObjectId"),
      },
    }
  );

  if (
    response.error ||
    (!response.data.at(0)?.aktualnyKredit &&
      response.data.at(0)?.aktualnyKredit !== 0)
  ) {
    console.error(
      `Failed to load ListStavKontaAKreditu from FEOO for client ${localStorage.getItem(
        "klientObjectId"
      )}`
    );
    throw new LoaderError();
  }

  return response.data.at(0)?.aktualnyKredit;
};

const getPossibleWithdrawalAmount = async () => {
  const { FEOO_Post } = await API_Clients();

  const response = await FEOO_Post(
    "/api/integracie/Kiosk/ListStavKontaAKreditu",
    {
      body: {
        idKlienta: localStorage.getItem("klientObjectId"),
      },
    }
  );

  if (
    response.error ||
    (!response.data.at(0)?.moznaSumaNaCerpanie &&
      response.data.at(0)?.moznaSumaNaCerpanie !== 0)
  ) {
    console.error(
      `Failed to load ListStavKontaAKreditu from FEOO for client ${localStorage.getItem(
        "klientObjectId"
      )}`
    );
    throw new LoaderError();
  }

  return response.data.at(0)?.moznaSumaNaCerpanie;
};

const getRegisteredAlimonyAndClaimsAmount = async () => {
  const { FEOO_Get } = await API_Clients();

  const response = await FEOO_Get("/api/Klient/Get", {
    params: {
      query: { id: localStorage.getItem("klientObjectId") ?? undefined },
    },
  });

  if (
    response.error ||
    !response.response.ok ||
    (!response.data.data?.rozsireneUdajeKlienta?.zostatkovaSumaPohladavokCP &&
      response.data.data?.rozsireneUdajeKlienta?.zostatkovaSumaPohladavokCP !==
        0) ||
    (!response.data.data?.rozsireneUdajeKlienta
      ?.zostatkovaSumaPohladavokGR_Zvjs &&
      response.data.data?.rozsireneUdajeKlienta
        ?.zostatkovaSumaPohladavokGR_Zvjs !== 0) ||
    (!response.data.data?.rozsireneUdajeKlienta?.zostatkovaSumaPohladavokNP &&
      response.data.data?.rozsireneUdajeKlienta?.zostatkovaSumaPohladavokNP !==
        0) ||
    (!response.data.data?.rozsireneUdajeKlienta?.zostatkovaSumaVyzivneho &&
      response.data.data?.rozsireneUdajeKlienta?.zostatkovaSumaVyzivneho !== 0)
  ) {
    throw new LoaderError();
  }

  return (
    response.data.data?.rozsireneUdajeKlienta?.zostatkovaSumaPohladavokCP +
    response.data.data?.rozsireneUdajeKlienta?.zostatkovaSumaPohladavokGR_Zvjs +
    response.data.data?.rozsireneUdajeKlienta?.zostatkovaSumaPohladavokNP +
    response.data.data?.rozsireneUdajeKlienta?.zostatkovaSumaVyzivneho
  );
};

const getRefundAmount = () => {
  // TODO replace with actual logic
  return 100;
};

const getTotalUnpaidAlimonysAndClaimsAmount = () => {
  return 200;
};

const getCurrentDietType = () => {
  // TODO replace with actual logic
  return "Štandardná strava";
};

export const getIsFemale = async () => {
  const isClientFemaleResponse = await isClientFemale();

  if (isClientFemaleResponse.error || !isClientFemaleResponse.response.ok) {
    throw new LoaderError();
  }

  return isClientFemaleResponse.data?.isFemale === true;
};

const canAcceptPackageWithPersonalItem = () => {
  // TODO replace with actual logic
  return true;
};

const canAcceptPackageWithClothesAndFootwear = () => {
  // TODO replace with actual logic
  return true;
};

const isUserAccused = async () => {
  const isClientAccusedResponse = await isClientAccused();
  if (isClientAccusedResponse.error || !isClientAccusedResponse.response.ok) {
    throw new LoaderError();
  }

  return isClientAccusedResponse.data?.isAccused === true;
};

const isUserSentenced = async () => {
  const isClientSentencedResponse = await isClientSentenced();
  if (isClientSentencedResponse.error || !isClientSentencedResponse.response.ok) {
    throw new LoaderError();
  }

  return isClientSentencedResponse.data?.isSentenced === true;
};

const isUserAccusedInStandardMode = async ({
  userCalcData,
}: {
  userCalcData: UserCalcData;
}) => {
  // check if the is user accused value was already calculated, if not calculate it
  if (userCalcData["IS_USER_ACCUSED"] === true || (await isUserSentenced())) {
    // TODO replace with actual logic
    return true;
  }
};

const isUserAccusedInSoftenedMode = async ({
  userCalcData,
}: {
  userCalcData: UserCalcData;
}) => {
  // check if the is user accused value was already calculated, if not calculate it
  if (userCalcData["IS_USER_ACCUSED"] === true || (await isUserSentenced())) {
    // TODO replace with actual logic
    return false;
  }
};

const isUserForeigner = ({ userCalcData }: { userCalcData: UserCalcData }) => {
  // TODO replace with actual logic
  return true;
};

const shoppedLastCalendarMonth = ({
  userCalcData,
}: {
  userCalcData: UserCalcData;
}) => {
  // TODO replace with actual logic
  return false;
};

const minimumSubsistenceAmount = async ({
  userCalcData,
}: {
  userCalcData: UserCalcData;
}) => {
  const { FEOO_Get } = await API_Clients();

  const response = await FEOO_Get("/api/integracie/Kiosk/GetZivotneMinimum");

  if (response.error || !response.data.hodnota) {
    // TODO do not use in production - workaround for presentation purposes
    return "?";
    // throw new Error();
  }

  return response.data.hodnota;
};

const minimumSubsistenceAmount_3x = async ({
  userCalcData,
}: {
  userCalcData: UserCalcData;
}) => {
  const minimumSubsistenceAmountVal = await minimumSubsistenceAmount({
    userCalcData: userCalcData,
  });

  // TODO do not use in production - workaround for presentation purposes
  return typeof minimumSubsistenceAmountVal === "number"
    ? 3 * minimumSubsistenceAmountVal
    : "?";
};

export const pravnyZastupcaData =  async ({
  userCalcData,
}: {
  userCalcData: UserCalcData;
}) => {
  const { EOO_Post } = await API_Clients();
  const resultPravnyZastupcaData = await EOO_Post(
    "/api/Administracia/PravnyZastupcaData",
    {
      body: {
        filters: [{
          platny: true,
          aktivny: true,
          overeny: true
        }],
      },
    }
  );

  if (
    resultPravnyZastupcaData.error ||
    !resultPravnyZastupcaData.response.ok
  ) {
    throw new LoaderError();
  }

  const fyzickaOsobaId =  resultPravnyZastupcaData?.data?.records && resultPravnyZastupcaData?.data?.records[0]?.fyzickaOsobaId;

  return fyzickaOsobaId ?? "";
};

const basicClientData = async ({
  userCalcData,
}: {
  userCalcData: UserCalcData;
}) => {
  const { EOO_Get } = await API_Clients();
  const resultDetailZakladneUdajeKlientaData = await EOO_Get(
    "/api/Klient/DetailZakladneUdajeKlientaData",
    {
      params: {
        query: {
          Id: Number(localStorage.getItem("klientId")),
          UstavZvjsId: Number(localStorage.getItem("klientUstavId")),
        },
      },
    }
  );

  if (
    resultDetailZakladneUdajeKlientaData.error ||
    !resultDetailZakladneUdajeKlientaData.response.ok
  ) {
    throw new LoaderError();
  }

  return resultDetailZakladneUdajeKlientaData.data;
};

const getClosePeopleList = async ({
  userCalcData,
}: {
  userCalcData: UserCalcData;
}): Promise<BlizkaOsoba[]> => {
  const { EOO_Post } = await API_Clients();

  const response = await EOO_Post(
    "/api/DalsieOsobneUdaje/BlizkeOsobyKlientaZoznam",
    {
      body: {
        filters: [
          {
            id: Number(localStorage.getItem("klientId")),
            ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
          },
        ],
      },
    }
  );

  if (response.error || !response.response.ok) {
    throw new LoaderError();
  }

  return response.data.records || [];
};

export enum ReusableCalculationType {
  AVAILABLE_ACCOUNT_AMOUNT = "AVAILABLE_ACCOUNT_AMOUNT",
  AVAILABLE_AMOUNT_TO_SHOP = "AVAILABLE_AMOUNT_TO_SHOP",
  CREDIT_AMOUNT = "CREDIT_AMOUNT",
  POSSIBLE_WITHDRAWAL_AMOUNT = "POSSIBLE_WITHDRAWAL_AMOUNT",
  REGISTERED_ALIMONY_AND_CLAIMS_AMOUNT = "REGISTERED_ALIMONY_AND_CLAIMS_AMOUNT",
  REFUND_AMOUNT = "REFUND_AMOUNT",
  TOTAL_UNPAID_CHILD_SUPPORT_OBLIGATIONS_AND_CLAIMS_AMOUNT = "TOTAL_UNPAID_CHILD_SUPPORT_OBLIGATIONS_AND_CLAIMS_AMOUNT",
  CURRENT_DIET_TYPE = "CURRENT_DIET_TYPE",
  IS_FEMALE = "IS_FEMALE",
  CAN_ACCEPT_PACKAGE_WITH_PERSONAL_ITEMS = "CAN_ACCEPT_PACKAGE_WITH_PERSONAL_ITEMS",
  CAN_ACCEPT_PACKAGE_WITH_CLOTHES_AND_FOOTWEAR = "CAN_ACCEPT_PACKAGE_WITH_CLOTHES_AND_FOOTWEAR",
  IS_USER_ACCUSED = "IS_USER_ACCUSED",
  IS_USER_SENTENCED = "IS_USER_SENTENCED",
  IS_USER_ACCUSED_IN_STANDARD_MODE = "IS_USER_ACCUSED_IN_STANDARD_MODE",
  IS_USER_ACCUSED_IN_SOFTENED_MODE = "IS_USER_ACCUSED_IN_SOFTENED_MODE",
  IS_USER_FOREIGNER = "IS_USER_FOREIGNER",
  SHOPPED_LAST_CALENDAR_MONTH = "SHOPPED_LAST_CALENDAR_MONTH",
  MINIMUM_SUBSISTENCE_AMOUNT = "MINIMUM_SUBSISTENCE_AMOUNT",
  "3_x_MINIMUM_SUBSISTENCE_AMOUNT" = "3_x_MINIMUM_SUBSISTENCE_AMOUNT",
  BASIC_CLIENT_DATA = "BASIC_CLIENT_DATA",
  PRAVNY_ZASTUPCA_DATA = "PRAVNY_ZASTUPCA_DATA",
  CLOSE_PEOPLE_LIST = "CLOSE_PEOPLE_LIST",
}

const reusableCalculations: CalculationType = {
  // disponibilnyZostatok
  AVAILABLE_ACCOUNT_AMOUNT: getAvailableAccountAmount,
  AVAILABLE_AMOUNT_TO_SHOP: getAvailableAmountToShop,
  CREDIT_AMOUNT: getCreditAmount,
  POSSIBLE_WITHDRAWAL_AMOUNT: getPossibleWithdrawalAmount,
  REGISTERED_ALIMONY_AND_CLAIMS_AMOUNT: getRegisteredAlimonyAndClaimsAmount,
  REFUND_AMOUNT: getRefundAmount,
  TOTAL_UNPAID_CHILD_SUPPORT_OBLIGATIONS_AND_CLAIMS_AMOUNT:
    getTotalUnpaidAlimonysAndClaimsAmount,
  CURRENT_DIET_TYPE: getCurrentDietType,
  IS_FEMALE: getIsFemale,
  CAN_ACCEPT_PACKAGE_WITH_PERSONAL_ITEMS: canAcceptPackageWithPersonalItem,
  CAN_ACCEPT_PACKAGE_WITH_CLOTHES_AND_FOOTWEAR:
    canAcceptPackageWithClothesAndFootwear,
  IS_USER_ACCUSED: isUserAccused,
  IS_USER_SENTENCED: isUserSentenced,
  IS_USER_ACCUSED_IN_STANDARD_MODE: isUserAccusedInStandardMode,
  IS_USER_ACCUSED_IN_SOFTENED_MODE: isUserAccusedInSoftenedMode,
  IS_USER_FOREIGNER: isUserForeigner,
  SHOPPED_LAST_CALENDAR_MONTH: shoppedLastCalendarMonth,
  MINIMUM_SUBSISTENCE_AMOUNT: minimumSubsistenceAmount,
  "3_x_MINIMUM_SUBSISTENCE_AMOUNT": minimumSubsistenceAmount_3x,
  BASIC_CLIENT_DATA: basicClientData,
  PRAVNY_ZASTUPCA_DATA: pravnyZastupcaData,
  CLOSE_PEOPLE_LIST: getClosePeopleList,
};

type CalculationType = {
  [key in ReusableCalculationType]: ({
    userCalcData,
    answers,
  }: {
    userCalcData: UserCalcData;
    answers: ZvjsAnswers;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => number | boolean | string | any;
};

export default reusableCalculations;
