import React, { useContext, useEffect, useState } from "react";
import { capitalize } from "@mui/material/utils";
import { Stack, Typography } from "@mui/material";
import { LoginStepProps } from "../Login";
import { ZvjsButton, ZvjsTextField } from "../../common";
import { ArrowForwardIos } from "@mui/icons-material";
import { useUITranslation } from "../../store/context/translation-context";
import { API_Clients } from "../../store/context/dataApi/Data";
import AuthContext from "../../store/context/auth-context";
import {
  CisPohlavie_MUZ_ID,
  CisPohlavie_NEZISTENE_ID,
  CisPohlavie_ZENA_ID,
  CisTypBlizkejOsoby_DruhDruzka_ID,
  CisTypBlizkejOsoby_ManzelManzelka_ID,
  CisTypBlizkejOsoby_Rodic_ID,
} from "../../common/specs/countersValues";
import moment from "moment";
import { useSnackbar } from "../../store/context/snackbar-context";
import { useUserData } from "../../store/context/userData-context";

const LoginStep3: React.FC<LoginStepProps> = ({ setStep }) => {
  enum SecurityQuestionType {
    KrstneMenoManzela,
    KrstneMenoDruzky,
    KrstneMenoDruha,
    PriezviskoDruzky,
    KrstneMenoMatky,
    KrstneMenoOtca,
    AktualnyVek,
    RokNarodenia,
    MestoNarodenia,
    TitulPredMenom,
    NazovObceTrvaleBydlisko,
  }

  const { tui } = useUITranslation();

  const {
    cisloKarty,
    setKlientId,
    setKlientObjectId,
    setKlientUstavId,
    onLogin,
  } = useContext(AuthContext);

  const {
    ustavKodSet,
    ustavSkratkaSet,
    menoSet,
    priezviskoSet,
    feooKlientIdSet,
    fyzickaOsobaIdSet,
    zakladneCisloSet,
    oddielIdSet,
    vystupnyOddielSet,
    pohlavieSet,
    aktivnyTrestSet,
    aktivnaVazbaSet,
    narodnostIdSet,
  } = useUserData();

  const { openSnackbar } = useSnackbar();

  const [response, setResponse] = useState<string>("");

  const [selectedSecurityQuestionType, setSelectedSecurityQuestionType] =
    useState<SecurityQuestionType>(SecurityQuestionType.RokNarodenia);

  const [securityQuestionData, setSecurityQuestionData] =
    useState<Awaited<ReturnType<typeof getSecurityQuestionData>>>();

  const getSecurityQuestionData = async (cisloKarty: number) => {
    const { EOO_Get, EOO_Post, SIDKOO_Post } = await API_Clients();

    const kartaResult = await SIDKOO_Post("/api/IdKarta/ListIdKartaAktivna", {
      body: {
        filters: [
          {
            cisloKarty: Number(cisloKarty),
          },
        ],
      },
    });

    const karta = kartaResult.data?.records?.at(0);

    const eoo_klient_DetailZakladneUdajeKlientaData = await EOO_Get(
      "/api/Klient/DetailZakladneUdajeKlientaData",
      {
        params: {
          query: {
            Id: karta?.klientId,
            UstavZvjsId: karta?.ustavZvjsId,
          },
        },
      }
    );

    const eoo_DalsieOsobneUdaje_BlizkeOsobyKlientaZoznam = await EOO_Post(
      "/api/DalsieOsobneUdaje/BlizkeOsobyKlientaZoznam",
      {
        body: {
          filters: [
            {
              id: karta?.klientId,
              ustavZvjsId: karta?.ustavZvjsId,
            },
          ],
        },
      }
    );

    const eoo_FyzickaOsoba_ListAdresaOsoba = await EOO_Post(
      "/api/FyzickaOsoba/ListAdresaOsoba",
      {
        body: {
          filters: [
            eoo_klient_DetailZakladneUdajeKlientaData.data?.data
              ?.fyzickaOsobaId ?? NaN,
          ],
        },
      }
    );

    const datumNarodenia =
      eoo_klient_DetailZakladneUdajeKlientaData.data?.data?.datumNarodenia;

    // TODO: this should be "krstneMenoManzelky"
    //       because this is already a duplicate
    // const krstneMenoMatkyKod = "prihlasenie.krstneMenoMatky";

    // const krstneMenoManzelkyKod = "prihlasenie.krstneMenoManzelky";
    // const krstneMenoManzelky =
    //   eoo_DalsieOsobneUdaje_BlizkeOsobyKlientaZoznam.data?.records?.find(
    //     (blizkaOsoba) =>
    //       blizkaOsoba.typId === CisTypBlizkejOsoby_ManzelManzelka_ID &&
    //       blizkaOsoba.fyzickaOsoba?.pohlavieId === CisPohlavie_ZENA_ID
    //   )?.fyzickaOsoba?.meno;

    const krstneMenoManzela =
      eoo_DalsieOsobneUdaje_BlizkeOsobyKlientaZoznam.data?.records?.find(
        (blizkaOsoba) =>
          blizkaOsoba.typId === CisTypBlizkejOsoby_ManzelManzelka_ID &&
          blizkaOsoba.fyzickaOsoba?.pohlavieId === CisPohlavie_MUZ_ID
      )?.fyzickaOsoba?.meno;

    const krstneMenoDruzky =
      eoo_DalsieOsobneUdaje_BlizkeOsobyKlientaZoznam.data?.records?.find(
        (blizkaOsoba) =>
          blizkaOsoba.typId === CisTypBlizkejOsoby_DruhDruzka_ID &&
          blizkaOsoba.fyzickaOsoba?.pohlavieId === CisPohlavie_ZENA_ID
      )?.fyzickaOsoba?.meno;

    const krstneMenoDruha =
      eoo_DalsieOsobneUdaje_BlizkeOsobyKlientaZoznam.data?.records?.find(
        (blizkaOsoba) =>
          blizkaOsoba.typId === CisTypBlizkejOsoby_DruhDruzka_ID &&
          blizkaOsoba.fyzickaOsoba?.pohlavieId === CisPohlavie_MUZ_ID
      )?.fyzickaOsoba?.meno;

    const priezviskoDruzky =
      eoo_DalsieOsobneUdaje_BlizkeOsobyKlientaZoznam.data?.records?.find(
        (blizkaOsoba) =>
          blizkaOsoba.typId === CisTypBlizkejOsoby_DruhDruzka_ID &&
          blizkaOsoba.fyzickaOsoba?.pohlavieId === CisPohlavie_ZENA_ID
      )?.fyzickaOsoba?.priezvisko;

    const krstneMenoMatky =
      eoo_DalsieOsobneUdaje_BlizkeOsobyKlientaZoznam.data?.records?.find(
        (blizkaOsoba) =>
          blizkaOsoba.typId === CisTypBlizkejOsoby_Rodic_ID &&
          blizkaOsoba.fyzickaOsoba?.pohlavieId === CisPohlavie_ZENA_ID
      )?.fyzickaOsoba?.meno;

    const krstneMenoOtca =
      eoo_DalsieOsobneUdaje_BlizkeOsobyKlientaZoznam.data?.records?.find(
        (blizkaOsoba) =>
          blizkaOsoba.typId === CisTypBlizkejOsoby_Rodic_ID &&
          blizkaOsoba.fyzickaOsoba?.pohlavieId === CisPohlavie_MUZ_ID
      )?.fyzickaOsoba?.meno;

    const aktualnyVek = datumNarodenia
      ? moment().diff(moment(datumNarodenia), "years", false)
      : undefined;

    const rokNarodenia = datumNarodenia
      ? new Date(datumNarodenia).getFullYear()
      : undefined;

    const mestoNarodenia =
      eoo_klient_DetailZakladneUdajeKlientaData.data?.data?.miestoNarodenia;

    const titulPredMenom =
      eoo_klient_DetailZakladneUdajeKlientaData.data?.data
        ?.titulyOsobyPredMenomNazov;

    const nazovObceTrvaleBydlisko =
      eoo_FyzickaOsoba_ListAdresaOsoba.data?.records
        ?.sort(
          (a, b) =>
            new Date(b.platnostOd ?? new Date()).valueOf() -
            new Date(a.platnostOd ?? new Date()).valueOf()
        )
        .find(
          (adresa) => adresa.druhAdresyId === 1 /* Trvaly pobyt */
        )?.obecNazov;

    const result = {
      krstneMenoManzela: krstneMenoManzela,
      krstneMenoDruzky: krstneMenoDruzky,
      krstneMenoDruha: krstneMenoDruha,
      priezviskoDruzky: priezviskoDruzky,
      krstneMenoMatky: krstneMenoMatky,
      krstneMenoOtca: krstneMenoOtca,
      aktualnyVek: aktualnyVek,
      rokNarodenia: rokNarodenia,
      mestoNarodenia: mestoNarodenia,
      titulPredMenom: titulPredMenom,
      nazovObceTrvaleBydlisko: nazovObceTrvaleBydlisko,
    };

    return result;
  };

  const checkSecurityQuestionResponse = (
    response: string,
    securityQuestionType: SecurityQuestionType,
    secData: typeof securityQuestionData
  ): boolean => {
    console.debug(
      `checkSecurityQuestionResponse response[${response}] securityQuestionType[${
        SecurityQuestionType[securityQuestionType]
      }] securityQuestionAnswers[${JSON.stringify(securityQuestionData)}]`
    );
    switch (securityQuestionType) {
      case SecurityQuestionType.KrstneMenoManzela:
        return (
          response.removeDiacriticsAndBlanks().toLowerCase() ===
          secData?.krstneMenoManzela?.removeDiacriticsAndBlanks().toLowerCase()
        );
      case SecurityQuestionType.KrstneMenoDruzky:
        return (
          response.removeDiacriticsAndBlanks().toLowerCase() ===
          secData?.krstneMenoDruzky?.removeDiacriticsAndBlanks().toLowerCase()
        );
      case SecurityQuestionType.KrstneMenoDruha:
        return (
          response.removeDiacriticsAndBlanks().toLowerCase() ===
          secData?.krstneMenoDruha?.removeDiacriticsAndBlanks().toLowerCase()
        );
      case SecurityQuestionType.PriezviskoDruzky:
        return (
          response.removeDiacriticsAndBlanks().toLowerCase() ===
          secData?.priezviskoDruzky?.removeDiacriticsAndBlanks().toLowerCase()
        );
      case SecurityQuestionType.KrstneMenoMatky:
        return (
          response.removeDiacriticsAndBlanks().toLowerCase() ===
          secData?.krstneMenoMatky?.removeDiacriticsAndBlanks().toLowerCase()
        );
      case SecurityQuestionType.KrstneMenoOtca:
        return (
          response.removeDiacriticsAndBlanks().toLowerCase() ===
          secData?.krstneMenoOtca?.removeDiacriticsAndBlanks().toLowerCase()
        );
      case SecurityQuestionType.AktualnyVek:
        return Number(response) === secData?.aktualnyVek;
      case SecurityQuestionType.RokNarodenia:
        return Number(response) === secData?.rokNarodenia;
      case SecurityQuestionType.MestoNarodenia:
        return (
          response.removeDiacriticsAndBlanks().toLowerCase() ===
          secData?.mestoNarodenia?.removeDiacriticsAndBlanks().toLowerCase()
        );
      case SecurityQuestionType.TitulPredMenom:
        return (
          response.length >= 2 &&
          (secData?.titulPredMenom
            ?.toLowerCase()
            .includes(response.toLowerCase()) ??
            false)
        );
      case SecurityQuestionType.NazovObceTrvaleBydlisko:
        return (
          response.removeDiacriticsAndBlanks().toLowerCase() ===
          secData?.nazovObceTrvaleBydlisko
            ?.removeDiacriticsAndBlanks()
            .toLowerCase()
        );
    }
  };

  useEffect(() => {
    if (!!cisloKarty) {
      (async () => {
        const secData = await getSecurityQuestionData(cisloKarty);
        setSecurityQuestionData(secData);
      })();
    }
  }, [cisloKarty]);

  const setUserData = async (
    klientId: number | undefined,
    klientObjectId: string | undefined,
    ustavZvjsId: number | undefined
  ) => {
    console.debug(
      `setUserData klientId[${klientId}] klientObjectId[${klientObjectId}] ustavZvjsId[${ustavZvjsId}]`
    );
    if (!klientId || !klientObjectId || !ustavZvjsId) {
      console.warn("setUserData: missing data");
      return;
    }

    const { CIS_Post, EOO_Get, EOO_Post, SHARE_INT_Post } = await API_Clients();

    const cisUstav = await CIS_Post("/api/CisUstavZvjs/List", {
      body: {
        filters: [
          {
            aktualny: true,
            kodKontaktneUdajeUstavuId: ustavZvjsId,
          },
        ],
      },
    });

    const ustavKod =
      cisUstav.data?.records
        ?.find((i) => i.kodKontaktneUdajeUstavuId === ustavZvjsId)
        ?.kod?.trim() ?? null;

    ustavKodSet(ustavKod);

    const ustavSkratka =
      cisUstav.data?.records
        ?.find((i) => i.kodKontaktneUdajeUstavuId === ustavZvjsId)
        ?.skratka?.trim()
        .removeDiacritics() ?? null;

    ustavSkratkaSet(ustavSkratka);

    const eoo_klient_DetailData = await EOO_Get("/api/Klient/DetailData", {
      params: {
        query: {
          Id: klientId,
          UstavZvjsId: ustavZvjsId,
        },
      },
    });

    menoSet(
      !!eoo_klient_DetailData.data?.data?.druheMeno
        ? `${eoo_klient_DetailData.data?.data?.meno ?? ""} ${
            eoo_klient_DetailData.data?.data?.druheMeno ?? ""
          }`
        : (eoo_klient_DetailData.data?.data?.meno ?? "")
    );

    priezviskoSet(eoo_klient_DetailData.data?.data?.priezvisko ?? null);

    feooKlientIdSet(eoo_klient_DetailData.data?.data?.feooKlientId ?? null);
    fyzickaOsobaIdSet(eoo_klient_DetailData.data?.data?.fyzickaOsobaId ?? null);
    zakladneCisloSet(eoo_klient_DetailData.data?.data?.zakladneCislo ?? null);

    const eoo_klient = await EOO_Post("/api/Klient/List", {
      body: {
        filters: [
          {
            zakladneCislo: eoo_klient_DetailData.data?.data?.zakladneCislo,
            klientVUstaveID: ustavZvjsId,
          },
        ],
      },
    });

    oddielIdSet(eoo_klient.data?.records?.at(0)?.oddielId ?? null);

    const klientiVoVo = await SHARE_INT_Post(
      "/api/interfaces/Epvvvt/ListKlientZaradenyDoVo",
      {
        body: {
          klientId: {
            eq: klientObjectId
          },
          ustavId: {
            eq: ustavZvjsId
          },
        },
      }
    );

    const klientJeVoVo =
      klientiVoVo.data?.some(
        (item) => item.klientId === klientObjectId
      ) ?? false;

    vystupnyOddielSet(klientJeVoVo);

    const fyzickaOsoba = await EOO_Get("/api/FyzickaOsoba/DetailData", {
      params: {
        query: {
          request: eoo_klient_DetailData.data?.data?.fyzickaOsobaId ?? NaN,
        },
      },
    });

    if (fyzickaOsoba.data?.data?.pohlavieId === CisPohlavie_MUZ_ID) {
      pohlavieSet(CisPohlavie_MUZ_ID);
    } else if (fyzickaOsoba.data?.data?.pohlavieId === CisPohlavie_ZENA_ID) {
      pohlavieSet(CisPohlavie_ZENA_ID);
    } else {
      pohlavieSet(CisPohlavie_NEZISTENE_ID);
    }

    aktivnyTrestSet(eoo_klient_DetailData.data?.data?.aktivnyTrest === true);

    aktivnaVazbaSet(eoo_klient_DetailData.data?.data?.aktivnaVazba === true);

    narodnostIdSet(fyzickaOsoba.data?.data?.narodnostId ?? null);
  };

  const onClick = async () => {
    const { SIDKOO_Post } = await API_Clients();
    const correctResponse = checkSecurityQuestionResponse(
      response,
      selectedSecurityQuestionType,
      securityQuestionData
    );

    let responseShouldBeChecked = false;

    switch (selectedSecurityQuestionType) {
      case SecurityQuestionType.KrstneMenoManzela:
        if (!!securityQuestionData?.krstneMenoManzela) {
          responseShouldBeChecked = true;
        }
        break;
      case SecurityQuestionType.KrstneMenoDruzky:
        if (!!securityQuestionData?.krstneMenoDruzky) {
          responseShouldBeChecked = true;
        }
        break;
      case SecurityQuestionType.KrstneMenoDruha:
        if (!!securityQuestionData?.krstneMenoDruha) {
          responseShouldBeChecked = true;
        }
        break;
      case SecurityQuestionType.PriezviskoDruzky:
        if (!!securityQuestionData?.priezviskoDruzky) {
          responseShouldBeChecked = true;
        }
        break;
      case SecurityQuestionType.KrstneMenoMatky:
        if (!!securityQuestionData?.krstneMenoMatky) {
          responseShouldBeChecked = true;
        }
        break;
      case SecurityQuestionType.KrstneMenoOtca:
        if (!!securityQuestionData?.krstneMenoOtca) {
          responseShouldBeChecked = true;
        }
        break;
      case SecurityQuestionType.AktualnyVek:
        if (!!securityQuestionData?.aktualnyVek) {
          responseShouldBeChecked = true;
        }
        break;
      case SecurityQuestionType.RokNarodenia:
        if (!!securityQuestionData?.rokNarodenia) {
          responseShouldBeChecked = true;
        }
        break;
      case SecurityQuestionType.MestoNarodenia:
        if (!!securityQuestionData?.krstneMenoManzela) {
          responseShouldBeChecked = true;
        }
        break;
      case SecurityQuestionType.TitulPredMenom:
        if (!!securityQuestionData?.krstneMenoManzela) {
          responseShouldBeChecked = true;
        }
        break;
      case SecurityQuestionType.NazovObceTrvaleBydlisko:
        if (!!securityQuestionData?.nazovObceTrvaleBydlisko) {
          responseShouldBeChecked = true;
        }
        break;
    }

    console.debug(
      `responseShouldBeChecked[${responseShouldBeChecked}] securityQuestionType[${SecurityQuestionType[selectedSecurityQuestionType]}]`
    );

    if (
      (responseShouldBeChecked && correctResponse) ||
      !responseShouldBeChecked
    ) {
      const kartaResult = await SIDKOO_Post("/api/IdKarta/ListIdKartaAktivna", {
        body: {
          filters: [
            {
              cisloKarty: Number(cisloKarty),
            },
          ],
        },
      });

      const karta = kartaResult.data?.records?.at(0);

      setKlientId(karta?.klientId);
      setKlientObjectId(karta?.klientObjectId);
      setKlientUstavId(karta?.ustavZvjsId);

      await setUserData(
        karta?.klientId,
        karta?.klientObjectId,
        karta?.ustavZvjsId
      );

      onLogin();
      setStep(4);
    } else {
      const message = capitalize(
        tui("Nesprávna odpoveď na bezpečnostnú otázku")
      );
      console.error(message);
      openSnackbar(message, "error");
    }

    setResponse("");
  };

  return (
    <Stack
      spacing={1}
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
      alignItems="center"
    >
      <Stack spacing={-1}>
        <Typography variant="h3" textAlign="center">
          {capitalize(tui("prihlasenie.bezpecnostnasOtazka"))}
          {":"}
        </Typography>
        <Typography
          variant="h3"
          sx={{ fontStyle: "italic" }}
          textAlign="center"
          width="80vw"
        >
          {capitalize(
            tui(
              selectedSecurityQuestionType ===
                SecurityQuestionType.KrstneMenoManzela
                ? "prihlasenie.krstneMenoManzela"
                : selectedSecurityQuestionType ===
                    SecurityQuestionType.KrstneMenoDruzky
                  ? "prihlasenie.krstneMenoDruzky"
                  : selectedSecurityQuestionType ===
                      SecurityQuestionType.KrstneMenoDruha
                    ? "prihlasenie.krstneMenoDruha"
                    : selectedSecurityQuestionType ===
                        SecurityQuestionType.PriezviskoDruzky
                      ? "prihlasenie.priezviskoDruzky"
                      : selectedSecurityQuestionType ===
                          SecurityQuestionType.KrstneMenoMatky
                        ? "prihlasenie.krstneMenoMatky"
                        : selectedSecurityQuestionType ===
                            SecurityQuestionType.KrstneMenoOtca
                          ? "prihlasenie.krstneMenoOtca"
                          : selectedSecurityQuestionType ===
                              SecurityQuestionType.AktualnyVek
                            ? "prihlasenie.AktualnyVek"
                            : selectedSecurityQuestionType ===
                                SecurityQuestionType.RokNarodenia
                              ? "prihlasenie.rokNarodenia"
                              : selectedSecurityQuestionType ===
                                  SecurityQuestionType.MestoNarodenia
                                ? "prihlasenie.mestoNarodenia"
                                : selectedSecurityQuestionType ===
                                    SecurityQuestionType.TitulPredMenom
                                  ? "prihlasenie.titulPredMenom"
                                  : "prihlasenie.nazovObceTrvaleBydlisko"
            )
          )}
        </Typography>
      </Stack>
      <Stack spacing={3} sx={{ width: "30rem" }}>
        <ZvjsTextField
          autoFocus
          label=""
          value={response}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setResponse(event.target.value);
          }}
          onKeyDown={(
            event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            if (event.code === "Enter") {
              onClick();
            }
          }}
        />
        <Stack direction="row">
          <ZvjsButton
            text={capitalize(tui("prihlasenie.prihlasitSa"))}
            endIcon={<ArrowForwardIos />}
            zvjsVariant={"secondaryAction"}
            onClick={onClick}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LoginStep3;
