import {
  State,
  UserCalcData,
  ZvjsCustomQuestion,
} from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { findItem } from "../../../../../../redux/slice";
import { API_Clients } from '../../../../../../../../store/context/dataApi/Data';
import { LoaderError } from '../../../../../../../../router/LoaderError';

const AVAILABLE_PROGRAMS = "PROGRAMS";
export const ProgramsGridConstants = {
  AVAILABLE_PROGRAMS,
};

/**
 * OnInit
 */

export interface ProgramType {
  id: string;
  name: string;
  content?: string;
}

export const SC_KIO_050602_ProgramsGrid_onInit = async (
  calcData: UserCalcData
): Promise<void> => {
  const { SHARE_INT_Post } = await API_Clients();

  const data = await Promise.all([
    SHARE_INT_Post("/api/interfaces/Epvvvt/ListZoznamSpecializovanychResocializacnychProgramov", {
      body: {},
    }),
    SHARE_INT_Post("/api/interfaces/Cis/ListSzooCisResocializacnyProgramA", {
      body: {},
    }),
    /*
    SHARE_INT_Post("/api/interfaces/Cis/ListEpvvvtCisOraNeformalneStandVzdelavA", {
      body: {},
    }),*/
  ]);

  if (data[0].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [zoznam špecializovaných resocializačných programov]");
  }
  if (data[1].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [zoznam resocializačných programov]");
  }
  /*if (data[2].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [zoznam vzdelávacích programov]");
  }*/

  const listOfPrograms0 = (data[0].data ?? []).map((program: any) => ({
    id: program.epvvvtCisTypProgramuVrpATaId,
    name: program.typProgramuNazov,
    content: "", // TODO doplnit ciel programu
  }));

  const listOfPrograms1 = (data[1].data ?? []).map((program: any) => ({
    id: program.szooCisResocializacnyProgramId,
    name: program.nazov,
    content: "", // TODO doplnit ciel programu
  }));

  /*
  const listOfPrograms2 = (data[2].data ?? []).map((program: any) => ({
    id: program.epvvvtCisOraNeformalneStandVzdelavId,
    name: program.nazov,
    content: "", // TODO doplnit ciel programu
  }));*/

  const mergedPrograms = [...listOfPrograms0, ...listOfPrograms1];

  calcData[ProgramsGridConstants.AVAILABLE_PROGRAMS] = mergedPrograms;
};

/**
 * Selectors
 */

const getProgramsGridDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    return {
      id: question.id,
      title: question.title,
      availablePrograms: state.userCalcData[
        ProgramsGridConstants.AVAILABLE_PROGRAMS
      ] as ProgramType[],
    };
  });

export const allSelectors = {
  getProgramsGridDisplayData,
};
