import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import {
  createSearchParams,
  defer,
  Params,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  Dashboard_SC_KIO_0200,
  ZvjsButton,
  ZvjsSearchBar,
  ZvjsTable,
} from "../../../common";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { withLoader } from "../../../hoc/withLoader";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { ZvjsSearchBarVariant } from "../../../common/components/ZvjsSearchBar";
import { API_Clients } from "../../../store/context/dataApi/Data";
import { LoaderError } from "../../../router/LoaderError";
import moment from "moment";

const loadData = async (params: Params, request: Request) => {
  const searchParams = new URL(request.url).searchParams;
  const datumOd = searchParams.get("datumOd")
    ? decodeURIComponent(searchParams.get("datumOd") || "")
    : undefined;
  const datumDo = searchParams.get("datumDo")
    ? decodeURIComponent(searchParams.get("datumDo") || "")
    : undefined;

  console.debug(`Navstevy loadData datumOd[${datumOd}] datumDo[${datumDo}]`);

  const { SHARE_INT_Post } = await API_Clients();

  const data = await Promise.all([
    SHARE_INT_Post("/api/interfaces/Epvvvt/ListZoznamZrealizovanychNavstev", {
      body: {
        klientId: {
          eq: localStorage.getItem("klientObjectId") ?? "faultyId",
        },
        planovanyDen:
          !!datumOd || !!datumDo
            ? {
                gte: datumOd,
                lte: datumDo,
              }
            : undefined,
      },
    }),
  ]);

  if (data[0].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [návštevy]");
  }

  return {
    navstevy: data[0],
  };
};

export const NavstevyLoader = async ({
  params,
  request,
}: {
  params: Params;
  request: Request;
}) => {
  return defer({
    data: loadData(params, request),
  });
};

interface NavstevyProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const Navstevy = (props: NavstevyProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  const onSearch = (dateFrom: Date, dateTo: Date) => {
    console.debug(`Navstevy onSearch dateFrom[${dateFrom}] dateTo[${dateTo}]`);
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams({
          ...(dateFrom.isValid() && {
            datumOd: encodeURIComponent(moment(dateFrom).toISOString()),
          }),
          ...(dateTo.isValid() && {
            datumDo: encodeURIComponent(moment(dateTo).toISOString()),
          }),
        }).toString()}`,
      },
      { replace: true }
    );
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(tui("osobneUdaje.kategorieOsobnychUdajov.navstevy"))}
          </Typography>
        </Stack>
      </Grid>
      <Stack direction="column" spacing={2}>
        <ZvjsSearchBar
          title={tui("vyhľadať v náštevách")}
          type={ZvjsSearchBarVariant.VARIANT_A_DpDp}
          onSubmit={onSearch}
        />

        <ZvjsTable
          data={{
            header: [
              {
                value: capitalize(tui("dátum návštevy")),
                align: "left",
                width: "22%",
              },
              {
                value: capitalize(tui("typ návštevy")),
                align: "left",
                width: "26%",
              },
              {
                value: capitalize(tui("forma návštevy")),
                align: "left",
                width: "26%",
              },
              {
                value: capitalize(tui("účastník")),
                align: "left",
                width: "26%",
              },
            ],
            body:
              props.data.navstevy.data
                .sort(
                  (p1, p2) =>
                    moment(p1.planovanyDen).valueOf() -
                    moment(p2.planovanyDen).valueOf()
                )

                .map((navsteva) => {
                  const rd: RowData = {
                    row: [
                      {
                        value: new Date(
                          navsteva.planovanyDen ?? new Date()
                        ).zvjsToString(),
                        align: "left",
                        width: "22%",
                      },
                      {
                        value: "Zrealizovaná návšteva",
                        align: "left",
                        width: "26%",
                      },
                      {
                        value: navsteva.formaRealizacieNazov ?? "",
                        align: "left",
                        width: "26%",
                      },
                      {
                        value: navsteva.osobaNavstevy ?? "",
                        align: "left",
                        width: "26%",
                      },
                    ],
                  };
                  return rd;
                }) ?? [],
          }}
          height={360}
          variant={ZvjsTableVariant.NORMAL}
          // TODO add translation
          hintText={" "}
        />

        <Grid width="20rem">
          <ZvjsButton
            text={capitalize(tui("žiadosť o návštevu"))}
            zvjsVariant="secondaryAction"
            onClick={() => {
              navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}/04.00.01`);
            }}
            endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
            style={{
              lineHeight: "1",
              textAlign: "left",
              textTransform: "none",
            }}
          />
        </Grid>
      </Stack>
    </Grid>
  );
};

export default withLoader(Navstevy);
