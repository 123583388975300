import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ReactComponent as DetailIcon } from "../../../../../assets/icons/Other/detail.svg";
import { useNavigate } from "react-router-dom";
import { ZvjsButton, ZvjsTable } from "../../../../../common";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../../../store/context/translation-context";
import { defer } from "react-router-dom";
import { withLoader } from "../../../../../hoc/withLoader";
import { API_Clients } from "../../../../../store/context/dataApi/Data";
import { LoaderError } from "../../../../../router/LoaderError";

const loadData = async () => {
  const { FEOO_Get, FEOO_Post } = await API_Clients();

  const feooKlient = await FEOO_Get("/api/Klient/Get", {
    params: {
      query: {
        id: localStorage.getItem("klientObjectId") ?? undefined,
      },
    },
  });

  const vyzivovaciePovinnosti = await FEOO_Post(
    "/api/VyzivovaciaPovinnost/List",
    {
      body: {
        filters: [
          {
            zakladnyFilter: {
              zakladneCislo:
                feooKlient.data?.data?.zakladneUdajeKlienta?.zakladneCislo,
            },
          },
        ],
      },
    }
  );

  if (vyzivovaciePovinnosti.error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [pohľadávky vyživovacích povinností]"
    );
  }


  const testData = {
    data: {
      "records": [
        {
          "vyzivovaciaPovinnostID": 1009,
          "klientFeooID": 3805,
          "pohladavkaID": 1249,
          "zakladneCislo": 245741,
          "priezvisko": "Novotný",
          "meno": "Juraj",
          "typVykonuID": 1,
          "typVykonu": "Trest",
          "fsvUstaveID": 6,
          "fsvUstave": "ÚVV a ÚVTOS Prešov",
          "stavVyz": "Zaevidovaná",
          "stavVyzID": 9,
          "poradoveCislo": 3,
          "mesacneVyzivne": 165,
          "percento": null,
          "vyzivovanaOsobaID": 1010,
          "vyzivovanaOsoba": "Tester Testovic",
          "typZaevidovaniaID": 1,
          "typZaevidovania": "Exekučný príkaz",
          "zaostaleVyzivne": 0,
          "doruceniePrvemuPlatcovi": "2024-11-11T00:00:00",
          "datumPravoplatnosti": "2024-11-11T00:00:00",
          "datumUkoncenia": "2007-08-31T00:00:00",
          "poznamka": null,
          "deponovane": false,
          "dlhNaVyzivnom": null,
          "stavVyzFarba": false,
          "priezviskoAMeno": "Novotný Juraj",
          "sumaSplatena": null,
          "prijemcaVeritel": "Novotný Juraj",
          "dlznaSuma": null,
          "klientVUstaveID": 6,
          "stavKlientaSkratka": null,
          "ikona": null,
          "farba": "denim",
          "transakciaID": "00000000-0000-0000-0000-000000000000",
          "transakciaPovodnaID": "0c9251f6-e288-4aa4-ba30-1ef5c89a08e6",
          "changed": "0001-01-01T00:00:00"
        },
        {
          "vyzivovaciaPovinnostID": 1016,
          "klientFeooID": 3805,
          "pohladavkaID": 1259,
          "zakladneCislo": 245741,
          "priezvisko": "Novotný",
          "meno": "Juraj",
          "typVykonuID": 1,
          "typVykonu": "Trest",
          "fsvUstaveID": 6,
          "fsvUstave": "ÚVV a ÚVTOS Prešov",
          "stavVyz": "Zaevidovaná",
          "stavVyzID": 9,
          "poradoveCislo": 4,
          "mesacneVyzivne": 50,
          "percento": null,
          "vyzivovanaOsobaID": 1010,
          "vyzivovanaOsoba": "Tester Testovic",
          "typZaevidovaniaID": 2,
          "typZaevidovania": "Rozhodnutie súdu",
          "zaostaleVyzivne": 500,
          "doruceniePrvemuPlatcovi": "2024-11-14T00:00:00",
          "datumPravoplatnosti": "2024-04-14T00:00:00",
          "datumUkoncenia": "2007-08-31T00:00:00",
          "poznamka": null,
          "deponovane": false,
          "dlhNaVyzivnom": null,
          "stavVyzFarba": false,
          "priezviskoAMeno": "Novotný Juraj",
          "sumaSplatena": null,
          "prijemcaVeritel": "Novotný Juraj",
          "dlznaSuma": null,
          "klientVUstaveID": 6,
          "stavKlientaSkratka": null,
          "ikona": null,
          "farba": "denim",
          "transakciaID": "00000000-0000-0000-0000-000000000000",
          "transakciaPovodnaID": "45430097-99ab-4747-afe5-bce316f9bdf4",
          "changed": "0001-01-01T00:00:00"
        },
        {
          "vyzivovaciaPovinnostID": 1017,
          "klientFeooID": 3805,
          "pohladavkaID": 1567,
          "zakladneCislo": 245741,
          "priezvisko": "Novotný",
          "meno": "Juraj",
          "typVykonuID": 1,
          "typVykonu": "Trest",
          "fsvUstaveID": 6,
          "fsvUstave": "ÚVV a ÚVTOS Prešov",
          "stavVyz": "Zaevidovaná",
          "stavVyzID": 9,
          "poradoveCislo": 5,
          "mesacneVyzivne": 10,
          "percento": null,
          "vyzivovanaOsobaID": 1010,
          "vyzivovanaOsoba": "Tester Testovic",
          "typZaevidovaniaID": 1,
          "typZaevidovania": "Exekučný príkaz",
          "zaostaleVyzivne": 0,
          "doruceniePrvemuPlatcovi": "2024-11-13T00:00:00",
          "datumPravoplatnosti": "2024-11-12T00:00:00",
          "datumUkoncenia": "2007-08-31T00:00:00",
          "poznamka": null,
          "deponovane": false,
          "dlhNaVyzivnom": null,
          "stavVyzFarba": false,
          "priezviskoAMeno": "Novotný Juraj",
          "sumaSplatena": null,
          "prijemcaVeritel": "Novotný Juraj",
          "dlznaSuma": null,
          "klientVUstaveID": 6,
          "stavKlientaSkratka": null,
          "ikona": null,
          "farba": "denim",
          "transakciaID": "00000000-0000-0000-0000-000000000000",
          "transakciaPovodnaID": "7ea7ecbd-7abb-4775-a91c-a426facc27d6",
          "changed": "0001-01-01T00:00:00"
        }
      ],
      "paging": {
        "totalCount": 0,
        "currentPage": 1,
        "recordsPerPage": 2147483647
      }
    }
  };

  return {
    vyzivovaciePovinnosti: testData,
  };
};

export const PohladavkyVyzivovacichPovinnostiLoader = async () => {
  return defer({
    data: loadData(),
  });
};

interface PohladavkyVyzivovacichPovinnostiProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const PohladavkyVyzivovacichPovinnosti = (
  props: PohladavkyVyzivovacichPovinnostiProps
) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(tui("vyživovacie povinnosti"))}
          </Typography>
        </Stack>
      </Grid>

      <ZvjsTable
        data={{
          header: [
            {
              value: capitalize(tui("osobneUdaje.vseobecneOsobneUdaje.meno")),
              align: "left",
            },
            {
              value: capitalize(
                tui("osobneUdaje.vseobecneOsobneUdaje.priezvisko")
              ),
              align: "left",
            },
            {
              value: capitalize(
                tui("osobneUdaje.rozpisSplatky.sposobZaevidovania")
              ),
              align: "left",
            },
            {
              value: capitalize(
                tui("osobneUdaje.vyzivovaciePovinnosti.sumaBeznehoVyzivneho")
              ),
              align: "left",
            },
            {
              value: capitalize(
                tui("osobneUdaje.vyzivovaciePovinnosti.sumaDlznehoVyzivneho")
              ),
              align: "left",
            },
            {
              value: capitalize(tui("akcia")),
              align: "left",
            },
          ],
          body:
            props.data.vyzivovaciePovinnosti.data?.records
              // .sort((p1, p2) =>
              //   p1.name > p2.name)
              ?.map((vyzivovaciaPovinnost) => {
                const rd: RowData = {
                  row: [
                    {
                      value:
                        vyzivovaciaPovinnost.vyzivovanaOsoba
                          ?.split(" ")
                          .at(0) ?? "",
                      align: "left",
                    },
                    {
                      value:
                        vyzivovaciaPovinnost.vyzivovanaOsoba
                          ?.split(" ")
                          .at(1) ?? "",
                      align: "left",
                    },
                    {
                      value: vyzivovaciaPovinnost.typZaevidovania ?? "",
                      align: "left",
                    },
                    {
                      value: vyzivovaciaPovinnost.mesacneVyzivne
                        ? vyzivovaciaPovinnost.mesacneVyzivne.toFixed(2) +
                          " Eur"
                        : "",
                      align: "left",
                    },
                    {
                      value: vyzivovaciaPovinnost.zaostaleVyzivne
                        ? vyzivovaciaPovinnost.zaostaleVyzivne.toFixed(2) +
                          " Eur"
                        : "",
                      align: "left",
                    },
                    {
                      value: (
                        <ZvjsButton
                          text={capitalize(tui("rozpis splátok"))}
                          zvjsVariant="secondaryAction"
                          startIcon={
                            <DetailIcon
                              style={{ fill: "white", width: 28, height: 28 }}
                            />
                          }
                          onClick={() => {
                            navigate(
                              `${vyzivovaciaPovinnost.vyzivovaciaPovinnostID}`
                            );
                          }}
                          style={{
                            lineHeight: "1",
                            textTransform: "none",
                            textAlign: "left",
                          }}
                        />
                      ),
                      align: "left",
                    },
                  ],
                };
                return rd;
              }) ?? [],
        }}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Grid>
  );
};

export default withLoader(PohladavkyVyzivovacichPovinnosti);
