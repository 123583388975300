import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../../../store/redux";
import CustomQuestionProps from "../CustomQuestionProps";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";
import questionnaireRedux from "../../../../../../redux";
import { ZvjsCheckBoxStateValues } from "../../../../../../../components/ZvjsCheckBox";
import {
  CellData,
  RequestFontSizes,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../../../../components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import {
  ZvjsButton,
  ZvjsCheckBox,
  ZvjsCustomQuestionFullWidthBox,
  ZvjsTable,
} from "../../../../../../../components";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { default as AddPersonForm } from "../../../forms/SC_KIO_050101_AddPersonForm";
import { useModal } from "../../../../../../../../store/context/modal-context";
import { useSubmit } from "react-router-dom";
import { ActionBodyWrapper } from "../../../../../../../../router/ActionBodyWrapper";
import { RequestActionsType } from "../../../../../../Request";
import { CisTypBlizkejOsoby_Dieta_ID } from "../../../../../../../specs/countersValues";

const SC_KIO_050701_PhoneCallLawyersGrid: React.FC<CustomQuestionProps> = ({
  location,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { openModal } = useModal();
  const { tuiz } = useUITranslation();
  const submit = useSubmit();

  console.debug(
    `SC_KIO_050701 PHONE CALL LAWYERS GRID QUESTION RERENDER ${location.join(
      "-"
    )}`
  );

  // /api/Administracia/PravnyZastupcaData
  const questionData = useSelector(
    questionnaireRedux.selectors.getPhoneCallLawyersGridDisplayData(
      location
    ),
    shallowEqual
  );

  const closePeopleList = useSelector(
    questionnaireRedux.selectors.getPhysicalVisitClosePeopleList(),
    shallowEqual
  );

  const answerVal = useSelector(
    questionnaireRedux.selectors.getQuestionAnswerData(questionData.id),
    shallowEqual
  ) as undefined | string[];

  const basicClientData = useSelector(
    questionnaireRedux.selectors.getPhysicalVisitBasicClientData(),
    shallowEqual
  );

  const closePersonRelationCounter = useSelector(
    questionnaireRedux.selectors.getPhysicalVisitClosePersonRelationTypeCounter(),
    shallowEqual
  );

  const onChangeHandler = (
    label: string | undefined,
    key: string,
    checked: boolean,
    checkedValues: ZvjsCheckBoxStateValues[]
  ) => {
    if (checked) {
      const answersCopy: string[] = answerVal
        ? JSON.parse(JSON.stringify(answerVal))
        : [];
      answersCopy.push(key);
      dispatch(
        questionnaireRedux.actions.addNewAnswer(
          location,
          questionData.id,
          answersCopy
        )
      );
    } else {
      let answersCopy: string[] = answerVal
        ? JSON.parse(JSON.stringify(answerVal))
        : [];
      answersCopy = answersCopy.filter((answer) => answer !== key);
      dispatch(
        questionnaireRedux.actions.addNewAnswer(
          location,
          questionData.id,
          answersCopy
        )
      );
    }
  };

  const dataStyle: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "right",
      width: 250,
      sx: {
        display: "grid",
        justifyContent: "flex-end",
      },
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(tuiz("meno")),
      ...dataStyle[0],
    },
    {
      value: capitalize(tuiz("priezvisko")),
      ...dataStyle[1],
    },
    {
      value: capitalize(tuiz("ziadost.ziadostOnavstevu.labelVztahKosobe")),
      ...dataStyle[2],
    },
    {
      value: capitalize(
        tuiz("ziadost.ziadostOnavstevu.labelPozvatOsobuNaFyzNavstevu")
      ),
      ...dataStyle[3],
    },
  ];

  const bodyData: Array<RowData> = new Array<RowData>();

  const numberOfChildren = closePeopleList.filter(
    (person) => person.relationId === CisTypBlizkejOsoby_Dieta_ID
  ).length;

  const numberOfSelectedChildren =
    answerVal?.filter((answer) =>
      closePeopleList.some(
        (person) =>
          person.fyzickaOsobaId.toString() === answer &&
          person.relationId === CisTypBlizkejOsoby_Dieta_ID
      )
    ).length ?? 0;

  for (const personDisplayData of questionData.peopleList) {
    const isChecked = answerVal?.some((answer) => {
      return answer === personDisplayData.id.toString();
    });

    const closePersonData = closePeopleList.find(
      (closePerson) => closePerson.fyzickaOsobaId === personDisplayData.id
    );

    bodyData.push({
      row: [
        {
          value: personDisplayData.firstname ?? "",
          ...dataStyle[0],
        },
        {
          value: personDisplayData.surname ?? "",
          ...dataStyle[1],
        },
        {
          value: personDisplayData.relationText ?? "",
          ...dataStyle[2],
        },
        {
          value: (
            <ZvjsCheckBox
              onChange={onChangeHandler}
              data={[
                {
                  key: personDisplayData.id.toString(),
                  checked: isChecked,
                  // user cannot choose more than 5 people for a visit
                  // only exception is when a user has above 4 kids,
                  // then these kids do not count into the limit for a visit
                  disabled:
                    !isChecked &&
                    (numberOfChildren > 4
                      ? !(
                          closePersonData?.relationId ===
                          CisTypBlizkejOsoby_Dieta_ID
                        ) &&
                        (answerVal?.length ?? 0 - numberOfSelectedChildren) > 4
                      : (answerVal?.length ?? 0) > 4),
                },
              ]}
            />
          ),
          ...dataStyle[3],
        },
      ],
    });
  }

  const tableData: TableData = {
    header: headerData,
    body: bodyData,
    label: tuiz(questionData.title),
  };

  const onSubmit = (toSubmit: unknown) => {
    const submitBody: ActionBodyWrapper = {
      action: RequestActionsType.SC_KIO_050101_ADD_NEW_PERSON,
      data: toSubmit,
    };
    submit(JSON.parse(JSON.stringify(submitBody)), {
      method: "POST",
      encType: "application/json",
    });
  };

  return (
    <ZvjsCustomQuestionFullWidthBox location={location}>
      <ZvjsTable
        data={tableData}
        variant={ZvjsTableVariant.NORMAL}
        fontSizes={RequestFontSizes}
      />
      <ZvjsButton
        zvjsVariant={"requestAction"}
        text={tuiz("ziadost.ziadostOnavstevu.labelDoplnenieInejOsoby")}
        onClick={() => {
          openModal(
            <AddPersonForm
              // On submit method cannot be defined directly inside dialog, as dialog itself is mounted in the root of application (via context) which means that router action (registered to specific route) would not catch this submit action
              onSubmit={onSubmit}
              basicClientData={basicClientData}
              closePersonRelationCounter={closePersonRelationCounter}
            />
          );
        }}
        endIcon={
          <AddCircleOutlineOutlinedIcon sx={{ fontSize: "30px !important" }} />
        }
      />
    </ZvjsCustomQuestionFullWidthBox>
  );
};

export default SC_KIO_050701_PhoneCallLawyersGrid;
