import { defer } from "react-router-dom";
import { ZvjsSummaryTable } from "../../../../common";
import { ZvjsSummaryTableComponent } from "../../../../common/components/ZvjsSummaryTable";
import { Stack } from "@mui/material";
import { useUITranslation } from "../../../../store/context/translation-context";
import { API_Clients } from "../../../../store/context/dataApi/Data";
import { withLoader } from "../../../../hoc/withLoader";
import { LoaderError } from "../../../../router/LoaderError";
import { CisDruhAdresy_Prechodny_Pobyt_Kod } from "../../../../common/specs/countersValues";

const loadData = async () => {
  const { EOO_Get, EOO_Post } = await API_Clients();

  const eoo_klient_DetailZakladneUdajeKlientaData = await EOO_Get(
    "/api/Klient/DetailZakladneUdajeKlientaData",
    {
      params: {
        query: {
          Id: Number(localStorage.getItem("klientId")),
          UstavZvjsId: Number(localStorage.getItem("klientUstavId")),
        },
      },
    }
  );

  if (eoo_klient_DetailZakladneUdajeKlientaData.error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [údaje klienta detail]");
  }

  const data = await Promise.all([
    EOO_Post("/api/FyzickaOsoba/ListAdresaOsoba", {
      body: {
        filters: [
          eoo_klient_DetailZakladneUdajeKlientaData.data?.data
            ?.fyzickaOsobaId ?? NaN,
        ],
      },
    }),
    EOO_Get("/api/Ciselnik/DruhAdresy"),
  ]);

  if (data[0].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [adresy]");
  }

  if (data[1].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [druh adresy]");
  }

  return {
    prechodnePobyty: data[0].data?.records
      ?.filter((x) =>
        data[1].data
          ?.filter((x) => x.kod === CisDruhAdresy_Prechodny_Pobyt_Kod)
          .map((x) => x.id)
          .includes(x.druhAdresyId)
      )
      .at(0),
  };
};

export const PrechodnyPobytTabLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface PrechodnyPobytTabProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const PrechodnyPobytTab = (props: PrechodnyPobytTabProps) => {
  const { tui } = useUITranslation();

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsSummaryTable
        header={tui("osobneUdaje.AdresaPrechodnehoBydliska.adresa")}
      >
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.AdresaPrechodnehoBydliska.uKoho")}
          value={props.data.prechodnePobyty?.uKoho ?? ""}
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.AdresaPrechodnehoBydliska.ulica")}
          value={props.data.prechodnePobyty?.ulica ?? ""}
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.AdresaPrechodnehoBydliska.cisloSupisne")}
          value={props.data.prechodnePobyty?.supisneCislo ?? ""}
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.AdresaPrechodnehoBydliska.cisloOrientacne")}
          value={props.data.prechodnePobyty?.orientacneCislo ?? ""}
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.AdresaPrechodnehoBydliska.obec")}
          value={props.data.prechodnePobyty?.obecNazov ?? ""}
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.AdresaPrechodnehoBydliska.psc")}
          value={props.data.prechodnePobyty?.psc ?? ""}
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.AdresaPrechodnehoBydliska.okres")}
          value={props.data.prechodnePobyty?.okresNazov ?? ""}
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.AdresaPrechodnehoBydliska.kraj")}
          value={props.data.prechodnePobyty?.krajNazov ?? ""}
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.AdresaPrechodnehoBydliska.stat")}
          value={props.data.prechodnePobyty?.statNazov ?? ""}
          isBorder={false}
          fullWidth={true}
        />
      </ZvjsSummaryTable>
    </Stack>
  );
};

export default withLoader(PrechodnyPobytTab);
