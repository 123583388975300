import {
  Calculate,
  UserCalcData,
  ZvjsConditionTypes,
  ZvjsCustomQuestionTypes,
  ZvjsItemTypes,
  ZvjsLabelFlagTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";
import { CreateZiadostRequestBody } from "../../specs/swaggerTypes";
import {
  SC_KIO_050101_ClosePerson
} from "../requestPages/questionnaire/utils/calculations/custom";
import {
  CisFormaRealizacieNavstevy_Fyzicka_Navsteva_Kod,
  CisFormaRealizacieNavstevy_Videonavsteva_Kod,
  CisFormaRealizacieNavstevy_Videonavsteva_S_Maloletymi_Detmi_Kod,
  CisTrvanieNavstevy_1_hodina_Kod,
  CisTypBlizkejOsoby_Dieta_ID,
} from "../../specs/countersValues";

export const SC_KIO_050102_CLOSE_PEOPLE_LIST =
  "SC_KIO_050102_CLOSE_PEOPLE_LIST";

export const SC_KIO_050102_STANDARD_VISIT_LIMIT_TOTAL = 2;
export const SC_KIO_050102_PHYSICAL_VISIT_LIMIT = 2;
export const SC_KIO_050102_VIDEO_CALL_VISIT_LIMIT = 1;
export const MONTH_OF_VISIT_QUESTION_ID = "mesiacNavstevy";
export const FORM_OF_VISIT_QUESTION_ID = "formaRealizacieNavstevy";
export const INVITED_PEOPLE_LIST_QUESTION_ID = "pozyvaneOsoby";


export const SC_KIO_050102_calculateVisitYear = (monthOfVisit: string): number => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const visitMonth = parseInt(monthOfVisit, 10);

  return visitMonth < currentMonth ? currentYear + 1 : currentYear;
};

export const SC_KIO_050102_can_request_visit_all_checks = (
  { userCalcData, }: {
  userCalcData: UserCalcData;
}) => {
  const today = new Date();
  const hasUnderageChild = (
    userCalcData[SC_KIO_050102_CLOSE_PEOPLE_LIST] as SC_KIO_050101_ClosePerson[]
  ).some(
    (person) =>
      person.relationId === CisTypBlizkejOsoby_Dieta_ID && person.isUnderage
  );

  const calculateCheckForMonth = (
    month: number,
    requests: CreateZiadostRequestBody[]
  ) => {
    let hasAlreadyRequestedExtraordinaryVisit = false;
    let hasRequestedVideoCallKidsVisit = false;
    let totalVideoCallVisitTimeRequested = 0;
    let totalPhysicalVisitTimeRequested = 0;

    for (const requestBody of requests) {
      //  this month
      if (requestBody.mesiacNavstevy === month) {
        if (requestBody.dovodMimoriadnejNavstevy) {
          hasAlreadyRequestedExtraordinaryVisit = true;
        }
        if (
          requestBody.formaRealizacieNavstevy ===
          CisFormaRealizacieNavstevy_Videonavsteva_S_Maloletymi_Detmi_Kod
        ) {
          hasRequestedVideoCallKidsVisit = true;
        }
        if (
          requestBody.formaRealizacieNavstevy ===
          CisFormaRealizacieNavstevy_Fyzicka_Navsteva_Kod &&
          // do not include extraordinary visit requests into this calculation
          !requestBody.dovodMimoriadnejNavstevy
        ) {
          totalPhysicalVisitTimeRequested +=
            requestBody.trvanieNavstevy === CisTrvanieNavstevy_1_hodina_Kod
              ? 1
              : 2;
        }
        if (
          requestBody.formaRealizacieNavstevy ===
          CisFormaRealizacieNavstevy_Videonavsteva_Kod &&
          // do not include extraordinary visit requests into this calculation
          !requestBody.dovodMimoriadnejNavstevy
        ) {
          totalVideoCallVisitTimeRequested += 1;
        }
      }
    }

    const prepareToReturn = {
      standardVideoCallVisit:
        totalVideoCallVisitTimeRequested <
        SC_KIO_050102_VIDEO_CALL_VISIT_LIMIT &&
        totalPhysicalVisitTimeRequested < SC_KIO_050102_PHYSICAL_VISIT_LIMIT,
      standardPhysicalVisit:
        totalPhysicalVisitTimeRequested + totalVideoCallVisitTimeRequested <
        SC_KIO_050102_STANDARD_VISIT_LIMIT_TOTAL,
      videoCallKidsVisit: !hasRequestedVideoCallKidsVisit && hasUnderageChild,
      extraordinaryVisit: !hasAlreadyRequestedExtraordinaryVisit,
      totalStandardVisitTimeRequested:
        totalVideoCallVisitTimeRequested + totalPhysicalVisitTimeRequested,
    };

    return {
      any:
        prepareToReturn.standardVideoCallVisit ||
        prepareToReturn.videoCallKidsVisit ||
        prepareToReturn.standardVideoCallVisit ||
        prepareToReturn.extraordinaryVisit,
      ...prepareToReturn,
    };
  };

  const thisMonthChecks = calculateCheckForMonth(
    today.getMonth() + 1,
    userCalcData[
      "SC_KIO_050102_VISIT_REQUESTS_FOR_CURRENT_AND_NEXT_MONTH_LIST"
      ] as CreateZiadostRequestBody[]
  );

  const nextMonthChecks = calculateCheckForMonth(
    today.getMonth() === 11 ? 1 : today.getMonth() + 2,
    userCalcData[
      "SC_KIO_050102_VISIT_REQUESTS_FOR_CURRENT_AND_NEXT_MONTH_LIST"
      ] as CreateZiadostRequestBody[]
  );

  return {
    thisMonthAny: thisMonthChecks.any,
    thisMonthStandardVideoCallVisit: thisMonthChecks.standardVideoCallVisit,
    thisMonthStandardPhysicalVisit: thisMonthChecks.standardPhysicalVisit,
    thisMonthVideoCallKidsVisit: thisMonthChecks.videoCallKidsVisit,
    thisMonthExtraordinaryVisit: thisMonthChecks.extraordinaryVisit,
    // total requested time of video call and physical visit for this month
    thisMonthTotalStandardVisitTimeRequested:
    thisMonthChecks.totalStandardVisitTimeRequested,

    nextMonthAny: nextMonthChecks.any,
    nextMonthStandardVideoCallVisit: nextMonthChecks.standardVideoCallVisit,
    nextMonthStandardPhysicalVisit: nextMonthChecks.standardPhysicalVisit,
    nextMonthVideoCallKidsVisit: nextMonthChecks.videoCallKidsVisit,
    nextMonthExtraordinaryVisit: nextMonthChecks.extraordinaryVisit,
    // total requested time of video call and physical visit for next month
    nextMonthTotalStandardVisitTimeRequested:
    nextMonthChecks.totalStandardVisitTimeRequested,
  };
};

export const SC_KIO_050102_is_any_selected_person_in_prison = (
  selectedPeopleList: string[],
  closePeopleList: SC_KIO_050101_ClosePerson[]
) => {
  for (const selectedPhysicalPersonId of selectedPeopleList) {
    if (
      closePeopleList?.some(
        (person) =>
          person?.fyzickaOsobaId === Number(selectedPhysicalPersonId) &&
          person?.inPrison
      )
    ) {
      return true;
    }
  }
  return false;
};

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050102",
  basicInfo: [
    {
      text: "TODO: ZVJS doplnit info",
    },
  ],
  dataCalculations: {
    IS_USER_ACCUSED: {
      calculateAt: Calculate.AT_INIT,
    },
    IS_USER_SENTENCED: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_050101_VISIT_REQUESTS_FOR_CURRENT_AND_NEXT_MONTH_LIST: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_050101_DISPLAY_2_HOURS_VISIT_OPTION: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_050101_CLOSE_PEOPLE_LIST: {
      calculateAt: Calculate.AT_INIT,
    },
    BASIC_CLIENT_DATA: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_050101_CAN_REQUEST_EXTRAORDINARY_VISIT_ONLY: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_050101_IS_PHYSICAL_VISIT_AVAILABLE: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_050101_IS_VIDEO_CALL_VISIT_AVAILABLE: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_050101_IS_UNDERAGE_KIDS_VIDEO_CALL_VISIT_AVAILABLE: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_050101_SHOULD_DISPLAY_INVITED_PERSON_IN_PRISON_WARNING: {
      calculateAt: Calculate.LIVE,
    },
  },
  counters: [
    "/api/CisTypBlizkejOsoby/List",
    "/api/CisFormaRealizacieNavstevy/List",
    "/api/CisTrvanieNavstevy/List",
    "/api/CisSposobOdoslaniaPozvanky/List",
    "/api/CisDovodMimoriadnejNavstevy/List",
  ],
  items: [
    {
      id: MONTH_OF_VISIT_QUESTION_ID,
      type: ZvjsCustomQuestionTypes.VISIT_DATE_PICKER,
      title: "ziadost.ziadostOnavstevu.labelMesiac",
      isRequired: true,
    },
    {
      id: FORM_OF_VISIT_QUESTION_ID,
      title: "ziadost.ziadostOnavstevu.FormaNavstevy",
      type: ZvjsQuestionTypes.DROPDOWN_NEW,
      isRequired: true,
      options: {
        counterKey: "/api/CisFormaRealizacieNavstevy/List",
        textKey: "nazov",
        optValues: [
          {
            id: "FN",
            conditions: [
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc: "SC_KIO_050101_IS_PHYSICAL_VISIT_AVAILABLE",
                conditionMetWhenValueIs: true,
              },
            ],
          },
          {
            id: "VN",
            conditions: [
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc: "SC_KIO_050101_IS_VIDEO_CALL_VISIT_AVAILABLE",
                conditionMetWhenValueIs: true,
              },
            ],
          },
          {
            id: "VND",
            conditions: [
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc:
                  "SC_KIO_050101_IS_UNDERAGE_KIDS_VIDEO_CALL_VISIT_AVAILABLE",
                conditionMetWhenValueIs: true,
              },
            ],
          },
        ],
      },
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: MONTH_OF_VISIT_QUESTION_ID,
          hasValue: true,
        },
      ],
    },
    {
      type: ZvjsItemTypes.LABEL,
      text: "ziadost.ziadostOnavstevuOdsudeny.upozornenie",
      flag: ZvjsLabelFlagTypes.WARNING,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: FORM_OF_VISIT_QUESTION_ID,
          value: ["FN", "VN"],
        },
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_050101_CAN_REQUEST_EXTRAORDINARY_VISIT_ONLY",
          conditionMetWhenValueIs: true,
        },
      ],
    },
    {
      id: "dovodMimoriadnejNavstevy",
      title: "ziadost.ziadostOnavstevu.labelDovodMimoriadnejNavstevy",
      type: ZvjsQuestionTypes.DROPDOWN_NEW,
      isRequired: true,
      options: {
        counterKey: "/api/CisDovodMimoriadnejNavstevy/List",
        textKey: "nazov",
        optValues: [
          { id: "OBJPRE" },
          { id: "NARODD" },
          { id: "UMRTIE" },
          { id: "ZDRAVS" },
          { id: "SVIATO" },
          { id: "INE" },
        ],
      },
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: FORM_OF_VISIT_QUESTION_ID,
          value: ["FN", "VN"],
        },
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_050101_CAN_REQUEST_EXTRAORDINARY_VISIT_ONLY",
          conditionMetWhenValueIs: true,
        },
      ],
    },
    {
      id: "trvanieNavstevy",
      title: "Trvanie návštevy",
      type: ZvjsQuestionTypes.DROPDOWN_NEW,
      isRequired: true,
      options: {
        counterKey: "/api/CisTrvanieNavstevy/List",
        textKey: "nazov",
        optValues: [
          {
            id: "2h",
            conditions: [
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc: "SC_KIO_050101_DISPLAY_2_HOURS_VISIT_OPTION",
                conditionMetWhenValueIs: true,
              },
            ],
          },
          { id: "1h" },
        ],
      },
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: FORM_OF_VISIT_QUESTION_ID,
          hasValue: true,
        },
      ],
    },
    {
      id: "sposobOdoslaniaPozvanky",
      title: "Spôsob odoslania pozvánky na návštevu",
      type: ZvjsQuestionTypes.DROPDOWN_NEW,
      isRequired: true,
      options: {
        counterKey: "/api/CisSposobOdoslaniaPozvanky/List",
        textKey: "nazov",
        optValues: [{ id: "MAIL" }, { id: "EMAIL" }],
      },
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "trvanieNavstevy",
          hasValue: true,
        },
      ],
    },
    {
      id: "emailPrePozvanku",
      title: "ziadost.ziadostOnavstevu.labelMail",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "sposobOdoslaniaPozvanky",
          value: ["EMAIL"],
        },
      ],
    },
    {
      id: INVITED_PEOPLE_LIST_QUESTION_ID,
      title: "ziadost.ziadostOnavstevu.labelOsobyNaFyzickuNavstevu",
      type: ZvjsCustomQuestionTypes.SC_KIO_050101_PHYSICAL_VISIT_PEOPLE_GRID,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: FORM_OF_VISIT_QUESTION_ID,
          value: ["FN"],
        },
      ],
    },
    {
      id: INVITED_PEOPLE_LIST_QUESTION_ID,
      title: "ziadost.ziadostOnavstevu.labelOsobyNaVideonavstevu",
      type: ZvjsCustomQuestionTypes.SC_KIO_050101_VIDEO_CALL_VISIT_PEOPLE_GRID,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: FORM_OF_VISIT_QUESTION_ID,
          value: ["VN", "VND"],
        },
      ],
    },
    /*
    {
      type: ZvjsItemTypes.LABEL,
      text: "ziadost.ziadostOnavstevuObvineny.upozornenie",
      flag: ZvjsLabelFlagTypes.WARNING,
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc:
            "SC_KIO_050101_SHOULD_DISPLAY_INVITED_PERSON_IN_PRISON_WARNING",
          conditionMetWhenValueIs: true,
        },
      ],
    },*/
    {
      type: ZvjsItemTypes.LABEL,
      text: "TODO: DOPLNIT Z CISELNIKA Upozornenie - úhrada eskorty",
      flag: ZvjsLabelFlagTypes.WARNING,
    },
    {
      type: ZvjsItemTypes.LABEL,
      text: "TODO: DOPLNIT Z CISELNIKA Upozornenie - žiadosť nad rámec",
      flag: ZvjsLabelFlagTypes.WARNING,
    },
  ],
};
