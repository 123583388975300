import { AlertColor } from "@mui/material";
import * as React from "react";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { ZVJS_COLORS } from "../../../theme/zvjs_theme";
import { CustomAnswerTypes } from "../requestPages/questionnaire/items/questions/customQuestions/customAnswers";
import {
  Ciselnik,
  CiselnikListResultType,
} from "../../../store/context/dataApi/Data";
import { IsAvailableToUserType } from "../requestPages/questionnaire/utils/isAvailableToUser";
import { RequestInternalCode } from "../requestTemplates/requestTemplates";
import { ReusableCalculationType } from "../requestPages/questionnaire/utils/calculations/reusable";
import { CustomCalculationType } from "../requestPages/questionnaire/utils/calculations/custom";
import { CustomValidationType } from "../requestPages/questionnaire/utils/validations/custom";
import { ReusableValidationType } from "../requestPages/questionnaire/utils/validations/reusable";

export interface ZvjsQuestionnaireTemplate {
  // DO NOT USE ID IN CODE LOGIC - used only for identification purposes between DNR and counter CisTypZiadosti (use code from requestCounter instead)
  id: `${RequestInternalCode}` | null;
  basicInfo: ZvjsBasicInfo[];
  items: ZvjsItem[];
  isAvailableToUser?: IsAvailableToUserType;
  dataCalculations?: {
    [key in
      | ReusableCalculationType
      | CustomCalculationType]?: ZvjsDataCalculation;
  };
  counters?: Ciselnik[];
}

export interface ZvjsBasicInfo {
  text: string;
  conditions?: ZvjsFormCondition[];
}

export interface ZvjsDataCalculation {
  calculateAt: Calculate;
}

export enum Calculate {
  // calculate right after questionnaire was fetched
  AT_INIT = "AT_INIT",
  // calculate everytime any component needs to know this value - not stored in userCalcData
  // calculation of this type should not call any async apis
  LIVE = "LIVE",
}

export type ZvjsItem =
  | ZvjsSection
  | ZvjsLabel
  | ZvjsDropdownQuestion
  | ZvjsDropdownQuestion_New
  | ZvjsTextQuestion
  | ZvjsSingleCheckBoxQuestion
  | ZvjsCustomQuestion
  | ZvjsNestedDropdown
  | ZvjsLabelSingleValue
  | ZvjsRadioQuestion;

// enum which holds all question types
export enum ZvjsQuestionTypes {
  DROPDOWN = "DROPDOWN",
  DROPDOWN_NEW = "DROPDOWN_NEW",
  TEXT = "TEXT",
  SINGLE_CHECKBOX = "SINGLE_CHECKBOX",
  NESTED_DROPDOWN = "NESTED_DROPDOWN",
  RADIO = "RADIO",
}

export enum ZvjsCustomQuestionTypes {
  VISIT_DATE_PICKER = "VISIT_DATE_PICKER",
  SC_KIO_050701_PHONE_CALL_LAWYERS_GRID = "SC_KIO_050701_PHONE_CALL_LAWYERS_GRID",
  SC_KIO_050302_PERSON_SENDING_PACKAGE_GRID = "SC_KIO_050302_PERSON_SENDING_PACKAGE_GRID",
  SC_KIO_050101_PHYSICAL_VISIT_PEOPLE_GRID = "SC_KIO_050101_PHYSICAL_VISIT_PEOPLE_GRID",
  SC_KIO_050101_VIDEO_CALL_VISIT_PEOPLE_GRID = "SC_KIO_050101_VIDEO_CALL_VISIT_PEOPLE_GRID",
  SC_KIO_050201_BANKNOTES_GRID = "SC_KIO_050201_BANKNOTES_GRID",
  SC_KIO_050202_CLOSE_PEOPLE_GRID = "SC_KIO_050202_CLOSE_PEOPLE_GRID",
  SC_KIO_050202_VALUABLE_ITEMS_GRID = "SC_KIO_050202_VALUABLE_ITEMS_GRID",
  SC_KIO_050301_CRIMINAL_PROCEEDINGS_GRID = "SC_KIO_050301_CRIMINAL_PROCEEDINGS_GRID",
  SC_KIO_050301_NOT_ALLOWED_TO_SEND_REQUEST_LABELS = "SC_KIO_050301_NOT_ALLOWED_TO_SEND_REQUEST_LABELS",
  SC_KIO_050802_TRAVEL_EXPENSES_RADIO = "SC_KIO_050802_TRAVEL_EXPENSES_RADIO",
  SC_KIO_051403_PLACE_OF_RELOCATION_QUESTION = "SC_KIO_051403_PLACE_OF_RELOCATION_QUESTION",
  SC_KIO_051403_PRIORITY_QUESTION = "SC_KIO_051403_PRIORITY_QUESTION",
  // fake question - just warning labels which prevent request submission when displayed
  SC_KIO_051402_NOT_ALLOWED_TO_SEND_REQUEST_LABEL = "SC_KIO_051402_NOT_ALLOWED_TO_SEND_REQUEST_LABEL",
  SC_KIO_051403_NOT_ALLOWED_TO_SEND_REQUEST_LABEL = "SC_KIO_051403_NOT_ALLOWED_TO_SEND_REQUEST_LABEL",
  SC_KIO_051404_PLACE_OF_LOCATION_QUESTION = "SC_KIO_051404_PLACE_OF_LOCATION_QUESTION",
  SC_KIO_050602_PROGRAMS_GRID = "SC_KIO_050602_PROGRAMS_GRID",
  SC_KIO_050601_ACTIVITIES_GRID = "SC_KIO_050601_ACTIVITIES_GRID",
  SC_KIO_050604_GROUP_QUESTION = "SC_KIO_050604_GROUP_QUESTION",
  SC_KIO_050206_REGISTERED_CHILDREN_GRID = "SC_KIO_050206_REGISTERED_CHILDREN_GRID",
  SC_KIO_050501_DEVICES_REVISION_CONTROL_GRID = "SC_KIO_050501_DEVICES_REVISION_CONTROL_GRID",
  SC_KIO_051003_NOT_ALLOWED_TO_SEND_REQUEST_LABELS = "SC_KIO_051003_NOT_ALLOWED_TO_SEND_REQUEST_LABELS",
  SC_KIO_051003_PERSONAL_HYGIENE_NEEDS_GRID = "SC_KIO_051003_PERSONAL_HYGIENE_NEEDS_GRID",
  SC_KIO_051102_CIVILIAN_CLOTHES_GRID = "SC_KIO_051102_CIVILIAN_CLOTHES_GRID",
  SC_KIO_050204_INSTITUTIONS_QUESTION = "SC_KIO_050204_INSTITUTIONS_QUESTION",
}

// non question objects which can be found in questionnaire/section items list
export enum ZvjsItemTypes {
  LABEL = "LABEL",
  SINGLE_VALUE_LABEL = "SINGLE_VALUE_LABEL",
  SECTION = "SECTION",
}

export enum ZvjsLabelFlagTypes {
  INFO = "INFO",
  WARNING = "WARNING",
  HINT = "HINT",
}

export const getSeverityOfZvjsFlag = (flag: ZvjsLabelFlagTypes): AlertColor => {
  switch (flag) {
    case ZvjsLabelFlagTypes.WARNING:
      return "warning";
    case ZvjsLabelFlagTypes.INFO:
      return "info";
    case ZvjsLabelFlagTypes.HINT:
      return "info";
    default:
      return "info";
  }
};

export const getAlertIconOfZvjsFlag = (
  flag: ZvjsLabelFlagTypes
): Partial<Record<AlertColor, React.ReactNode>> | undefined => {
  switch (flag) {
    case ZvjsLabelFlagTypes.HINT:
      return {
        info: <LightbulbIcon sx={{ color: ZVJS_COLORS.YELLOW }} />,
      };
    default:
      return undefined;
  }
};

export interface ZvjsGeneralItem {
  conditions?: ZvjsFormCondition[];
}

export interface ZvjsSection extends ZvjsGeneralItem {
  type: ZvjsItemTypes.SECTION;
  title?: string;
  items: (
    | ZvjsSection
    | ZvjsLabel
    | ZvjsDropdownQuestion
    | ZvjsDropdownQuestion_New
    | ZvjsTextQuestion
    | ZvjsSingleCheckBoxQuestion
    | ZvjsCustomQuestion
    | ZvjsNestedDropdown
    | ZvjsLabelSingleValue
    | ZvjsRadioQuestion
  )[];
}

export interface ZvjsLabel extends ZvjsGeneralItem {
  type: ZvjsItemTypes.LABEL;
  text: string;
  flag?: ZvjsLabelFlagTypes;
}

export interface ZvjsLabelSingleValue extends ZvjsGeneralItem {
  type: ZvjsItemTypes.SINGLE_VALUE_LABEL;
  text: string;
  value: `${ReusableCalculationType}` | `${CustomCalculationType}`;
  valueType?: LabelType;
  flag?: ZvjsLabelFlagTypes;
}

export enum LabelType {
  EURO = "EUR",
}

export interface ZvjsQuestion extends ZvjsGeneralItem {
  id: string;
  type: ZvjsQuestionTypes | ZvjsCustomQuestionTypes; // ZvjsQuestionTypes.TEXT | ZvjsQuestionTypes.DROPDOWN,
  title: string;
  isRequired?: boolean;
  validations?: (`${ReusableValidationType}` | `${CustomValidationType}`)[];
  hintText?: string;
}

export interface ZvjsDropdownQuestion extends ZvjsQuestion {
  type: ZvjsQuestionTypes.DROPDOWN;
  options: {
    id: string;
    label: string;
    conditions?: ZvjsFormCondition[];
  }[];
}

export interface ZvjsDropdownQuestion_New extends ZvjsQuestion {
  type: ZvjsQuestionTypes.DROPDOWN_NEW;
  options: {
    counterKey: Ciselnik;
    textKey: string;
    optValues: {
      id: string;
      conditions?: ZvjsFormCondition[];
    }[];
  };
}

export interface ZvjsTextQuestion extends ZvjsQuestion {
  type: ZvjsQuestionTypes.TEXT;
  // html input types: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types
  inputType?: "text" | "number";
  multiline?: boolean;
  minRows?: number;
}

export interface ZvjsSingleCheckBoxQuestion extends ZvjsQuestion {
  id: string;
  type: ZvjsQuestionTypes.SINGLE_CHECKBOX;
  title: string;
  checked?: boolean;
  // single checkbox question currently cannot be required because not checked checkbox = no answer in list of answers
  isRequired?: boolean;
}

export interface ZvjsRadioQuestion extends ZvjsQuestion {
  type: ZvjsQuestionTypes.RADIO;
  hideTitle?: boolean;
  options: {
    id: string;
    label: string;
    conditions?: ZvjsFormCondition[];
  }[];
}

export interface ZvjsCustomQuestion extends ZvjsQuestion {
  type: ZvjsCustomQuestionTypes;
}

export interface ZvjsNestedDropdown extends ZvjsQuestion {
  type: ZvjsQuestionTypes.NESTED_DROPDOWN;
  maxNumberSelect?: number;
  options: ZvjsNestedDropdownOptions;
}

export interface ZvjsNestedDropdownOptions {
  answerId: string;
  counterKey: Ciselnik;
  textKey: string;
  children: ZvjsNestedDropdownOption[];
}

export interface ZvjsNestedDropdownOption {
  id: string;
  conditions?: ZvjsFormCondition[];
  options?: ZvjsNestedDropdownOptions;
}

// condition which describes if question/item/option should be displayed based on previous user answer
export type ZvjsFormCondition =
  | UserInfoCondition
  | PreviousAnswersConditionValue
  | PreviousAnswersConditionHasValue;

export interface UserInfoCondition {
  type: ZvjsConditionTypes.FUNCTION;
  conFunc: `${ReusableCalculationType}` | `${CustomCalculationType}`;
  conditionMetWhenValueIs: true | false;
}

interface PreviousAnswersCondition {
  type: ZvjsConditionTypes.PREVIOUS_ANSWERS;
  questionId: string;
}

export interface PreviousAnswersConditionValue
  extends PreviousAnswersCondition {
  value: (string | NestedDropdownAnswerValueType)[];
  hasValue?: never;
}

export interface PreviousAnswersConditionHasValue
  extends PreviousAnswersCondition {
  value?: never;
  hasValue: boolean;
}

export enum ZvjsConditionTypes {
  FUNCTION = "FUNCTION",
  PREVIOUS_ANSWERS = "PREVIOUS_ANSWERS",
}

export type UserCalcData = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in ReusableCalculationType | CustomCalculationType | string]?:
    | number
    | boolean
    | string
    | any;
};

export interface ZvjsAnswers {
  [key: string]: ZvjsAnswerValueType;
}

export type ZvjsAnswerValueType =
  | number
  | string
  | string[]
  | boolean
  | NestedDropdownAnswerValueType[]
  | CustomAnswerTypes;

export interface NestedDropdownAnswerValueType {
  [key: string]: string;
}

export interface RequestUserPersonalData {
  fullName?: string;
  birthDate?: string;
}

export enum ZvjsValidationFlagTypes {
  INFO = "INFO",
  ERROR = "ERROR",
}

export const getSeverityOfValidationFlag = (
  flag: ZvjsValidationFlagTypes
): AlertColor => {
  switch (flag) {
    case ZvjsValidationFlagTypes.ERROR:
      return "error";
    case ZvjsValidationFlagTypes.INFO:
      return "info";
  }
  return "error";
};

export interface ZvjsValidationError {
  type: ZvjsValidationFlagTypes;
  message: string;
}

export type RequestCounter = {
  [key in Ciselnik]?: CiselnikListResultType<Ciselnik>["data"];
};

export interface State {
  questionnaire: ZvjsQuestionnaireTemplate;
  answers: ZvjsAnswers;
  userCalcData: UserCalcData;
  counters: RequestCounter;
  validationErrors: {
    [key: string]: ZvjsValidationError;
  };
  userPersonalData: RequestUserPersonalData;
  editQuestion: string | undefined;
  // holds request counter for particular request (there should not be stored counter values from different questionnaire)
  requestCounter: CiselnikListResultType<"/api/CisTypZiadosti/List">["data"];
}

export const initialState: State = {
  questionnaire: {
    id: null,
    basicInfo: [],
    items: [],
    dataCalculations: {},
  },
  answers: {},
  userCalcData: {},
  counters: {},
  validationErrors: {},
  userPersonalData: {},
  editQuestion: undefined,
  requestCounter: undefined,
};
