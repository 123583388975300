import { defer } from "react-router-dom";
import { ZvjsSummaryTable } from "../../../../common";
import { Stack } from "@mui/material";
import { useUITranslation } from "../../../../store/context/translation-context";
import { capitalize } from "@mui/material/utils";
import {
  API_Clients,
  MAX_NUMBER_OF_ITEMS,
} from "../../../../store/context/dataApi/Data";
import { withLoader } from "../../../../hoc/withLoader";
import { LoaderError } from "../../../../router/LoaderError";
import { ZvjsSummaryTableComponent } from "../../../../common/components/ZvjsSummaryTable";
import ZvjsTable, {
  RowData,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import moment from "moment";

const loadData = async () => {
  const { EOO_Post, FEOO_Post, FEOO_Get } = await API_Clients();

  const data = await Promise.all([
    EOO_Post("/api/VazbyATresty/ListTrestData", {
      body: {
        filters: [
          {
            id: Number(localStorage.getItem("klientId")),
            ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
          },
        ],
      },
    }),
    EOO_Post("/api/VazbyATresty/DetailTDVData", {
      body: {
        id: Number(localStorage.getItem("klientId")),
        ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
      },
    }),
    FEOO_Post("/api/KlientUni/List", {
      body: {
        filters: [
          {
            zakladnyFilter: {
              zakladneCislo: Number(localStorage.getItem("zakladneCislo")),
            },
          },
        ],
      },
    }),
    FEOO_Post("/api/Codelist/ListStupenStrazenia", {
      body: {
        filters: [],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
    FEOO_Post("/api/Codelist/ListDiferenciacnaSkupina", {
      body: {
        filters: [],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
  ]);

  const dataDifSkup = await FEOO_Get("/api/KlientUni/Get", {
    params: {
      query: {
        id: localStorage.getItem("klientObjectId") ?? undefined,
      },
    },
  });

  if (data[0].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [tresty klienta]");
  }

  if (data[1].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [tresty TDV klienta]");
  }

  if (data[2].error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [feoo klient uni zoznam]"
    );
  }

  if (data[3].error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [stupen strazenia zoznam]"
    );
  }

  if (data[4].error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [diferenciacna skupina zoznam]"
    );
  }

  const aktualnyTrestId = data[0].data?.records
    ?.filter((t) => t.aktivny)
    ?.sort(
      (t1, t2) =>
        new Date(t1.predpokladanyKoniecTrestu ?? "").valueOf() -
        new Date(t2.predpokladanyKoniecTrestu ?? "").valueOf()
    )
    .at(0)?.id;

  const aktualnyTrest = await EOO_Post("/api/VazbyATresty/DetailTrestData", {
    body: {
      id: aktualnyTrestId ?? -1,
      ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
    },
  });

  if (aktualnyTrest.error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [aktuálny trest]");
  }

  const stupenStrazeniaMap = Object.fromEntries(
    data[3]?.data?.records?.map((item) => [item.id, item.nazov]) ?? []
  );

  const diferenciacnaSkupinaMap = Object.fromEntries(
    data[4]?.data?.records?.map((item) => [item.id, item.nazov]) ?? []
  );

  return {
    aktualnyTrest: aktualnyTrest,
    detailTDVData: data[1],
    epvvvtKlient: data[2]?.data?.records
      ?.filter(
        (klient) =>
          // pouzit /api/Klient/DetailZakladneCisloData z EOO ak nepojde klientId
          klient?.klientID === Number(localStorage.getItem("klientId"))
      )
      ?.map((klient) => ({
        ...klient,
        stupenStrazenia: klient?.stupenStrazenia
          ? stupenStrazeniaMap[klient?.stupenStrazenia]
          : null,
        diferenciacnaSkupina: dataDifSkup?.data?.data?.zakladneUdajeKlienta
          ?.vonkajsiaDiferenciacia
          ? diferenciacnaSkupinaMap[
              dataDifSkup?.data?.data?.zakladneUdajeKlienta
                ?.vonkajsiaDiferenciacia
            ]
          : null,
      }))
      .at(0),
  };
};

export const UdajeOTresteTabLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface UdajeOTresteTabProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const UdajeOTresteTab = (props: UdajeOTresteTabProps) => {
  const { tui } = useUITranslation();

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsSummaryTable
        header={tui(
          "osobneUdaje.vseobecneTrestnopravneUdaje.vseobecneTrestUdaje"
        )}
      >
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.vseobecneTrestnopravneUdaje.zaciatokTrestu")}
          value={
            props.data.aktualnyTrest.data.datumNastupu
              ? new Date(
                  props.data.aktualnyTrest.data.datumNastupu
                ).zvjsToString()
              : ""
          }
          isBorder={true}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui(
            "osobneUdaje.vseobecneTrestnopravneUdaje.napovedaNastupTrestu"
          )}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.vseobecneTrestnopravneUdaje.terminPP")}
          value={
            !!props.data.aktualnyTrest.data.datumNastupu &&
            (!!props.data.aktualnyTrest.data.podmienecnePrepusteniePoRoky ||
              !!props.data.aktualnyTrest.data.podmienecnePrepusteniePoMesiace ||
              !!props.data.aktualnyTrest.data.podmienecnePrepusteniePoDni)
              ? new Date(
                  moment(props.data.aktualnyTrest.data.datumNastupu)
                    .add(
                      props.data.aktualnyTrest.data
                        .podmienecnePrepusteniePoRoky ?? 0,
                      "years"
                    )
                    .add(
                      props.data.aktualnyTrest.data
                        .podmienecnePrepusteniePoMesiace ?? 0,
                      "months"
                    )
                    .add(
                      props.data.aktualnyTrest.data
                        .podmienecnePrepusteniePoDni ?? 0,
                      "days"
                    )
                    .toISOString()
                ).zvjsToString()
              : ""
          }
          isBorder={true}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui("osobneUdaje.vseobecneTrestnopravneUdaje.napovedaPP")}
        />
        <ZvjsSummaryTableComponent
          label={tui(
            "osobneUdaje.vseobecneTrestnopravneUdaje.terminPPsTechnickouKontrolou"
          )}
          value={"< API? >"}
          isBorder={true}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui(
            "osobneUdaje.vseobecneTrestnopravneUdaje.napovedaPPtechKontrola"
          )}
        />
        <ZvjsSummaryTableComponent
          label={tui(
            "osobneUdaje.vseobecneTrestnopravneUdaje.terminDomaceVazenie"
          )}
          value={
            props.data.detailTDVData.data.datumTDV
              ? new Date(props.data.detailTDVData.data.datumTDV).zvjsToString()
              : ""
          }
          isBorder={true}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui(
            "osobneUdaje.vseobecneTrestnopravneUdaje.napovedaDomaceVazenie"
          )}
        />
        <ZvjsSummaryTableComponent
          label={tui(
            "osobneUdaje.vseobecneTrestnopravneUdaje.aktualnyStupenStrazenia"
          )}
          value={props.data.epvvvtKlient?.stupenStrazenia ?? ""}
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui(
            "osobneUdaje.vseobecneTrestnopravneUdaje.diferenciacnaSkupina"
          )}
          value={props.data.epvvvtKlient?.diferenciacnaSkupina ?? ""}
          isBorder={true}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui(
            "osobneUdaje.vseobecneTrestnopravneUdaje.napovedaAktualnaDifSkupina"
          )}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.vseobecneTrestnopravneUdaje.dlzkaTrestu")}
          value={props.data.aktualnyTrest.data.dlzkaTrestu?.toString() ?? ""}
          isBorder={false}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui(
            "osobneUdaje.vseobecneTrestnopravneUdaje.napovedaInfoDlzkaTrestu"
          )}
        />
      </ZvjsSummaryTable>
      <ZvjsTable
        data={{
          label: capitalize(
            tui("osobneUdaje.vykonTrestuZaSpachanieTrCinu.vykonTrestu")
          ),
          header: [
            {
              value: capitalize(tui("osobneUdaje.udajeOvazbe.paragraf")),
              align: "left",
            },
          ],
          body:
            (() => {
              const rd: RowData = {
                row: [
                  {
                    value:
                      props.data.aktualnyTrest.data.paragrafOdsekPismeno ?? "",
                    align: "left",
                  },
                ],
              };
              return [rd];
            })() ?? [],
        }}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
        hintTitle={tui("nápoveda").toString()}
        hintText={tui(
          "osobneUdaje.vykonTrestuZaSpachanieTrCinu.napovedaTrestnyCin"
        )}
      />
    </Stack>
  );
};
export default withLoader(UdajeOTresteTab);
